import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { SweetAlertService } from '@shared/services/sweet-alert.service';

@Component({
  selector: 'app-tracking-reports-add',
  templateUrl: './tracking-reports-add.component.html',
  styleUrls: ['./tracking-reports-add.component.scss']
})
export class TrackingReportsAddComponent implements OnInit {
  form!: FormGroup;
  lang_key = localStorage?.getItem('lang_key') || 'en';
  emailsList = [];
  constructor(private dialogRef: MatDialogRef<TrackingReportsAddComponent>, private sweetAlert: SweetAlertService,
    private fb: FormBuilder, private spinner: PreloaderService, public ds: DataService, private lang: LanguagePipe
  ) { }

  ngOnInit(): void {
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			emails: [null, Validators.required],
			total_hours: [null, [Validators.required]],
			record_date: [null, [Validators.required]],
			notes: [''],
		});
	}

  searchUserEmail(searchKey) {
    this.ds.get(`human_resources/search_users_tracking?search_key=${searchKey?.term || ''}`).subscribe(res => {
      if(res?.status) {
        this.emailsList = res?.records;
      } else {
        this.emailsList = [];
      }
    }, err => {
      this.emailsList = [];
    })
  }

	addTrackingReport() {
		this.spinner.show();
		let param = new FormData();
		param.append(
			"emails",
			this.form.value.emails?.toString()
		);
		param.append(
			"total_hours",
			this.form.value.total_hours
		);
		param.append(
			"record_date",
			this.form.value.record_date
		);
		param.append(
			"notes",
			this.form.value.notes
		);
		this.ds.post('np/design/tracking/create_manual_tracking_records', param).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						2000
					);
					this.dialogRef.close(true);
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}

}
