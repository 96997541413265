import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DataService } from "@core/bootstrap/data.service";
import { PreloaderService } from "@core/bootstrap/preloader.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import * as moment from "moment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-device-records",
  templateUrl: "./device-records.component.html",
  styleUrls: ["./device-records.component.scss"],
})
export class DeviceRecordsComponent implements OnInit {
  filter_form: FormGroup;
  devices_ids: any[];
  attendance_records: any = [];
  attendance_records_with_pagination: any = [];
  loading: boolean = false;
  page_no = 1;
  pagesize = 10;
  inserted: any = [];
  inserted_with_pagination: any = [];
  inserted_loading: boolean = false;
  inserted_page_no = 1;
  inserted_pagesize = 10;
  nodata: string = this.lang.transform("lang_no_data");
  constructor(
    private formBuilder: FormBuilder,
    public ds: DataService,
    public lang: LanguagePipe,
    public spinner: PreloaderService
  ) {}
  initForm() {
    this.filter_form = this.formBuilder.group({
      startdate: [""],
      startdatetime: [""],
      startdatedate: [""],
      enddate: [""],
      enddatetime: [""],
      enddatedate: [""],
      device_id: [""],
      person_id: [""],
    });
  }
  ngOnInit(): void {
    this.initForm();
    this.get_form_lists();
  }
  setdate(typeofdate: any) {
    if (typeofdate === "start") {
      this.filter_form.get("startdate").setValue(
        moment(new Date(this.filter_form.get("startdatetime").value))
          .locale("en-US")
          .format("YYYY-MM-DD") +
          " " +
          this.filter_form.get("startdatedate").value +
          ":00"
      );
    } else {
      this.filter_form.get("enddate").setValue(
        moment(new Date(this.filter_form.get("enddatetime").value))
          .locale("en-US")
          .format("YYYY-MM-DD") +
          " " +
          this.filter_form.get("enddatedate").value +
          ":00"
      );
    }
  }
  private subscriptions = new Subscription();
  public get_form_lists() {
    this.devices_ids = [];
    this.subscriptions.add(
      this.ds
        .getActionByUrl([], "human_resources/list_devices/1/100?search_key=")
        .subscribe(
          (res) => {
            if (res.status) {
              this.devices_ids = res?.records;
            } else {
              this.devices_ids = [];
            }
          },
          (error) => {
            this.devices_ids = [];
          }
        )
    );
  }
  public get_records() {
    this.spinner.show();
    this.page_no=1
    this.pagesize=10
    this.inserted_page_no=1
    this.inserted_pagesize=10
    this.loading = true;
    this.inserted_loading = true;
    let param = new FormData();
    param.append("device_id", this.filter_form.get("device_id")?.value);
    param.append("person_id", this.filter_form.get("person_id")?.value);
    param.append("startdatetime", this.filter_form.get("startdate")?.value);
    param.append("enddatetime", this.filter_form.get("enddate")?.value);
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "human_resources/get_employees_attendance_records"
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            this.loading = false;
            this.inserted_loading = false;
            if (res.status) {
              this.attendance_records = res?.data;
              this.inserted = res?.inserted;
              if (this.attendance_records.length > 10) {
                for (let i = 0; i < 10; i++) {
                  this.attendance_records_with_pagination = [
                    ...this.attendance_records_with_pagination,
                    this.attendance_records[i],
                  ];
                }
              } else if (this.attendance_records.length < 10) {
                for (let i = 0; i < this.attendance_records.length; i++) {
                  this.attendance_records_with_pagination = [
                    ...this.attendance_records_with_pagination,
                    this.attendance_records[i],
                  ];
                }
              }else{
                this.attendance_records=[]
                this.attendance_records_with_pagination=[]
              }

              if (this.inserted.length > 10) {
                for (let i = 0; i < 10; i++) {
                  this.inserted_with_pagination = [
                    ...this.inserted_with_pagination,
                    this.inserted[i],
                  ];
                }
              } else if (this.inserted.length < 10) {
                for (let i = 0; i < this.inserted.length; i++) {
                  this.inserted_with_pagination = [
                    ...this.inserted_with_pagination,
                    this.inserted[i],
                  ];
                }
              }else{
                this.inserted=[]
                this.inserted_with_pagination=[]
              }
            } else {
              this.attendance_records = [];
              this.attendance_records_with_pagination = [];
              this.inserted = [];
              this.inserted_with_pagination = [];
              this.nodata = res.error;
            }
          },
          (error) => {
            this.attendance_records = [];
            this.attendance_records_with_pagination = [];
            this.inserted = [];
            this.inserted_with_pagination = [];
            this.spinner.hide();
          }
        )
    );
  }
  public loadPageFrm(page) {
    this.page_no = page;
    this.pagesize = this.pagesize;
    let start_pagination = (this.page_no - 1) * this.pagesize;
    let end_pagination = Number((this.page_no - 1) * this.pagesize) + Number(this.pagesize);
    if (this.attendance_records.length < end_pagination) {
      end_pagination = this.attendance_records.length;
    }
    this.attendance_records_with_pagination = [];
    for (let i = start_pagination; i < end_pagination; i++) {
      this.attendance_records_with_pagination = [
        ...this.attendance_records_with_pagination,
        this.attendance_records[i],
      ];
    }
  }
  public loadFrmPagesize(size) {
    this.page_no = 1;
    this.pagesize = size;
    let start_pagination = (this.page_no - 1) * this.pagesize;
    let end_pagination = Number((this.page_no - 1) * this.pagesize) + Number(this.pagesize);
    if (this.attendance_records.length < end_pagination) {
      end_pagination = this.attendance_records.length;
    }
    this.attendance_records_with_pagination = [];
    for (let i = start_pagination; i < end_pagination; i++) {
      this.attendance_records_with_pagination = [
        ...this.attendance_records_with_pagination,
        this.attendance_records[i],
      ];
    }
  }
  public insertedloadPageFrm(page) {
    this.inserted_page_no = page;
    this.inserted_pagesize = this.inserted_pagesize;
    let start_pagination = (this.inserted_page_no - 1) * this.inserted_pagesize;
    let end_pagination = Number((this.inserted_page_no - 1) * this.inserted_pagesize) + Number(this.inserted_pagesize);
    if (this.inserted.length < end_pagination) {
      end_pagination = this.inserted.length;
    }
    this.inserted_with_pagination = [];
    for (let i = start_pagination; i < end_pagination; i++) {
      this.inserted_with_pagination = [
        ...this.inserted_with_pagination,
        this.inserted[i],
      ];
    }
  }
  public insertedloadFrmPagesize(size) {
    this.inserted_page_no = 1;
    this.inserted_pagesize = size;
    let start_pagination = (this.inserted_page_no - 1) * this.inserted_pagesize;
    let end_pagination = Number((this.inserted_page_no - 1) * this.inserted_pagesize) + Number(this.inserted_pagesize);
    if (this.inserted.length < end_pagination) {
      end_pagination = this.inserted.length;
    }
    this.inserted_with_pagination = [];
    for (let i = start_pagination; i < end_pagination; i++) {
      this.inserted_with_pagination = [
        ...this.inserted_with_pagination,
        this.inserted[i],
      ];
    }
  }
}
