import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { DecimalPipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { environment } from "@env/environment";
import { NgSelectComponent } from "@ng-select/ng-select";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subscription } from "rxjs";

@Component({
  selector: "app-sinvoice-add",
  templateUrl: "./sinvoice-add.component.html",
  styleUrls: ["./sinvoice-add.component.scss"],
})
export class SinvoiceAddComponent implements OnInit, OnDestroy {
  public branch_id = this.route.snapshot.paramMap.get("bid");
  public finance_id = this.route.snapshot.paramMap.get("fid");
  lang_key = localStorage.getItem("lang_key") || "en";
  modalRef: BsModalRef;
  advance_acode_text = this.lang.transform("lang_advance_accounts");
  advance_ccode_text = this.lang.transform("lang_advance_costcenter");
  fullname: string;
  date: Date;
  branches: any = [];
  modes: any = [];
  accounts: any = [];
  customers: any = [];
  costs: any = [];
  taxs: any = [];
  disable: boolean = true;
  myDate: any = new Date();
  form: FormGroup;
  registrationForm: FormGroup;
  items: FormArray;
  invoiceToCostsArray: FormArray;
  incomeAccountsCostsArray: FormArray;
  cashAdvanceCostsArray: FormArray;
  taxAccountsCostsArray: FormArray;
  isEmailExists: boolean = false;
  isMobExists: boolean = false;
  emailErrMsg: string = "";
  mobErrMsg: string = "";
  isFile: boolean = false;
  filename: any = this.lang.transform("lang_select_files");
  users: any = [];
  data: any = [];
  item_tax;
  global_tax;
  item_discount;
  global_discount;
  financeList = [];
  totalDiscount: any = "0.00";
  test;
  get itemvalid() {
    return this.form.get("items") as FormArray;
  }
  defaultRoundoff;
  documentTypes = [
    {
      label: this.lang.transform("lang_simplified_tax"),
      value: "simplified",
    },
    {
      label: this.lang.transform("lang_standard_tax"),
      value: "standard",
    },
  ];
  constructor(
    public ds: DataService,
    public route: ActivatedRoute,
    public datePipe: DatePipe,
    public router: Router,
    private sweetAlert: SweetAlertService,
    public ls: LocalStorage,
    public lang: LanguagePipe,
    public spinner: PreloaderService,
    public fb: FormBuilder,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private _decimalPipe: DecimalPipe
  ) {}
  ngOnInit(): void {
    this.fullname = this.ls.getItem("user_username");
    this.myDate = new Date(this.datePipe.transform(this.myDate, "yyyy/MM/dd"));
    this.spinner.show();
    this.load_branches();
    this.buildform();
    this.registration_form();
    this.form.get("branch_id").valueChanges.subscribe((res) => {
      if (res) {
        this.getFinancialList();
      }
    });
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  ngAfterViewInit() {
    for (let index = 0; index < 5; index++) {
      this.items.push(this.item_records());
    }
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  public load_tax_settings() {
    this.item_tax = 0;
    this.global_tax = 0;
    this.item_discount = 0;
    this.global_discount = 0;
    if (this.form.get("branch_id").value) {
      this.subscriptions.add(
        this.ds
          .postActionByUrl([], "gts/" + this.form.get("branch_id").value)
          .subscribe((res) => {
            this.spinner.hide();
            if (res.status) {
              this.item_tax = JSON.parse(res.records.item_tax);
              this.global_tax = JSON.parse(res.records.global_tax);
              this.item_discount = JSON.parse(res.records.item_discount);
              this.global_discount = JSON.parse(res.records.global_discount);
            }
          })
      );
    }
  }
  public load_branches() {
    this.branches = [];
    this.subscriptions.add(
      this.ds.getActionByUrl([], "ab/accounts_add").subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.branches = res.records;
        }
      })
    );
  }
  public load_payment_modes(branch_id) {
    this.form.get("invoice_payment_mode").setValue(null);
    this.modes = [];
    if (branch_id) {
      this.spinner.show();
      this.subscriptions.add(
        this.ds.getActionByUrl([], "pmodes/" + branch_id).subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.modes = res.records;
            }
          },
          (error) => {
            this.spinner.hide();
          }
        )
      );
    }
  }
  public load_taxes() {
    if (this.form.get("branch_id").value) {
      this.taxs = [];
      this.subscriptions.add(
        this.ds
          .getActionByUrl([], "invtaxes/" + this.form.get("branch_id").value)
          .subscribe((res) => {
            this.spinner.hide();
            if (res.status) {
              this.taxs = res.records;
            }
          })
      );
    }
  }
  public buildform() {
    this.form = new FormGroup({
      branch_id: new FormControl(this.branch_id, [Validators.required]),
      finance_id: new FormControl(this.finance_id, [Validators.required]),
      invoice_date: new FormControl(null, [Validators.required]),
      actual_delivery_date: new FormControl(null, [Validators.required]),
      invoice_payment_mode: new FormControl(null, [Validators.required]),
      invoice_credit_days: new FormControl({
        value: "",
        disabled: this.disable,
      }),
      invoice_credit_date: new FormControl(""),
      invoice_to_account_id: new FormControl(null, [Validators.required]),
      transaction_tages: new FormControl(null, [Validators.required]),
      document_type: new FormControl(null, [Validators.required]),
      return_parent_id: new FormControl(null, [Validators.required]),
      invoice_type_zatca: new FormControl("388", [Validators.required]),
      customers: new FormControl(null),
      invoice_address_to: new FormControl(""),
      ccr_number: new FormControl(""),
      invoice_vat_tax_id: new FormControl(""),
      invoice_ref_number: new FormControl(""),
      invoice_instruction_remarks: new FormControl("", [Validators.required]),
      invoiceToCostCenter: this.fb.array([]),
      items: this.fb.array([]),
      invoice_grand_total: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      invoice_global_discount_percent: new FormControl(""),
      invoice_cash_advance_amount: new FormControl({
        value: "",
        disabled: true,
      }),
      invoice_global_tax_id: new FormControl(null),
      invoice_global_tax_age: new FormControl({ value: "", disabled: true }),
      invoice_global_tax_amount: new FormControl({ value: "", disabled: true }),
      invoice_net_total: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      invoice_income_expanse_account_id: new FormControl(null, [
        Validators.required,
      ]),
      invoice_discount_account_id: new FormControl(null),
      // incomeAccountsCostCenter: this.fb.array([]),
      invoice_cash_advance_account_id: new FormControl(null),
      cashAdvanceCostCenter: this.fb.array([]),
      invoice_tax_account_id: new FormControl(null),
      taxAccountsCostCenter: this.fb.array([]),
      total_item_tax: new FormControl({ value: "", disabled: true }),
      total_item_discount: new FormControl({ value: "", disabled: true }),
      isGlobal: new FormControl(false),
      invoice_global_discount_amount: new FormControl({
        value: "",
      }),
      invoice_roundoff_account_id: new FormControl(null, [Validators.required]),
      invoice_roundoff_amount: new FormControl({
        value: "0.00",
        disabled: true,
      }),
    });
    this.items = this.form.get("items") as FormArray;
    this.invoiceToCostsArray = this.form.get(
      "invoiceToCostCenter"
    ) as FormArray;
    // this.incomeAccountsCostsArray = this.form.get(
    //   "incomeAccountsCostCenter"
    // ) as FormArray;
    this.cashAdvanceCostsArray = this.form.get(
      "cashAdvanceCostCenter"
    ) as FormArray;
    this.taxAccountsCostsArray = this.form.get(
      "taxAccountsCostCenter"
    ) as FormArray;
    this.getFinancialList();
    this.load_payment_modes(this.branch_id);
    this.load_taxes();
    this.load_tax_settings();
  }
  public items_group() {
    return this.form.get("items") as FormArray;
  }
  public item_records(): FormGroup {
    return this.fb.group({
      invoice_item_name: new FormControl("", [Validators.required]),
      invoice_item_unit: new FormControl(""),
      invoice_item_quantity: new FormControl("", [Validators.required]),
      invoice_item_price: new FormControl("0", [Validators.required]),
      invoice_item_tax: new FormControl(null),
      invoice_item_tax_amount: new FormControl({ value: "", disabled: true }),
      invoice_item_amount: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      total_amount_discount: new FormControl({ value: "", disabled: true }),
      invoice_discount_percent: new FormControl(""),
      invoice_discount_percent_amount: new FormControl(""),
      invoice_total_amount: new FormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      invoice_item_description: new FormControl("", [Validators.required]),
    });
  }
  public add_items() {
    this.items.push(this.item_records());
  }
  public removeItems(index) {
    if (this.items.length > 1) {
      this.items.removeAt(index);
    }
  }
  clearEmptyItem() {
    for (let k = this.items.length - 1; k > -1; k--) {
      if (
        this.itemvalid.controls[k].get("invoice_item_name").value == "" &&
        this.itemvalid.controls[k].get("invoice_item_unit").value == "" &&
        this.itemvalid.controls[k].get("invoice_item_quantity").value == "" &&
        this.itemvalid.controls[k].get("invoice_item_price").value == "0" &&
        this.itemvalid.controls[k].get("invoice_item_tax").value == null &&
        this.itemvalid.controls[k].get("invoice_discount_percent").value ==
          "" &&
        this.itemvalid.controls[k].get("invoice_item_description").value == ""
      ) {
        this.removeItems(k);
      }
    }
  }
  //invoice to cost centers
  public invoiceToCostCenterGroup() {
    return this.form.get("invoiceToCostCenter") as FormArray;
  }
  get invoiceToCostCenterGroups() {
    return this.form.get("invoiceToCostCenter") as FormArray;
  }
  public invoiceToCostCenterRecords(): FormGroup {
    return this.fb.group({
      cid: new FormControl(null, [Validators.required]),
      debit_amount: new FormControl("", [Validators.required]),
      credit_amount: new FormControl("", [Validators.required]),
      costList: new FormControl([]),
    });
  }
  public addInvoiceToCostCenter() {
    this.invoiceToCostsArray.push(this.invoiceToCostCenterRecords());
  }
  public removeInvoiceToCostCenter(index) {
    this.invoiceToCostsArray.removeAt(index);
  }
  clearEmptyInvoiceToCostCenter() {
    for (let k = this.invoiceToCostsArray.length - 1; k > -1; k--) {
      if (
        this.invoiceToCostCenterGroups.controls[k].get("cid").value == null ||
        (this.invoiceToCostCenterGroups.controls[k].get("debit_amount").value ==
          "" &&
          this.invoiceToCostCenterGroups.controls[k].get("credit_amount")
            .value == "")
      ) {
        this.removeInvoiceToCostCenter(k);
      }
    }
  }
  //income accounts cost centers
  // public incomeAccountsCostCenterGroup() {
  //   return this.form.get("incomeAccountsCostCenter") as FormArray;
  // }
  // get incomeAccountsCostCenterGroups() {
  //   return this.form.get("incomeAccountsCostCenter") as FormArray;
  // }
  // public incomeAccountsCostCenterRecords(): FormGroup {
  //   return this.fb.group({
  //     cid: new FormControl(null, [Validators.required]),
  //     amount: new FormControl("", [Validators.required]),
  //     costList: new FormControl([]),
  //   });
  // }
  // public addIncomeAccountsCostCenter() {
  //   this.incomeAccountsCostsArray.push(this.incomeAccountsCostCenterRecords());
  // }
  // public removeIncomeAccountsCostCenter(index) {
  //   this.incomeAccountsCostsArray.removeAt(index);
  // }
  // clearEmptyIncomeAccounts() {
  //   for (let k = this.incomeAccountsCostsArray.length - 1; k > -1; k--) {
  //     if (
  //       this.incomeAccountsCostCenterGroups.controls[k].get("cid").value ==
  //         null &&
  //       this.incomeAccountsCostCenterGroups.controls[k].get("amount").value ==
  //         ""
  //     ) {
  //       this.removeIncomeAccountsCostCenter(k);
  //     }
  //   }
  // }
  //cash advance cost centers
  public cashAdvanceCostCenterGroup() {
    return this.form.get("cashAdvanceCostCenter") as FormArray;
  }
  get cashAdvanceCostCenterGroups() {
    return this.form.get("cashAdvanceCostCenter") as FormArray;
  }
  public cashAdvanceCostCenterRecords(): FormGroup {
    return this.fb.group({
      cid: new FormControl(null, [Validators.required]),
      amount: new FormControl("", [Validators.required]),
      costList: new FormControl([]),
    });
  }
  public addCashAdvanceCostCenter() {
    this.cashAdvanceCostsArray.push(this.cashAdvanceCostCenterRecords());
  }
  public removeCashAdvanceCostCenter(index) {
    this.cashAdvanceCostsArray.removeAt(index);
  }
  clearEmptyCashAdvance() {
    for (let k = this.cashAdvanceCostsArray.length - 1; k > -1; k--) {
      if (
        this.cashAdvanceCostCenterGroups.controls[k].get("cid").value == null &&
        this.cashAdvanceCostCenterGroups.controls[k].get("amount").value == ""
      ) {
        this.removeCashAdvanceCostCenter(k);
      }
    }
  }
  //tax accounts cost centers
  get taxAccountsCostCenterGroup() {
    return this.form.get("taxAccountsCostCenter") as FormArray;
  }
  public taxAccountsCostCenterRecords(): FormGroup {
    return this.fb.group({
      cid: new FormControl(null, [Validators.required]),
      amount: new FormControl("", [Validators.required]),
      costList: new FormControl([]),
    });
  }
  public addTaxAccountsCostCenter() {
    this.taxAccountsCostsArray.push(this.taxAccountsCostCenterRecords());
  }
  public removeTaxAccountsCostCenter(index) {
    this.taxAccountsCostsArray.removeAt(index);
  }
  clearTaxAccountsCostCenter() {
    for (let k = this.taxAccountsCostsArray.length - 1; k > -1; k--) {
      if (
        this.taxAccountsCostCenterGroup.controls[k].get("cid").value == null &&
        this.taxAccountsCostCenterGroup.controls[k].get("amount").value == ""
      ) {
        this.removeTaxAccountsCostCenter(k);
      }
    }
  }
  searchCosts(key, index, account_master, type) {
    // this.costData = [];
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value || "");
    // param.append("account_id", account_master || "");
    param.append("search_text", key.term);
    if (this.form.get("branch_id").value && key.term && key.term != undefined) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cctransactions").subscribe(
          (res) => {
            if (res.status) {
              if (type == "invoiceTo") {
                this.invoiceToCostsArray.controls[index]
                  ?.get("costList")
                  ?.setValue(res.records);
              } else if (type == "incomeAccounts") {
                this.incomeAccountsCostsArray.controls[index]
                  ?.get("costList")
                  ?.setValue(res.records);
              } else if (type == "cashAdvance") {
                this.cashAdvanceCostsArray.controls[index]
                  ?.get("costList")
                  ?.setValue(res.records);
              } else if (type == "taxAccounts") {
                this.taxAccountsCostsArray.controls[index]
                  ?.get("costList")
                  ?.setValue(res.records);
              }
            } else {
              if (type == "invoiceTo") {
                this.invoiceToCostsArray.controls[index]
                  ?.get("costList")
                  ?.setValue([]);
              } else if (type == "incomeAccounts") {
                this.incomeAccountsCostsArray.controls[index]
                  ?.get("costList")
                  ?.setValue([]);
              } else if (type == "cashAdvance") {
                this.cashAdvanceCostsArray.controls[index]
                  ?.get("costList")
                  ?.setValue([]);
              } else if (type == "taxAccounts") {
                this.taxAccountsCostsArray.controls[index]
                  ?.get("costList")
                  ?.setValue([]);
              }
            }
          },
          (error) => {
            if (type == "invoiceTo") {
              this.invoiceToCostsArray.controls[index]
                ?.get("costList")
                ?.setValue([]);
            } else if (type == "incomeAccounts") {
              this.incomeAccountsCostsArray.controls[index]
                ?.get("costList")
                ?.setValue([]);
            } else if (type == "cashAdvance") {
              this.cashAdvanceCostsArray.controls[index]
                ?.get("costList")
                ?.setValue([]);
            } else if (type == "taxAccounts") {
              this.taxAccountsCostsArray.controls[index]
                ?.get("costList")
                ?.setValue([]);
            }
          }
        )
      );
    }
  }
  public payment_mode_options(event) {
    if (event != undefined && event.key === "cash") {
      this.advance_acode_text = this.lang.transform("lang_cash_accounts");
      this.advance_ccode_text = this.lang.transform("lang_cash_costcenter");
    } else if (event != undefined && event.key === "bank") {
      this.advance_acode_text = this.lang.transform("lang_bank_accounts");
      this.advance_ccode_text = this.lang.transform("lang_bank_costcenter");
    } else if (event != undefined && event.key === "credit") {
      this.advance_acode_text = this.lang.transform("lang_credit_accounts");
      this.advance_ccode_text = this.lang.transform("lang_credit_costcenter");
    }
    this.form.get("invoice_credit_days").setValue("");
    this.form.get("invoice_credit_date").setValue("");
    if (event != undefined && event.key == "credit") {
      this.disable = false;
      this.form.get("invoice_credit_days").enable();
      this.form.get("invoice_credit_date").enable();
    } else {
      this.form.get("invoice_credit_days").disable();
      this.form.get("invoice_credit_date").disable();
      this.disable = true;
    }
    this.form.get("invoice_cash_advance_amount").setValue("");
    if (event == undefined || (event != undefined && event.key === "cash")) {
      this.form.get("invoice_cash_advance_amount").disable();
    } else {
      this.form.get("invoice_cash_advance_amount").enable();
    }
  }
  public add_days(days) {
    this.date = new Date(
      this.datePipe.transform(this.form.get("invoice_date").value, "yyyy/MM/dd")
    );
    this.date.setDate(this.date.getDate() + JSON.parse(days));
  }
  public calculate_invoice_tax(control, index, controlName?) {
    let quantity = control.get("invoice_item_quantity").value;
    let price = control.get("invoice_item_price").value;
    let amount: any = parseFloat(quantity) * parseFloat(price);

    let discount_percent = control.get("invoice_discount_percent").value;
    if (controlName == "invoice_discount_percent") {
      let discountamount: any = "0.00";
      if (discount_percent) {
        discountamount =
          (parseFloat(discount_percent) / 100) * parseFloat(amount);
        control
          .get("invoice_discount_percent_amount")
          .setValue(this.roundfunction(discountamount));
      } else {
        control.get("invoice_discount_percent_amount").setValue(0);
      }
    }
    let discountAmount =
      control.get("invoice_discount_percent_amount").value || "0.00";
    if (controlName == "invoice_discount_percent_amount") {
      let discountPercent: any = "0.00";
      if (discountAmount) {
        discountPercent =
          (parseFloat(discountAmount) * 100) / parseFloat(amount);
        control
          .get("invoice_discount_percent")
          .setValue(this.roundfunction(discountPercent));
      } else {
        control.get("invoice_discount_percent").setValue(0);
      }
    }
    let discount_amount_of_price = control.get(
      "invoice_discount_percent_amount"
    ).value;
    amount = amount - discount_amount_of_price;
    this.items.controls[index]
      .get("total_amount_discount")
      .setValue(amount.toFixed(3));
    let taxamount: any = 0.0;
    let tax = this.items.controls[index].get("invoice_item_tax").value;
    if (tax && tax.taxvalue) {
      taxamount = (parseFloat(tax.taxvalue) / 100) * parseFloat(amount);
      control.get("invoice_item_tax_amount").setValue(taxamount.toFixed(2));
    } else {
      control.get("invoice_item_tax_amount").setValue("");
    }
    this.calculate_total_calculation();
  }
  public calculate_total_calculation() {
    let items = this.form.get("items").value;
    let grand_total: any = 0;
    let grand_total_withoutround: any = 0;
    let total_item_tax: any = 0.0;
    let total_item_discount: any = 0.0;
    items.forEach((v, k) => {
      let amount: any =
        parseFloat(v.invoice_item_quantity) * parseFloat(v.invoice_item_price);
      let discountamount: any = "0.00";
      let discount_percent = v.invoice_discount_percent;
      if (discount_percent) {
        discountamount =
          (parseFloat(discount_percent) / 100) * parseFloat(amount);
        if (!isNaN(discountamount)) {
          total_item_discount += parseFloat(discountamount);
        }
      }
      amount = amount - discountamount;
      let taxamount: any = 0.0;
      let tax = v.invoice_item_tax;
      if (tax && tax.taxvalue) {
        taxamount = (parseFloat(tax.taxvalue) / 100) * parseFloat(amount);
        if (!isNaN(taxamount)) {
          total_item_tax += parseFloat(taxamount);
        }
      }
      let final_total_amount: any = this.roundfunction(amount);
      if (!isNaN(final_total_amount)) {
        grand_total += parseFloat(final_total_amount);
      }
      let final_total_amount_withoutround: any = amount;
      if (!isNaN(final_total_amount)) {
        grand_total_withoutround += parseFloat(final_total_amount_withoutround);
      }
    });

    this.form
      .get("invoice_grand_total")
      .setValue(this.roundfunction(grand_total));
    this.form
      .get("total_item_tax")
      .setValue(this.roundfunction(total_item_tax));
    this.form
      .get("total_item_discount")
      .setValue(this.roundfunction(total_item_discount));
    let net_total: any =
      parseFloat(grand_total_withoutround) + parseFloat(total_item_tax);
    this.form.get("invoice_net_total").setValue(this.roundfunction(net_total));

    let gdiscount_percent = this.form.get(
      "invoice_global_discount_percent"
    ).value;
    let gdiscountamount: any = "0.00";
    if (gdiscount_percent) {
      gdiscountamount =
        (parseFloat(gdiscount_percent) / 100) * parseFloat(net_total);
      if (!isNaN(gdiscountamount)) {
        net_total = parseFloat(net_total) - parseFloat(gdiscountamount);
      }
    }
    this.form
      .get("invoice_global_discount_amount")
      .setValue(this.roundfunction(gdiscountamount));

    let global_tax = this.form.get("invoice_global_tax_id").value;
    let gtaxamount: any = 0.0;
    if (global_tax && global_tax.taxvalue) {
      gtaxamount =
        (parseFloat(global_tax.taxvalue) / 100) * parseFloat(net_total);
    }
    this.form
      .get("invoice_global_tax_amount")
      .setValue(this.roundfunction(gtaxamount));
    let invoice_net_total: any = parseFloat(net_total) + parseFloat(gtaxamount);

    let gdis: any = parseFloat(grand_total) - parseFloat(gdiscountamount);
    this.form.get("invoice_grand_total").setValue(this.roundfunction(gdis));

    let advance_amount = this.form.get("invoice_cash_advance_amount").value;
    let payment_mode = this.form.get("invoice_payment_mode").value;
    if (advance_amount && payment_mode?.value !== "cash") {
      invoice_net_total =
        parseFloat(invoice_net_total) - parseFloat(advance_amount);
    }
    this.form.get("invoice_net_total").setValue(invoice_net_total);
    //this.form.get("invoice_roundoff_amount").setValue(invoice_net_total);
    this.defaultRoundoff = invoice_net_total;
  }

  calcRoundoffAmount(type: string) {
    const invoice_net_total = parseFloat(
      this.form.get("invoice_net_total").value
    );
    const currentRoundoff = parseFloat(
      this.form.get("invoice_roundoff_amount").value
    );
    if (type == "up") {
      if (currentRoundoff < invoice_net_total) {
        this.form
          .get("invoice_roundoff_amount")
          .setValue(
            parseFloat(
              parseFloat(parseFloat(`${invoice_net_total}`).toFixed(2)).toFixed(
                1
              )
            ).toFixed(2)
          );
      } else {
        let upValue = Math.ceil(invoice_net_total);
        this.form
          .get("invoice_roundoff_amount")
          .setValue(this.roundfunction(`${upValue}`));
      }
    } else if (type == "down") {
      if (currentRoundoff > invoice_net_total) {
        this.form
          .get("invoice_roundoff_amount")
          .setValue(
            parseFloat(
              parseFloat(parseFloat(`${invoice_net_total}`).toFixed(2)).toFixed(
                1
              )
            ).toFixed(2)
          );
      } else {
        let downValue = Math.floor(invoice_net_total);
        this.form
          .get("invoice_roundoff_amount")
          .setValue(this.roundfunction(`${downValue}`));
      }
    }
  }
  onToggleGlobal(event) {
    if (event?.checked) {
      this.items.controls.forEach((el, index) => {
        el.get("invoice_discount_percent").reset();
        this.calculate_invoice_tax(el, index, "invoice_discount_percent");
        el.get("invoice_item_tax").reset();
        this.calculate_invoice_tax(el, index);
      });
    } else {
      this.form.get("invoice_global_discount_percent").reset();
      this.calculate_total_calculation();
      this.form.get("invoice_global_tax_id").reset();
      this.global_tax_age();
    }
  }
  public global_tax_age() {
    let global_tax = this.form.get("invoice_global_tax_id").value;
    if (global_tax && global_tax.taxvalue) {
      this.form.get("invoice_global_tax_age").setValue(global_tax.taxvalue);
    } else {
      this.form.get("invoice_global_tax_age").setValue(0);
    }
    this.calculate_total_calculation();
  }
  public create_invoices() {
    this.spinner.show();
    this.clearEmptyInvoiceToCostCenter();
    // this.clearEmptyIncomeAccounts();
    this.clearEmptyCashAdvance();
    this.clearTaxAccountsCostCenter();
    this.clearEmptyItem();
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("finance_id", this.form.get("finance_id").value || "");
    param.append(
      "invoice_date",
      this.form.get("invoice_date").value || this.myDate
    );
    let invoice_payment_mode = this.form.get("invoice_payment_mode").value;
    if (invoice_payment_mode && invoice_payment_mode != undefined) {
      param.append("invoice_payment_mode", invoice_payment_mode?.value);
    }
    if (invoice_payment_mode && invoice_payment_mode.key == "credit") {
      param.append(
        "invoice_credit_days",
        this.form.get("invoice_credit_days").value
      );
    }
    param.append(
      "invoice_income_expanse_account_id",
      this.form.get("invoice_income_expanse_account_id").value
        ? this.form.get("invoice_income_expanse_account_id").value
        : ""
    );
    param.append(
      "invoice_to_account_id",
      this.form.get("invoice_to_account_id").value
        ? this.form.get("invoice_to_account_id").value
        : ""
    );
    param.append(
      "customers",
      this.form.get("customers").value ? this.form.get("customers").value : ""
    );
    param.append(
      "transaction_tages",
      this.form.get("transaction_tages").value
        ? this.form.get("transaction_tages").value
        : ""
    );
    param.append(
      "document_type",
      this.form.get("document_type").value
        ? this.form.get("document_type").value
        : ""
    );
    param.append(
      "actual_delivery_date",
      this.form.get("actual_delivery_date").value || ""
    );
    param.append(
      "return_parent_id",
      this.form.get("return_parent_id").value
        ? this.form.get("return_parent_id").value
        : ""
    );
    param.append("invoice_type_zatca", "388");
    param.append(
      "invoice_address_to",
      this.form.get("invoice_address_to").value || ""
    );
    param.append(
      "invoice_vat_tax_id",
      this.form.get("invoice_vat_tax_id").value
    );
    param.append(
      "invoice_ref_number",
      this.form.get("invoice_ref_number").value
    );
    param.append(
      "invoice_instruction_remarks",
      this.form.get("invoice_instruction_remarks").value
    );
    // param.append(
    // 	"invoice_to_cost_center_id",
    // 	this.form.get("invoice_to_cost_center_id").value
    // 		? this.form.get("invoice_to_cost_center_id").value
    // 		: ""
    // );
    this.invoiceToCostsArray?.controls?.forEach((el, index) => {
      param.append(
        `invoice_to_cost_center_id[${index}][cid]`,
        el?.get("cid")?.value || ""
      );
      param.append(
        `invoice_income_expanse_cost_id[${index}][cid]`,
        el?.get("cid")?.value || ""
      );
      param.append(
        `invoice_to_cost_center_id[${index}][amount]`,
        el?.get("debit_amount")?.value || ""
      );
      param.append(
        `invoice_income_expanse_cost_id[${index}][amount]`,
        el?.get("credit_amount")?.value || ""
      );
    });
    //Items Data
    let items = this.form.get("items").value;
    items.forEach((v, k) => {
      param.append("items[" + k + "][invoice_item_name]", v.invoice_item_name);
      param.append("items[" + k + "][invoice_item_unit]", v.invoice_item_unit);
      param.append(
        "items[" + k + "][invoice_item_quantity]",
        v.invoice_item_quantity
      );
      param.append(
        "items[" + k + "][invoice_item_price]",
        v.invoice_item_price
      );
      param.append(
        "items[" + k + "][invoice_item_tax]",
        v.invoice_item_tax && v.invoice_item_tax.value
          ? v.invoice_item_tax.value
          : ""
      );
      param.append(
        "items[" + k + "][invoice_discount_percent]",
        v.invoice_discount_percent == "0.00" ? "" : v.invoice_discount_percent
      );
      param.append(
        "items[" + k + "][invoice_item_description]",
        v.invoice_item_description
      );
    });
    //Total Data
    param.append(
      "invoice_grand_total",
      this.form.get("invoice_grand_total").value
    );
    param.append(
      "invoice_global_discount_percent",
      this.form.get("invoice_global_discount_percent").value
    );
    param.append(
      "invoice_global_tax_id",
      this.form.get("invoice_global_tax_id").value
        ? this.form.get("invoice_global_tax_id").value.value
        : ""
    );
    param.append(
      "invoice_global_tax_amount",
      this.form.get("invoice_global_tax_amount").value
    );
    param.append("invoice_net_total", this.form.get("invoice_net_total").value);
    param.append(
      "invoice_roundoff_account_id",
      this.form.get("invoice_roundoff_account_id").value || ""
    );
    param.append(
      "invoice_discount_account_id",
      this.form.get("invoice_discount_account_id").value || ""
    );
    param.append(
      "invoice_roundoff_amount",
      this.form.get("invoice_roundoff_amount").value || ""
    );
    param.append(
      "invoice_income_expanse_account_id",
      this.form.get("invoice_income_expanse_account_id").value
        ? this.form.get("invoice_income_expanse_account_id").value
        : ""
    );

    // this.incomeAccountsCostsArray?.controls?.forEach((el, index) => {
    //   param.append(
    //     `invoice_income_expanse_cost_id[${index}][cid]`,
    //     el?.get("cid")?.value || ""
    //   );
    //   param.append(
    //     `invoice_income_expanse_cost_id[${index}][amount]`,
    //     el?.get("amount")?.value || ""
    //   );
    // });
    param.append(
      "invoice_tax_account_id",
      this.form.get("invoice_tax_account_id").value
        ? this.form.get("invoice_tax_account_id").value
        : ""
    );
    this.taxAccountsCostsArray?.controls?.forEach((el, index) => {
      param.append(
        `invoice_tax_cost_center_id[${index}][cid]`,
        el?.get("cid")?.value || ""
      );
      param.append(
        `invoice_tax_cost_center_id[${index}][amount]`,
        el?.get("amount")?.value || ""
      );
    });
    if (this.form.get("invoice_cash_advance_amount").value != "") {
      param.append(
        "invoice_cash_advance_amount",
        this.form.get("invoice_cash_advance_amount").value
      );
      this.cashAdvanceCostsArray?.controls?.forEach((el, index) => {
        param.append(
          `invoice_cash_advance_cost_center_id[${index}][cid]`,
          el?.get("cid")?.value || ""
        );
        param.append(
          `invoice_cash_advance_cost_center_id[${index}][amount]`,
          el?.get("amount")?.value || ""
        );
      });
    }
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "csinvoice").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.alert.success(res.msg);
            this.router.navigate([
              "accounts/vsinvoices/" +
                res.branch_id +
                "/" +
                res.invoice_id +
                "/" +
                this.form.get("finance_id").value +
                "/view",
            ]);
            this.form.reset();
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      )
    );
  }
  public searchAccounts(key, payment_mode, field) {
    this.accounts = [];
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("finance_id", this.form.get("finance_id").value);
    param.append("search_text", key.term);
    param.append("invoice_type", "SINV");
    param.append("search_field", field);
    if (payment_mode && payment_mode != undefined) {
      param.append("payment_mode", payment_mode?.value);
    }
    if (this.form.get("branch_id").value && key.term) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "sam/accounts_add").subscribe((data) => {
          if (data.status) {
            this.accounts = data.records;
          } else {
            this.accounts = [];
          }
        })
      );
    }
  }
  public searchcustomer(key) {
    this.customers = [];
    let param = new FormData();
    param.append("searchKey", key.term);
    if (this.form.get("branch_id").value && key.term) {
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cs/get_search").subscribe((data) => {
          if (data.status) {
            this.data = data.records;
            this.customers = [
              {
                label:
                  this.lang_key == "en"
                    ? `${data?.records?.customer_title_en}`
                    : `${data?.records?.customer_title_ar}`,
                value: `${data?.records?.customer_id}`,
              },
            ];
          }
        })
      );
    }
  }
  getFinancialList() {
    this.ds.get("financial/" + this.form.get("branch_id").value).subscribe(
      (res) => {
        if (res?.status) {
          this.financeList = res?.records;
          this.form?.get("finance_id")?.setValue(this.finance_id);
        } else {
          this.financeList = [];
          this.sweetAlert.errorToast(res?.error, 2000);
        }
      },
      (err) => {
        this.financeList = [];
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          2000
        );
      }
    );
  }
  selectFinance() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }
  Addinfo() {
    this.form.get("invoice_vat_tax_id").setValue(this.data?.customer_tax_vat);
    this.form.get("invoice_address_to").setValue(this.data?.customer_address);
    this.form.get("ccr_number").setValue(this.data?.customer_ccr_no);
  }
  public registration_form() {
    this.registrationForm = new FormGroup({
      branch_id: new FormControl("", [Validators.required]),
      customer_title_en: new FormControl("", [Validators.required]),
      customer_title_ar: new FormControl("", [Validators.required]),
      logo: new FormControl(""),
      customer_email_address: new FormControl("", [Validators.email]),
      customer_mobile: new FormControl(""),
      customer_tax_vat: new FormControl(""),
      customer_ccr_no: new FormControl(""),
      customer_managers: new FormControl(""),
      customer_teams: new FormControl(""),
      customer_link_with_accounts: new FormControl(0, [Validators.required]),
      customer_address: new FormControl(""),
      customer_credit_days: new FormControl(""),
      customer_security_amount: new FormControl(""),
      customer_credit_limit: new FormControl(""),
      customer_discount_limit: new FormControl(""),
      customer_discount_onpay: new FormControl(""),
      customer_salesmancode: new FormControl(""),
      customer_salesmanarea: new FormControl(""),
      customer_stop_transaction: new FormControl(""),
      customer_account_id: new FormControl(""),
      street_name: new FormControl("", [Validators.required]),
      building_number: new FormControl("", [Validators.required]),
      plot_identification: new FormControl("", [Validators.required]),
      city_sub_division: new FormControl("", [Validators.required]),
      city: new FormControl("", [Validators.required]),
      postal_number: new FormControl("", [Validators.required]),
      registration_name: new FormControl("", [Validators.required]),
    });
  }
  public createCustomer() {
    if (!this.isEmailExists && !this.isMobExists) {
      this.spinner.show();
      let param = this.ds.json2formdata(this.registrationForm.value);
      if (
        this.registrationForm.get("customer_link_with_accounts").value == "1"
      ) {
        param.set(
          "customer_account_id",
          this.registrationForm.get("customer_account_id").value || ""
        );
      }

      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cs/create").subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.alert.success(data.msg);
              this.registrationForm.reset();
              setTimeout(() => {
                this.router.navigate(["/customers/view/" + data.insert_id]);
              }, 1000);
            } else {
              this.alert.error(data.error);
            }
          },
          (error) => {
            this.spinner.hide();
            this.alert.error(
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
      );
    }
  }
  checkEmailExists() {
    let formData = new FormData();
    formData.append(
      "customer_email_address",
      this.registrationForm.get("customer_email_address").value || ""
    );
    this.ds.post("cs/emailexists", formData).subscribe((res) => {
      if (res?.error) {
        this.isEmailExists = true;
        this.emailErrMsg = res?.error[0]?.title;
      } else {
        this.isEmailExists = false;
        this.emailErrMsg = "";
      }
    });
  }
  checkMobExists() {
    let formData = new FormData();
    formData.append(
      "customer_mobile",
      this.registrationForm.get("customer_mobile").value || ""
    );
    this.ds.post("cs/mobileexists", formData).subscribe((res) => {
      if (res?.error) {
        this.isMobExists = true;
        this.mobErrMsg = res?.error[0]?.title;
      } else {
        this.isMobExists = false;
        this.mobErrMsg = "";
      }
    });
  }
  public uploadFile(event) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      this.filename = fileid.name;
      this.isFile = true;
      this.registrationForm.get("logo").setValue(fileid, { emitEvent: true });
    } else {
      this.isFile = false;
      this.registrationForm.get("logo").setValue("");
      this.filename = this.lang.transform("lang_select_files");
    }
  }
  public removeFile() {
    this.isFile = false;
    this.registrationForm.get("logo").setValue("");
    this.filename = this.lang.transform("lang_select_files");
  }
  public emptyAccountFileds() {
    this.registrationForm.patchValue({
      customer_address: null,
      customer_credit_days: null,
      customer_security_amount: null,
      customer_credit_limit: null,
      customer_discount_limit: null,
      customer_discount_onpay: null,
      customer_salesmancode: null,
      customer_salesmanarea: null,
      customer_stop_transaction: null,
      customer_account_id: null,
    });
  }
  public search_users(key) {
    if (this.registrationForm.get("branch_id").value && key != undefined) {
      let param = new FormData();
      param.append("branch_id", this.registrationForm.get("branch_id").value);
      param.append("search_text", key.term);
      param.append("permission_key", "customers_add");
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "cs/susers").subscribe((data) => {
          if (data.status) {
            this.users = data.records;
          }
        })
      );
    } else {
      this.ds.dialogf("", this.lang.transform("lang_choose_branch"));
    }
  }
  public accountSearch(key) {
    this.accounts = [];
    if (
      this.registrationForm.get("branch_id").value &&
      key != undefined &&
      key.term
    ) {
      let param = new FormData();
      param.append("branch_id", this.registrationForm.get("branch_id").value);
      param.append("search_text", key.term);
      param.append("status", "1");
      this.subscriptions.add(
        this.ds.postActionByUrl(param, "sam/accounts_add").subscribe((data) => {
          if (data.status) {
            this.accounts = data.records;
          }
        })
      );
    }
  }
  closeSelect(select: NgSelectComponent) {
    select.close();
  }
  roundfunction(number) {
    if (number) {
      number = Number(number);
      number = Number(
        this._decimalPipe.transform(number, "1.0-2").replace(",", "")
      );
      if (
        Number(this._decimalPipe.transform(number, "1.0-1").replace(",", "")) >
        number
      ) {
        number = Number(
          this._decimalPipe.transform(number, "1.0-1").replace(",", "")
        );
      }
      number = parseFloat(number).toFixed(2);
    }
    return number;
  }
  public getsinvoice_calculations(fromType?: string) {
    this.clearEmptyInvoiceToCostCenter();
    // this.clearEmptyIncomeAccounts();
    this.clearEmptyCashAdvance();
    this.clearTaxAccountsCostCenter();
    this.clearEmptyItem();
    let param = new FormData();
    param.append("branch_id", this.form.get("branch_id").value);
    param.append("finance_id", this.form.get("finance_id").value || "");
    param.append(
      "invoice_date",
      this.form.get("invoice_date").value || this.myDate
    );
    let invoice_payment_mode = this.form.get("invoice_payment_mode").value;
    param.append("invoice_payment_mode", invoice_payment_mode?.value);
    if (invoice_payment_mode && invoice_payment_mode != undefined) {
      param.append("invoice_payment_mode", invoice_payment_mode?.value);
    }
    if (invoice_payment_mode && invoice_payment_mode.key == "credit") {
      param.append(
        "invoice_credit_days",
        this.form.get("invoice_credit_days").value
      );
    }
    param.append(
      "invoice_income_expanse_account_id",
      this.form.get("invoice_income_expanse_account_id").value
        ? this.form.get("invoice_income_expanse_account_id").value
        : ""
    );
    param.append(
      "invoice_to_account_id",
      this.form.get("invoice_to_account_id").value
        ? this.form.get("invoice_to_account_id").value
        : ""
    );
    param.append(
      "transaction_tages",
      this.form.get("transaction_tages").value
        ? this.form.get("transaction_tages").value
        : ""
    );
    param.append(
      "invoice_address_to",
      this.form.get("invoice_address_to").value
    );
    param.append(
      "invoice_vat_tax_id",
      this.form.get("invoice_vat_tax_id").value
    );
    param.append(
      "invoice_ref_number",
      this.form.get("invoice_ref_number").value
    );
    param.append(
      "invoice_instruction_remarks",
      this.form.get("invoice_instruction_remarks").value
    );
    //Items Data
    let items = this.form.get("items").value;
    items.forEach((v, k) => {
      param.append("items[" + k + "][invoice_item_name]", v.invoice_item_name);
      param.append("items[" + k + "][invoice_item_unit]", v.invoice_item_unit);
      param.append(
        "items[" + k + "][invoice_item_quantity]",
        v.invoice_item_quantity
      );
      param.append(
        "items[" + k + "][invoice_item_price]",
        v.invoice_item_price
      );
      param.append(
        "items[" + k + "][invoice_item_tax]",
        v.invoice_item_tax && v.invoice_item_tax.value
          ? v.invoice_item_tax.value
          : ""
      );
      param.append(
        "items[" + k + "][invoice_discount_percent]",
        v.invoice_discount_percent == "0.00" ? "" : v.invoice_discount_percent
      );
      param.append(
        "items[" + k + "][invoice_item_description]",
        v.invoice_item_description
      );
    });
    //Total Data
    param.append(
      "invoice_grand_total",
      this.form.get("invoice_grand_total").value
    );
    if (fromType == "fromGlobalAmount") {
      param.append("invoice_global_discount_percent", "0");
    } else {
      param.append(
        "invoice_global_discount_percent",
        this.form.get("invoice_global_discount_percent").value
      );
    }

    param.append(
      "invoice_global_discount_amount",
      this.form.get("invoice_global_discount_amount").value
    );
    param.append(
      "invoice_global_tax_id",
      this.form.get("invoice_global_tax_id").value
        ? this.form.get("invoice_global_tax_id").value.value
        : ""
    );
    param.append(
      "invoice_global_tax_amount",
      this.form.get("invoice_global_tax_amount").value
    );
    param.append("invoice_net_total", this.form.get("invoice_net_total").value);
    param.append(
      "invoice_income_expanse_account_id",
      this.form.get("invoice_income_expanse_account_id").value
        ? this.form.get("invoice_income_expanse_account_id").value
        : ""
    );
    param.append(
      "invoice_tax_account_id",
      this.form.get("invoice_tax_account_id").value
        ? this.form.get("invoice_tax_account_id").value
        : ""
    );
    this.taxAccountsCostsArray?.controls?.forEach((el, index) => {
      param.append(
        `invoice_tax_cost_center_id[${index}][cid]`,
        el?.get("cid")?.value || ""
      );
      param.append(
        `invoice_tax_cost_center_id[${index}][amount]`,
        el?.get("amount")?.value || ""
      );
    });
    if (this.form.get("invoice_cash_advance_amount").value != "") {
      param.append(
        "invoice_cash_advance_amount",
        this.form.get("invoice_cash_advance_amount").value
      );
      param.append(
        "invoice_cash_advance_account_id",
        this.form.get("invoice_cash_advance_account_id").value
          ? this.form.get("invoice_cash_advance_account_id").value
          : ""
      );
      this.cashAdvanceCostsArray?.controls?.forEach((el, index) => {
        param.append(
          `invoice_cash_advance_cost_center_id[${index}][cid]`,
          el?.get("cid")?.value || ""
        );
        param.append(
          `invoice_cash_advance_cost_center_id[${index}][amount]`,
          el?.get("amount")?.value || ""
        );
      });
    }
    param.append(
      "invoice_discount_account_id",
      this.form.get("invoice_discount_account_id").value || ""
    );
    this.subscriptions.add(
      this.ds.postActionByUrl(param, "getsinvoice_calculations").subscribe(
        (res) => {
          this.spinner.hide();
          if (res.status) {
            this.totalDiscount = res?.invoiceData?.invoice_item_discount_amount;
            this.form
              .get("invoice_grand_total")
              .setValue(res?.invoiceData?.invoice_grand_total);
            this.form
              .get("total_item_tax")
              .setValue(res?.invoiceData?.invoice_item_tax_amount);
            this.form
              .get("total_item_discount")
              .setValue(res?.invoiceData?.invoice_item_discount_amount);
            this.form
              .get("invoice_net_total")
              .setValue(res?.invoiceData?.invoice_net_total);

            this.form
              .get("invoice_roundoff_amount")
              .setValue(res?.invoiceData?.invoice_roundoff_amount);

            if (fromType != "fromGlobalAmount") {
              this.form
                .get("invoice_global_discount_amount")
                .setValue(res?.invoiceData?.invoice_global_discount_amount);
            } else {
              this.form
                .get("invoice_global_discount_percent")
                .setValue(res?.invoiceData?.invoice_global_discount_percent);
            }

            this.form
              .get("invoice_global_tax_amount")
              .setValue(res?.invoiceData?.invoice_global_tax_amount);
            let items = this.form.get("items").value;
            items.forEach((v, k) => {
              if (k > -1) {
                let invoice_total_amount: number =
                  Number(res?.itemData[k]?.invoice_item_quantity) *
                  Number(res?.itemData[k]?.invoice_item_price);
                let invoice_item_amount =
                  invoice_total_amount +
                  Number(res?.itemData[k]?.invoice_item_tax_amount);
                this.itemvalid.controls[k]
                  .get("invoice_discount_percent_amount")
                  .setValue(res?.itemData[k]?.invoice_item_disc_amount);
                this.itemvalid.controls[k]
                  .get("invoice_item_tax_amount")
                  .setValue(res?.itemData[k]?.invoice_item_tax_amount);
                this.itemvalid.controls[k]
                  .get("invoice_discount_percent")
                  .setValue(res?.itemData[k]?.invoice_discount_percent);
                this.items.controls[k]
                  .get("invoice_total_amount")
                  .setValue(parseFloat(`${invoice_total_amount}`).toFixed(3));
                this.items.controls[k]
                  .get("invoice_item_amount")
                  .setValue(parseFloat(`${invoice_item_amount}`).toFixed(3));
              }
            });
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
            this.form.get("invoice_grand_total").setValue("0.00");
            this.form.get("total_item_tax").setValue("0.00");
            this.form.get("total_item_discount").setValue("0.00");
            this.form.get("invoice_net_total").setValue("0.00");
            this.totalDiscount = "0.00";
            this.form.get("invoice_roundoff_amount").setValue("0.00");

            this.form.get("invoice_global_discount_amount").setValue("0.00");
            this.form.get("invoice_global_tax_amount").setValue("0.00");
            let items = this.form.get("items").value;
            items.forEach((v, k) => {
              if (k > -1) {
                this.itemvalid.controls[k]
                  .get("invoice_discount_percent_amount")
                  .setValue("0.00");
                this.itemvalid.controls[k]
                  .get("invoice_item_tax_amount")
                  .setValue("0.00");
                this.itemvalid.controls[k]
                  .get("invoice_discount_percent")
                  .setValue("0.00");
                this.itemvalid.controls[k]
                  .get("invoice_total_amount")
                  .setValue("0.00");
                this.itemvalid.controls[k]
                  .get("invoice_item_amount")
                  .setValue("0.00");
              }
            });
          }
        },
        (error) => {
          this.spinner.hide();
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
          this.form.get("invoice_grand_total").setValue("0.00");
          this.form.get("total_item_discount").setValue("0.00");
          this.form.get("invoice_cash_advance_amount").setValue("0.00");
          this.form.get("invoice_global_discount_amount").setValue("0.00");
          this.form.get("total_item_tax").setValue("0.00");
          this.form.get("invoice_global_tax_amount").setValue("0.00");
          this.form.get("invoice_net_total").setValue("0.00");
          this.form.get("invoice_roundoff_amount").setValue("0.00");
          let items = this.form.get("items").value;
          items.forEach((v, k) => {
            if (k > -1) {
              this.items.controls[k]
                .get("invoice_discount_percent_amount")
                .setValue("0.00");
              this.items.controls[k]
                .get("invoice_item_tax_amount")
                .setValue("0.00");
              this.items.controls[k]
                .get("invoice_discount_percent")
                .setValue("0.00");
              this.items.controls[k]
                .get("invoice_total_amount")
                .setValue("0.00");
              this.items.controls[k]
                .get("invoice_item_amount")
                .setValue("0.00");
            }
          });
        }
      )
    );
  }
}
