<div class="card tracking-reports">
    <div class="card-header d-flex justify-content-between">
        {{ "lang_tracking_reports" | language }}
        <i class="fa fa-plus" (click)="onAddTrackingReport()"></i>
    </div>
    <div class="card-body">
        <div class="martb0">
            <form [formGroup]="filterForm">
                <div class="row">
                    <div class="col-12 col-sm-3 mb-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>{{ "lang_date" | language }}</mat-label>
                            <mat-date-range-input [rangePicker]="picker">
                                <input
                                    matStartDate
                                    formControlName="start_date"
                                    placeholder="YYYY/MM/DD"
                                    readonly
                                />
                                <input
                                    matEndDate
                                    formControlName="end_date"
                                    placeholder="YYYY/MM/DD"
                                    readonly
                                />
                            </mat-date-range-input>
                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                            ></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-3 mb-2">
                      <div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
                        <mat-form-field appearance="outline" style="width: 100%">
                          <mat-label>{{ "lang_project_id" | language }}</mat-label>
                          <input
                            matInput
                            placeholder="{{ 'lang_project_id' | language }}"
                            type="text"
                            formControlName="project_id"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="col-12 col-sm-3 mb-2">
                        <ng-select
                            [items]="emailsList"
                            [multiple]="true"
                            bindLabel="label"
                            bindValue="value"
                            appendTo="body"
                            clearAllText="Clear"
                            (search)="searchUserEmail($event)"
                            [searchFn]="ds.customSearchFn"
                            formControlName="user_emails"
                            placeholder="{{
                            'lang_email' | language
                            }}"
                        ></ng-select>
                    </div>
                    <div class="col-12 col-sm-3 mb-2">
                        <ng-select
                            [items]="typeList"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            appendTo="body"
                            clearAllText="Clear"
                            formControlName="recordstype"
                            placeholder="{{
                            'lang_type' | language
                            }}"
                        ></ng-select>
                    </div>

                    <div class="col-12 col-sm-3 mb-2">
                      <mat-checkbox formControlName="project_group_by_file_name">
                        {{ "lang_project_group_by_file_name" | language }}
                      </mat-checkbox>
                    </div>
                    <div class="col-12 col-sm-4 mb-2">
                      <mat-checkbox formControlName="user_emails_group_by_file_name">
                        {{ "lang_user_emails_group_by_file_name" | language }}
                      </mat-checkbox>
                    </div>

                    <div class="col-12 col-sm-3 mb-2">
                      <button type="button" class="albutton" (click)="getTrackingReportsTotal();getTrackingReports()">{{'lang_submit' | language}}</button>
                    </div>

                    <div class="col-md-12">
                        <div class="table-responsive dropdownyes customResponsive">
                          <table border="1" class="table CustomTable">
                            <thead>
                              <tr>
                                <td>#</td>
                                <td *ngIf="ds.allowMe('human_resources', 'human_resources_delete')">
                                  <div
                                    class="checkbox"
                                    matTooltip="{{ 'lang_check_all' | language }}"
                                  >
                                    <input
                                      type="checkbox"
                                      class="allItems checkbox"
                                      id="allItems"
                                      (click)="checkAllItems($event)"
                                    />
                                    <label for="checkbox"></label>
                                  </div>
                                </td>
                                <td>{{ "lang_email" | language }}</td>
                                <td>{{ "lang_total_hours" | language }}</td>
                                <td>{{ "lang_date" | language }}</td>
                                <td>{{ "lang_app_name" | language }}</td>
                                <td>{{ "lang_notes" | language }}</td>
                              </tr>
                            </thead>
                            <tbody class="AppendList" *ngIf="trackingReports?.length">
                              <tr
                                *ngFor="
                                  let item of trackingReports;
                                  let i = index;
                                  let odd = odd
                                  let even = even
                                "
                                [ngClass]="{ odd: odd, even: even }"
                                class="makeback removerow{{ item?.id }}"
                              >
                                <td>{{ i + 1 }}</td>
                                <td *ngIf="ds.allowMe('human_resources', 'human_resources_delete')">
                                  <div
                                    class="checkbox"
                                    matTooltip="{{ 'lang_check' | language }}"
                                    *ngIf="item?.recordstype == 'manual'"
                                  >
                                    <input
                                      type="checkbox"
                                      class="eachItem checkbox"
                                      value="{{ item?.id }}"
                                      (click)="checkEachItem($event)"
                                    />
                                    <label for="checkbox"></label>
                                  </div>
                                </td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.total_hours }}</td>
                                <td>{{ item.date }}</td>
                                <td>{{ item.app_name }}</td>
                                <td>{{ item.file_name }}</td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="!trackingReports?.length" class="AppendList">
                              <tr class="odd">
                                <td colspan="6" align="center">{{ errormessagef }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                    </div>
                    
                </div>
                <div class="row mt-4" *ngIf="total_records">
                    <div class="col-lg-1 col-sm-1  mb-10 " >
                      <i
                        class="deleteIconDiv none material-icons red deleteIcon"
                        *ngIf="ds.allowMe('human_resources', 'human_resources_delete')"
                        (click)="deleteBulkData()"
                        aria-hidden="true"
                        matTooltip="{{ 'lang_delete' | language }}"
                        >delete_forever</i
                      >
                    </div>
                    <div class="col-lg-3 col-sm-3 mb-10">
                      {{ "lang_total" | language }} {{ total_records }}
                    </div>

                    <div class="col-12 col-sm-6 d-flex justify-content-center">
                      <ngb-pagination
                        [collectionSize]="total_records"
                        [maxSize]="3"
                        [rotate]="true"
                        [ellipses]="false"
                        [boundaryLinks]="true"
                        [(page)]="page_no"
                        [pageSize]="page_size"
                        (pageChange)="getTrackingReports()"
                      ></ngb-pagination>
                    </div>
                    <div class="col-12 col-sm-2 d-flex justify-content-end">
                      <mat-select
                        class="customselect"
                        [(ngModel)]="page_size"
                        [ngModelOptions]="{standalone: true}"
                        (change)="getTrackingReports()"
                      >
                        <mat-option [value]="'10'">10 {{ "lang_per_page" | language }}</mat-option>
                        <mat-option value="20">20 {{ "lang_per_page" | language }}</mat-option>
                        <mat-option value="50">50 {{ "lang_per_page" | language }}</mat-option>
                        <mat-option value="100"
                          >100 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option value="250"
                          >250 {{ "lang_per_page" | language }}</mat-option
                        >
                        <mat-option value="500"
                          >500 {{ "lang_per_page" | language }}</mat-option
                        >
                      </mat-select>
                    </div>
                  </div>
            </form>
        </div>
    </div>
</div>