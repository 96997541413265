<breadcrumb></breadcrumb>
<section class="panel-expansion matborderno" *ngIf="myprofile">
  <mat-accordion class="stylepanels">
    <mat-expansion-panel class="mpanel my_profile" [expanded]="true">
      <mat-expansion-panel-header class="mpanel-header">{{
        "lang_myprofile" | language
      }}</mat-expansion-panel-header>
      <div class="material-panel-body">
        <div class="mbrl15 martb0">
          <div class="row">
            <div class="col-xs-12 col-12 pad0 suppervision-stepper">
              <div class="wrapper">
                <owl-carousel-o [options]="customOptions" #stepperCar>
                  <ng-template carouselSlide id="s1">
                    <div (click)="moveStepper('human_resources_id_details')">
                      <div class="step">
                        <div class="st">
                          <i
                            class="icon-5x fa fa-user fa-icon stepper-header-icon stepper-active human_resources_id_details"
                          ></i>
                        </div>
                      </div>
                      <div
                        matTooltip="{{
                          'lang_human_resources_id_details' | language
                        }}"
                        style="text-align: center"
                      >
                        {{ "lang_human_resources_id_details" | language }}
                      </div>
                    </div>
                  </ng-template>

                  <ng-template carouselSlide id="s2">
                    <div
                      (click)="moveStepper('human_resources_contract_details')"
                    >
                      <div class="step">
                        <div class="st">
                          <i
                            class="icon-5x fa fa-files-o fa-icon stepper-header-icon human_resources_contract_details"
                          ></i>
                        </div>
                      </div>
                      <div
                        matTooltip="{{
                          'lang_human_resources_contract_details' | language
                        }}"
                        style="text-align: center"
                      >
                        {{ "lang_human_resources_contract_details" | language }}
                      </div>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide id="s3">
                    <div (click)="moveStepper('human_resources_job_details')">
                      <div class="step">
                        <div class="st">
                          <i
                            class="icon-5x fa fa-suitcase fa-icon stepper-header-icon human_resources_job_details"
                          ></i>
                        </div>
                      </div>
                      <div
                        matTooltip="{{
                          'lang_human_resources_job_details' | language
                        }}"
                        style="text-align: center"
                      >
                        {{ "lang_human_resources_job_details" | language }}
                      </div>
                    </div>
                  </ng-template>
                  <ng-template carouselSlide id="s4">
                    <div (click)="moveStepper('human_resources_communication')">
                      <div class="step">
                        <div class="st">
                          <i
                            class="icon-5x fa fa-link fa-icon stepper-header-icon human_resources_communication"
                          ></i>
                        </div>
                      </div>
                      <div
                        matTooltip="{{ 'lang_communications' | language }}"
                        style="text-align: center"
                      >
                        {{ "lang_communications" | language }}
                      </div>
                    </div>
                  </ng-template>
                  <ng-container>
                    <ng-template carouselSlide id="s5">
                      <div
                        (click)="moveStepper('human_resources_contact_details')"
                      >
                        <div class="step">
                          <div class="st">
                            <mat-icon
                              class="mnabricon stepper-header-icon human_resources_contact_details"
                              >supervisor_account</mat-icon
                            >
                          </div>
                        </div>
                        <div
                          matTooltip="{{
                            'lang_human_resources_contact_details' | language
                          }}"
                          style="text-align: center"
                        >
                          {{
                            "lang_human_resources_contact_details" | language
                          }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container>
                    <ng-template carouselSlide id="s6">
                      <div
                        (click)="moveStepper('human_resources_bank_details')"
                      >
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-mobile fa-icon stepper-header-icon human_resources_bank_details"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{
                            'lang_human_resources_bank_details' | language
                          }}"
                          style="text-align: center"
                        >
                          {{ "lang_human_resources_bank_details" | language }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container>
                    <ng-template carouselSlide id="s7">
                      <div
                        (click)="
                          moveStepper('human_resources_education_details')
                        "
                      >
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-book fa-icon stepper-header-icon human_resources_education_details"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{
                            'lang_human_resources_education_details' | language
                          }}"
                          style="text-align: center"
                        >
                          {{
                            "lang_human_resources_education_details" | language
                          }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container>
                    <ng-template carouselSlide id="s8">
                      <div
                        (click)="
                          moveStepper('human_resources_passport_details')
                        "
                      >
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-plane fa-icon stepper-header-icon human_resources_passport_details"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{
                            'lang_human_resources_passport_details' | language
                          }}"
                          style="text-align: center"
                        >
                          {{
                            "lang_human_resources_passport_details" | language
                          }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>

                  <ng-container>
                    <ng-template carouselSlide id="s9">
                      <div
                        (click)="
                          moveStepper('human_resources_insurance_details')
                        "
                      >
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-hospital-o fa-icon stepper-header-icon human_resources_insurance_details"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{
                            'lang_human_resources_insurance_details' | language
                          }}"
                          style="text-align: center"
                        >
                          {{
                            "lang_human_resources_insurance_details" | language
                          }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>

                  <ng-container>
                    <ng-template carouselSlide id="s10">
                      <div (click)="moveStepper('vacations')">
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-address-book fa-icon stepper-header-icon vacations"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_vacations' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_vacations" | language }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container>
                    <ng-template carouselSlide id="s11">
                      <div (click)="moveStepper('notes')">
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-sticky-note-o fa-icon stepper-header-icon notes"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_notes' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_notes" | language }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container>
                    <ng-template carouselSlide id="s12">
                      <div (click)="moveStepper('attachments')">
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-paperclip fa-icon stepper-header-icon attachments"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_attachments' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_attachments" | language }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>

                  <ng-container>
                    <ng-template carouselSlide id="s13">
                      <div (click)="moveStepper('modules')">
                        <div class="step">
                          <div class="st">
                            <i
                              class="icon-5x fa fa-address-book fa-icon stepper-header-icon modules"
                            ></i>
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_modules' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_modules" | language }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container>
                    <ng-template carouselSlide id="s14">
                      <div (click)="moveStepper('signature')">
                        <div class="step">
                          <div class="st">
                            <mat-icon
                              class="icon-5x stepper-header-icon signature"
                              >gamepad</mat-icon
                            >
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_signature' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_signature" | language }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>

                  <ng-container>
                    <ng-template carouselSlide id="s15">
                      <div (click)="moveStepper('calender_view')">
                        <div class="step">
                          <div class="st">
                            <!-- 	<i
																class="icon-5x fa fa-comments-o fa-icon stepper-header-icon"
															></i> -->
                            <mat-icon
                              class="icon-5x stepper-header-icon calender_view"
                              >event_available</mat-icon
                            >
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_calender_view' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_calender_view" | language }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                  <ng-container>
                    <ng-template carouselSlide id="s16">
                      <div (click)="moveStepper('finanial_details')">
                        <div class="step">
                          <div class="st">
                            <mat-icon
                              class="icon-5x stepper-header-icon finanial_details"
                              >attach_money</mat-icon
                            >
                          </div>
                        </div>
                        <div
                          matTooltip="{{ 'lang_finanial_details' | language }}"
                          style="text-align: center"
                        >
                          {{ "lang_finanial_details" | language }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
                <button
                  (click)="
                    lang_key == 'en' ? stepperCar.prev() : stepperCar.next()
                  "
                  class="carousel-btn-pre albutton mnabrbutton"
                >
                  <i class="fa fa-chevron-left"></i>
                </button>
                <button
                  (click)="
                    lang_key == 'en' ? stepperCar.next() : stepperCar.prev()
                  "
                  class="carousel-btn-next albutton mnabrbutton"
                >
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
              <mat-horizontal-stepper #stepper class="pad0">
                <mat-step>
                  <div
                    class="card"
                    *ngIf="selectedName == 'human_resources_id_details'"
                  >
                    <i
                      *ngIf="attachments.id0001"
                      matTooltip="{{ 'lang_recent_attachment' | language }} : {{
                        attachments.id0001_d
                      }}"
                      class="fa fa-paperclip linkicon mnabricon"
                      (click)="
                        ds.preview_pdf_file(attachments.id0001, 'id0001')
                      "
                    ></i>
                    <div class="card-header">
                      {{ "lang_human_resources_id_details" | language }}
                    </div>
                    <div class="card-body">
                      <div class="mbrl15 martb0">
                        <div class="row">
                          <div class="col-md-9 col-sm-12 col-xs-12 pad0">
                            <div class="row">
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{
                                    "lang_human_resources_id_number" | language
                                  }}
                                </div>
                                <div class="mb-10">
                                  <b>{{ viewdata.employee_id_number }}</b>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_branch" | language }}
                                </div>
                                <div class="mb-10">
                                  <b>{{ viewdata.branch_name }}</b>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_human_resources_nameen" | language }}
                                </div>
                                <div class="mb-10">
                                  <b
                                    >{{ viewdata.settings_name_english }}
                                    {{ viewdata.firstname_english }}
                                    {{ viewdata.secondname_english }}
                                    {{ viewdata.thirdname_english }}
                                    {{ viewdata.lastname_english }}</b
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_human_resources_namear" | language }}
                                </div>
                                <div class="mb-10">
                                  <b
                                    >{{ viewdata.settings_name_arabic }}
                                    {{ viewdata.firstname_arabic }}
                                    {{ viewdata.secondname_arabic }}
                                    {{ viewdata.thirdname_arabic }}
                                    {{ viewdata.lastname_arabic }}</b
                                  >
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_expiry_date" | language }}
                                </div>
                                <div class="mb-10">
                                  <b
                                    >{{ viewdata.iqama_expiry_date_english }}
                                    -
                                    {{ viewdata.iqama_expiry_date_arabic }}</b
                                  >
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{
                                    "lang_human_resources_copy_number"
                                      | language
                                  }}
                                </div>
                                <div class="mb-10">
                                  <b>{{ viewdata.copy_number }}</b>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{
                                    "lang_human_resources_birth_date" | language
                                  }}
                                </div>
                                <div class="mb-10">
                                  <b
                                    >{{ viewdata.birth_date_english }} -
                                    {{ viewdata.birth_date_arabic }}</b
                                  >
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_human_resources_gender" | language }}
                                </div>
                                <div class="mb-10">
                                  <b *ngIf="viewdata.gender == 'M'">{{
                                    "lang_human_resources_male" | language
                                  }}</b>
                                </div>
                                <div class="mb-10">
                                  <b *ngIf="viewdata.gender == 'F'">{{
                                    "lang_human_resources_female" | language
                                  }}</b>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{
                                    "lang_human_resources_nationality"
                                      | language
                                  }}
                                </div>
                                <div class="mb-10">
                                  <b
                                    ><span
                                      ><img
                                        src="./assets/images/nations/{{
                                          viewdata.nationality.toLowerCase()
                                        }}.svg"
                                        class="img-responsive cntryimage"
                                      />
                                      {{ viewdata.countryname }}</span
                                    ></b
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_user_type" | language }}
                                </div>
                                <div class="mb-10">
                                  <b>{{ viewdata.typename }}</b>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{
                                    "lang_human_resources_job_title_iqama"
                                      | language
                                  }}
                                </div>
                                <div class="mb-10">
                                  <b>{{ viewdata.job_title_iqama }}</b>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_status" | language }}
                                </div>
                                <div class="mb-10">
                                  <b
                                    *ngIf="viewdata.employee_status == '0'"
                                    class="red"
                                    >{{ "lang_inactive" | language }}</b
                                  >
                                  <b
                                    *ngIf="viewdata.employee_status == '1'"
                                    class="green"
                                    >{{ "lang_active" | language }}</b
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_ondate" | language }}
                                </div>
                                <div class="mb-10">
                                  <b>{{ viewdata.created_datetime }}</b>
                                </div>
                              </div>
                              <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_onupdate" | language }}
                                </div>
                                <div class="mb-10">
                                  <b>{{ viewdata.updated_datetime }}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-md-3 col-sm-12 col-xs-12 pad0 text-center"
                          >
                            <p>
                              {{ "lang_myprofile" | language }} :
                              {{ profile_percentage }}%
                            </p>
                            <ngb-progressbar
                              class="mb-10"
                              [value]="profile_percentage"
                              [striped]="true"
                              [animated]="true"
                            >
                              <i>{{ profile_percentage }}%</i></ngb-progressbar
                            >
                            <b class="green">
                              {{
                                ds.calculateage(viewdata.birth_date_english) +
                                  " "
                              }}{{ "lang_age" | language }}</b
                            >
                            <div class="row">
                              <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                <div class="avatar-upload">
                                  <img
                                    *ngIf="
                                      (!viewdata.profile_image &&
                                        viewdata.gender == 'M' &&
                                        !view_profile_image) ||
                                      (viewdata.profile_image &&
                                        !view_profile_image &&
                                        viewdata.gender == 'M')
                                    "
                                    src="./assets/images/male.png"
                                    class="avatar-preview redborder"
                                    id="imagePreview"
                                  />
                                  <img
                                    *ngIf="
                                      (!viewdata.profile_image &&
                                        viewdata.gender == 'F' &&
                                        !view_profile_image) ||
                                      (viewdata.profile_image &&
                                        !view_profile_image &&
                                        viewdata.gender == 'F')
                                    "
                                    src="./assets/images/female.png"
                                    class="avatar-preview redborder"
                                    id="imagePreview"
                                  />
                                  <img
                                    *ngIf="
                                      viewdata.profile_image &&
                                      view_profile_image
                                    "
                                    src="{{ view_profile_image }}"
                                    class="avatar-preview"
                                    id="imagePreview"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                <div class="mb-10">
                                  {{ "lang_human_resources_number" | language }}
                                  : <b>{{ viewdata.employee_number }}</b>
                                </div>
                              </div>
                            </div>
                            <div
                              class="row"
                              *ngIf="ds.birthday(viewdata.birth_date_english)"
                            >
                              <div class="col-md-12 col-sm-12 col-xs-12 mb-10">
                                <b class="green">{{
                                  "lang_happy_birthday" | language
                                }}</b>
                                <div class="avatar-upload">
                                  <img
                                    src="./assets/images/birthday.png"
                                    class="avatar-preview"
                                    id="imagePreview"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>
                <mat-step>
                  <app-contracts
                    *ngIf="selectedName == 'human_resources_contract_details'"
                    [able2add]="able2add"
                    [id]="viewdata.employee_number"
                    [user_data]="viewdata"
                    [attachments]="attachments"
                    [branch_id]="viewdata.branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [able2delete]="able2delete"
                    [requestfor]="module"
                  ></app-contracts>
                </mat-step>
                <mat-step>
                  <app-positions
                    *ngIf="selectedName == 'human_resources_job_details'"
                    [able2add]="able2add"
                    [id]="viewdata.employee_number"
                    [user_data]="viewdata"
                    [attachments]="attachments"
                    [branch_id]="viewdata.branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [able2delete]="able2delete"
                    [requestfor]="module"
                  ></app-positions>
                </mat-step>
                <mat-step>
                  <ng-container
                    *ngIf="selectedName == 'human_resources_communication'"
                  >
                    <app-communication
                      [user_data]="viewdata"
                      [able2add]="add"
                    ></app-communication>
                  </ng-container>
                </mat-step>
                <mat-step>
                  <app-contacts
                    *ngIf="selectedName == 'human_resources_contact_details'"
                    [able2add]="able2add"
                    [id]="viewdata.employee_number"
                    [attachments]="attachments"
                    [user_data]="viewdata"
                    [able2delete]="able2delete"
                    [branch_id]="viewdata.branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [requestfor]="module"
                  ></app-contacts>
                </mat-step>
                <mat-step>
                  <div
                    class="card"
                    *ngIf="selectedName == 'human_resources_bank_details'"
                  >
                    <i
                      *ngIf="attachments.bk0001"
                      matTooltip="{{ 'lang_recent_attachment' | language }} : {{
                        attachments.bk0001_d
                      }}"
                      class="fa fa-paperclip linkicon mnabricon"
                      (click)="
                        ds.preview_pdf_file(attachments.bk0001, 'bk0001')
                      "
                    ></i>
                    <div class="card-header">
                      {{ "lang_human_resources_bank_details" | language }}
                    </div>
                    <div class="card-body">
                      <div class="martb0">
                        <div class="row">
                          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">
                              {{
                                "lang_human_resources_account_number" | language
                              }}
                            </div>
                            <div class="mb-10">
                              <b>{{ viewdata.account_number }}</b>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div class="mb-10">
                              {{ "lang_bank" | language }}
                            </div>
                            <div class="mb-10">
                              <b>{{ viewdata.bankname }}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>
                <mat-step>
                  <app-education
                    *ngIf="selectedName == 'human_resources_education_details'"
                    [able2add]="able2add"
                    [id]="viewdata.employee_number"
                    [attachments]="attachments"
                    [user_data]="viewdata"
                    [able2delete]="able2delete"
                    [branch_id]="viewdata.branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [ownedit]="educationedit"
                    [requestfor]="module"
                  >
                  </app-education>
                </mat-step>
                <mat-step>
                  <div
                    class="card"
                    *ngIf="selectedName == 'human_resources_passport_details'"
                  >
                    <i
                      *ngIf="attachments.ps0001"
                      matTooltip="{{ 'lang_recent_attachment' | language }} : {{
                        attachments.ps0001_d
                      }}"
                      class="fa fa-paperclip linkicon mnabricon"
                      (click)="
                        ds.preview_pdf_file(attachments.ps0001, 'ps0001')
                      "
                    ></i>
                    <div class="card-header">
                      {{ "lang_human_resources_passport_details" | language }}
                    </div>
                    <div class="card-body">
                      <div class="martb0">
                        <div class="row">
                          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div
                              class="mb-10"
                              [ngClass]="{ red: !viewdata.passport_number }"
                            >
                              {{
                                "lang_human_resources_passport_number"
                                  | language
                              }}
                            </div>
                            <div
                              class="mb-10"
                              [ngClass]="{ red: !viewdata.passport_number }"
                            >
                              <b *ngIf="viewdata.passport_number">{{
                                viewdata.passport_number
                              }}</b>
                              <b *ngIf="!viewdata.passport_number">-</b>
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div
                              class="mb-10"
                              [ngClass]="{
                                red: !viewdata.passport_issue_date_english
                              }"
                            >
                              {{ "lang_issue_date" | language }}
                            </div>
                            <div
                              class="mb-10"
                              [ngClass]="{
                                red: !viewdata.passport_issue_date_english
                              }"
                            >
                              <b
                                >{{ viewdata.passport_issue_date_english }} -
                                {{ viewdata.passport_issue_date_arabic }}</b
                              >
                            </div>
                          </div>
                          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div
                              class="mb-10"
                              [ngClass]="{
                                red: !viewdata.passport_expiry_date_english
                              }"
                            >
                              {{ "lang_expiry_date" | language }}
                            </div>
                            <div
                              class="mb-10"
                              [ngClass]="{
                                red: !viewdata.passport_expiry_date_english
                              }"
                            >
                              <b
                                >{{ viewdata.passport_expiry_date_english }} -
                                {{ viewdata.passport_expiry_date_arabic }}</b
                              >
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                            <div
                              class="mb-10"
                              [ngClass]="{
                                red: !viewdata.passport_issue_place
                              }"
                            >
                              {{
                                "lang_human_resources_passport_issue_place"
                                  | language
                              }}
                            </div>
                            <div
                              class="mb-10"
                              [ngClass]="{
                                red: !viewdata.passport_issue_place
                              }"
                            >
                              <b *ngIf="viewdata.passport_issue_place">{{
                                viewdata.passport_issue_place
                              }}</b>
                              <b *ngIf="!viewdata.passport_issue_place">-</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>
                <mat-step>
                  <app-insurance
                    *ngIf="selectedName == 'human_resources_insurance_details'"
                    [able2add]="able2add"
                    [id]="viewdata.employee_number"
                    [attachments]="attachments"
                    [user_data]="viewdata"
                    [able2delete]="able2delete"
                    [branch_id]="viewdata.branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [requestfor]="module"
                  ></app-insurance>
                </mat-step>
                <mat-step>
                  <app-vaction
                    *ngIf="selectedName == 'vacations'"
                    [openFromProfile]="true"
                    [id]="viewdata.employee_number"
                    [able2add]="add"
                    [able2delete]="able2delete"
                    [user_data]="viewdata"
                    [branch_id]="branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [requestfor]="module"
                  ></app-vaction>
                </mat-step>
                <mat-step>
                  <app-notes
                    *ngIf="selectedName == 'notes'"
                    [able2add]="able2add"
                    [id]="viewdata.employee_number"
                    [user_data]="viewdata"
                    [able2delete]="able2delete"
                    [branch_id]="viewdata.branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [requestfor]="module"
                  ></app-notes>
                </mat-step>
                <mat-step>
                  <app-attachments
                    *ngIf="selectedName == 'attachments'"
                    [able2add]="able2add"
                    [id]="viewdata.employee_number"
                    [user_data]="viewdata"
                    [able2delete]="able2delete"
                    [branch_id]="viewdata.branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [requestfor]="module"
                  ></app-attachments>
                </mat-step>
                <mat-step>
                  <app-modules
                    *ngIf="selectedName == 'modules'"
                    [able2add]="able2add"
                    [id]="viewdata.employee_number"
                    [user_data]="viewdata"
                    [able2delete]="able2delete"
                    [branch_id]="viewdata.branch_id"
                    [key]="key"
                    [able2edit]="able2edit"
                    [requestfor]="module"
                  ></app-modules>
                </mat-step>
                <mat-step>
                  <div class="card" *ngIf="selectedName == 'signature'">
                    <mat-icon
                      class="mnabricon s2icon linkicon"
                      data-toggle="modal"
                      data-target="#makeSignature"
                      data-backdrop="static"
                      data-keyboard="false"
                      matTooltip="{{ 'lang_signature' | language }}"
                      (click)="showsignatureform()"
                      >gamepad</mat-icon
                    >
                    <div class="card-header">
                      {{ "lang_signature" | language }}
                    </div>
                    <div class="card-body">
                      <div class="martb0">
                        <div class="row">
                          <div class="col-md-3 col-xs-6">
                            <div>{{ "lang_signature" | language }}</div>
                            <div class="mb-10">
                              <a>
                                <img
                                  (click)="
                                    showsignature(
                                      viewdata.employee_id_number,
                                      viewdata.user_id,
                                      'S'
                                    )
                                  "
                                  class="img-responsive signview"
                                  src="./assets/images/sign.png"
                                />
                              </a>
                            </div>
                          </div>
                          <div class="col-md-3 col-xs-6">
                            <div>{{ "lang_mark" | language }}</div>
                            <div class="mb-10">
                              <a>
                                <img
                                  (click)="
                                    showsignature(
                                      viewdata.employee_id_number,
                                      viewdata.user_id,
                                      'M'
                                    )
                                  "
                                  class="img-responsive signview"
                                  src="./assets/images/sign.png"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>
                <mat-step>
                  <ng-container *ngIf="selectedName == 'calender_view'">
                    <app-calender
                      [singleUser]="singleUser"
                      [userInfo]="viewdata"
                      [isHRView]="false"
                      [isShowTotalHours]="true"
                      [fromProfile]="true"
                    ></app-calender>
                  </ng-container>
                </mat-step>
                <mat-step>
                  <ng-container *ngIf="selectedName == 'finanial_details'">
                    <app-finanial-details
                      [filepermissionlevels]="filepermissionlevels"
                      (childToParentAttach)="get_view_details(true)"
                      (showForm)="create_loan_form($event)"
                      [id]="id"
                      [able2add]="add"
                      [able2delete]="delete || is_admin"
                      [user_data]="viewdata"
                      [branch_id]="branch_id"
                      [attachments]="attachments"
                      [key]="key"
                      [able2edit]="edit || is_admin"
                      [requestfor]="module"
                    ></app-finanial-details>
                  </ng-container>
                </mat-step>
              </mat-horizontal-stepper>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</section>

<div class="modal" id="makeSignature">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ "lang_signature" | language }}</h4>
        <button
          type="button"
          class="close"
          id="modelclose"
          data-dismiss="modal"
        >
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="signatureform"
          (ngSubmit)="submitSignature(viewdata.employee_id_number)"
        >
          <div class="form-group">
            <label class="mb-10"
              >{{ "lang_how_to_send_verification" | language }} :
              <span class="red">*</span></label
            >
            <div class="custom-control custom-radio mb-10">
              <input
                type="radio"
                class="custom-control-input zindex9999"
                value="email"
                id="vm1"
                formControlName="verification_method"
              />
              <label class="custom-control-label"
                >{{ "lang_email" | language }}
                <b>({{ ds.secureemail(viewdata.user_email) }}) </b>
              </label>
            </div>
            <div class="custom-control custom-radio mb-10">
              <input
                type="radio"
                class="custom-control-input zindex9999"
                value="mobile"
                id="vm2"
                formControlName="verification_method"
              />
              <label class="custom-control-label"
                >{{ "lang_mobile" | language }}
                <b
                  ><bdi>({{ ds.securemobile(viewdata.user_phone) }})</bdi></b
                >
              </label>
            </div>
            <div class="custom-control custom-radio mb-10">
              <input
                type="radio"
                class="custom-control-input zindex9999"
                value="whatsapp"
                id="vm3"
                formControlName="verification_method"
              />
              <label class="custom-control-label"
                >{{ "lang_whatsapp" | language }}
                <b
                  ><bdi>({{ ds.securemobile(viewdata.user_phone) }})</bdi></b
                >
              </label>
            </div>
            <mat-error
              *ngIf="
                submitted &&
                signatureform.controls['verification_method'].errors?.required
              "
              >{{ "lang_field_required" | language }}</mat-error
            >
          </div>
          <hr />
          <div class="form-group">
            <label for="signature"
              >{{ "lang_signature" | language }} :
              <span class="red">*</span></label
            >
            <div class="sig-container" #padElement>
              <canvas #sPad width="{{ width }}" height="{{ height }}"></canvas>
            </div>
            <mat-error *ngIf="submitted">{{
              "lang_field_required" | language
            }}</mat-error>
            <br />
            <div fxLayout="row">
              <div class="col-md-3 col-xs-12 mb-10 pad0">
                <button
                  type="button"
                  class="albutton"
                  (click)="signaturePad.clear()"
                >
                  {{ "lang_clear" | language }}
                </button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="mark"
              >{{ "lang_mark" | language }} : <span class="red">*</span></label
            >
            <div class="sig-container" #padElement>
              <canvas #mPad width="{{ width }}" height="{{ height }}"></canvas>
            </div>
            <mat-error *ngIf="submitted">{{
              "lang_field_required" | language
            }}</mat-error>
            <br />
            <div fxLayout="row">
              <div class="col-md-3 col-xs-12 mb-10 pad0">
                <button
                  type="button"
                  class="albutton"
                  (click)="markPad.clear()"
                >
                  {{ "lang_clear" | language }}
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="sendstatus" id="verification_send">
            <div class="form-group">
              <label for="verification_code"
                >{{ "lang_enter_verification_code" | language }}:
                <span class="red">*</span></label
              >
              <input
                type="text"
                required
                minlength="4"
                maxlength="4"
                class="form-control almnabrformcontrol"
                (keypress)="ds.numberOnly($event)"
                id="verification_code"
                formControlName="verification_code"
              />
              <mat-error
                *ngIf="
                  submitted &&
                  signatureform.controls['verification_code'].errors?.required
                "
                >{{ "lang_field_required" | language }}</mat-error
              >
              <mat-error
                *ngIf="
                  signatureform.controls['verification_code'].errors?.minlength
                "
              >
                {{ "lang_minimum" | language }} 4</mat-error
              >
              <mat-error
                *ngIf="
                  signatureform.controls['verification_code'].errors?.maxlength
                "
              >
                {{ "lang_maximum" | language }} 4
              </mat-error>
            </div>
          </div>
          <app-alert id="default-alert"></app-alert>
          <div class="form-group">
            <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                <button
                  type="button"
                  [disabled]="sendcodestatus"
                  (click)="sendVerificationCode(viewdata.employee_id_number)"
                  class="albutton"
                >
                  {{ codemsg }}
                </button>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 mb-10">
                <button
                  type="submit"
                  [disabled]="!signatureform.valid"
                  class="albutton"
                >
                  {{ "lang_submit" | language }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          #myModalClose
          id="modelclose"
          data-dismiss="modal"
        >
          {{ "lang_close" | language }}
        </button>
      </div>
    </div>
  </div>
</div>
