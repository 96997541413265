<form [formGroup]="filter_form" class="row">
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="reason-date w-100">
      <mat-label>{{'lang_start_date_time'}}</mat-label>
      <input matInput formControlName="startdate" class="w-100"  readonly (click)="shift_start_time.open()"/>
      <input
        matInput
        formControlName="startdatetime"
        [matDatepicker]="picker2"
        style="display: none"
        (dateChange)="setdate('start')"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="shift_start_time"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
      <input
          style="display:none"
        matInput
        readonly
        required
        formControlName="startdatedate"
        [ngxTimepicker]="shift_start_time"
        [format]="24"
      />
      <ngx-material-timepicker
        #shift_start_time
        (timeSet)="picker2.open()"
      ></ngx-material-timepicker>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="reason-date w-100">
      <mat-label>{{'lang_end_date_time'}}</mat-label>
      <input matInput formControlName="enddate" readonly (click)="shift_start_time2.open()"/>
      <input
        matInput
        formControlName="enddatetime"
        [matDatepicker]="picker3"
        style="display: none"
        (dateChange)="setdate('end')"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="shift_start_time2"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
      <input
          style="display:none"
        matInput
        readonly
        required
        formControlName="enddatedate"
        [ngxTimepicker]="shift_start_time2"
        [format]="24"
      />
      <ngx-material-timepicker
        #shift_start_time2
        (timeSet)="picker3.open()"
      ></ngx-material-timepicker>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'lang_device'|language}}</mat-label>
      <mat-select formControlName="device_id">
          <mat-option *ngFor="let device_id of devices_ids" [value]="device_id.device_id">{{device_id.device_name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-6">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{'lang_person_id'|language}}</mat-label>
      <input matInput formControlName="person_id">
    </mat-form-field>
  </div>

  <div class="col-12 d-flex align-items-center mt-2">
    <button
      class="reset-reason-filters albutton mnabrbutton bold"
      mat-button
      (click)="get_records()"
    >
      {{ "lang_filter" | language }}
    </button>
  </div>
</form>
<mat-tab-group class="device-mat-tab mt-4 p-2">
	<mat-tab>
		<ng-template mat-tab-label>{{
      "lang_records" | language
    }}</ng-template>
		<div class="p-2 my-2" style="background-color: #fbfbfb;
    border-radius: 5px;">
			<div class="row">
				<div class="col-12">
					<div class="material-panel-body tlist">
            <div class="mbrl15 fromtransactions">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>{{ "lang_person_id" | language }}</td>
                          <td>{{ "lang_temperature" | language }}</td>
                          <td>{{ "lang_time" | language }}</td>
                          <td>{{ "lang_type" | language }}</td>
                          <td>{{ "lang_mask" | language }}</td>
                          <td>{{ "lang_temperatureState" | language }}</td>
                        </tr>
                      </thead>
                      <tbody
                        *ngIf="attendance_records_with_pagination?.length != '0'"
                        class="AppendList"
                      >
                        <tr
                          *ngFor="
                            let record of attendance_records_with_pagination;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                          [ngClass]="{ odd: odd, even: even }"
                        >
                          <td>{{ record.id }}</td>
                          <td>{{ record.personId }}</td>
                          <td>{{ record.temperature }}</td>
                          <td>{{ record.time }}</td>
                          <td>{{ record.type }}</td>
                          <td>{{ record.mask }}</td>
                          <td>{{ record.temperatureState }}</td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="loading">
                        <tr>
                          <td colspan="8" class="nodata" align="center">
                            <ngx-skeleton-loader
                              count="5"
                              [theme]="{ 'border-radius': '3px', height: '50px' }"
                            ></ngx-skeleton-loader>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        *ngIf="attendance_records_with_pagination?.length == '0' && !loading"
                        class="AppendList"
                      >
                        <tr class="odd">
                          <td colspan="8" class="nodata" align="center">
                            {{ nodata }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="attendance_records?.length != '0'" class="card-footer padb0">
              <div class="row responsive_pagination">
                <div
                  class="col-lg-3 col-sm-3 mb-10"
                  style="height: fit-content; margin: auto 0"
                >
                  {{ "lang_total" | language }} :
                  {{ attendance_records?.length }}
                </div>
                <div
                  class="col-lg-5 col-sm-5 mb-10"
                  style="display: flex; justify-content: center"
                >
                  <ngb-pagination
                    class="m-auto"
                    [collectionSize]="attendance_records?.length"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="page_no"
                    [pageSize]="pagesize"
                    (pageChange)="loadPageFrm($event)"
                  ></ngb-pagination>
                </div>
                <div
                  class="col-lg-3 col-sm-3"
                  style="display: flex; justify-content: end; margin: auto"
                >
                  <mat-select
                    style="max-width: 150px"
                    [(ngModel)]="pagesize"
                    (selectionChange)="loadFrmPagesize($event.value)"
                  >
                    <mat-option class="text-center" [value]="10"
                      >10 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="20"
                      >20 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="50"
                      >50 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="100"
                      >100 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" [value]="500"
                      >500 {{ "lang_per_page" | language }}</mat-option
                    >
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
	</mat-tab>
  <mat-tab>
		<ng-template mat-tab-label>{{
      "lang_inserted_records" | language
    }}</ng-template>
		<div class="p-2 my-2" style="background-color: #fbfbfb;
    border-radius: 5px;">
			<div class="row">
				<div class="col-12">
					<div class="material-panel-body tlist">
            <div class="mbrl15 fromtransactions">
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive customResponsive">
                    <table border="1" class="table CustomTable">
                      <thead>
                        <tr>
                          <td>{{ "lang_employee_id" | language }}</td>
                          <td>{{ "lang_employee_number" | language }}</td>
                          <td>{{ "lang_device_serial_number" | language }}</td>
                          <td>{{ "lang_employee_group" | language }}</td>
                          <td>{{ "lang_employee_identity" | language }}</td>
                          <td>{{ "lang_access_type" | language }}</td>
                          <td>{{ "lang_mask_status" | language }}</td>
                          <td>{{ "lang_existing_record_id" | language }}</td>
                          <td>{{ "lang_action_datatime" | language }}</td>
                          <td>{{ "lang_created_datetime" | language }}</td>
                        </tr>
                      </thead>
                      <tbody
                        *ngIf="inserted_with_pagination?.length != '0'"
                        class="AppendList"
                      >
                        <tr
                          *ngFor="
                            let item of inserted_with_pagination;
                            let i = index;
                            let odd = odd;
                            let even = even
                          "
                          [ngClass]="{ odd: odd, even: even }"
                        >
                          <td>{{ item?.employee_id_number }}</td>
                          <td>{{ item?.employee_number }}</td>
                          <td>{{ item?.device_serial_number }}</td>
                          <td>{{ item?.employee_group }}</td>
                          <td>{{ item?.employee_identity }}</td>
                          <td>{{ item?.access_type }}</td>
                          <td>{{ item?.mask_status }}</td>
                          <td>{{ item?.existing_record_id }}</td>
                          <td>{{ item?.action_datatime }}</td>
                          <td>{{ item?.created_datetime }}</td>
                        </tr>
                      </tbody>
                      <tbody *ngIf="inserted_loading">
                        <tr>
                          <td colspan="14" class="nodata" align="center">
                            <ngx-skeleton-loader
                              count="5"
                              [theme]="{ 'border-radius': '3px', height: '50px' }"
                            ></ngx-skeleton-loader>
                          </td>
                        </tr>
                      </tbody>
                      <tbody
                        *ngIf="inserted_with_pagination?.length == '0' && !loading"
                        class="AppendList"
                      >
                        <tr class="odd">
                          <td colspan="14" class="nodata" align="center">
                            {{ nodata }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="inserted_with_pagination?.length != '0'" class="card-footer padb0">
              <div class="row responsive_pagination">
                <div
                  class="col-lg-3 col-sm-3 mb-10"
                  style="height: fit-content; margin: auto 0"
                >
                  {{ "lang_total" | language }} :
                  {{ inserted.length }}
                </div>
                <div
                  class="col-lg-5 col-sm-5 mb-10"
                  style="display: flex; justify-content: center"
                >
                  <ngb-pagination
                    class="m-auto"
                    [collectionSize]="inserted.length"
                    [rotate]="true"
                    [ellipses]="false"
                    [maxSize]="3"
                    [boundaryLinks]="true"
                    [(page)]="inserted_page_no"
                    [pageSize]="inserted_pagesize"
                    (pageChange)="insertedloadPageFrm($event)"
                  ></ngb-pagination>
                </div>
                <div
                  class="col-lg-3 col-sm-3"
                  style="display: flex; justify-content: end; margin: auto"
                >
                  <mat-select
                    style="max-width: 150px"
                    [(ngModel)]="inserted_pagesize"
                    (selectionChange)="insertedloadFrmPagesize($event.value)"
                  >
                    <mat-option class="text-center" [value]="10"
                      >10 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="20"
                      >20 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="50"
                      >50 {{ "lang_per_page" | language }}
                    </mat-option>
                    <mat-option class="text-center" [value]="100"
                      >100 {{ "lang_per_page" | language }}</mat-option
                    >
                    <mat-option class="text-center" [value]="500"
                      >500 {{ "lang_per_page" | language }}</mat-option
                    >
                  </mat-select>
                </div>
              </div>
            </div>
          </div>
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
