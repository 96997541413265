<breadcrumb></breadcrumb>
<div class="themesection">
  <section class="panel-expansion matborderno">
    <mat-accordion class="create_selling_invoice">
      <mat-expansion-panel class="mpanel" [expanded]="true">
        <mat-expansion-panel-header class="mpanel-header">{{
          "lang_create_selling_invoices" | language
        }}</mat-expansion-panel-header>
        <div class="material-panel-body">
          <form [formGroup]="form" (ngSubmit)="create_invoices()" class="form">
            <div class="mbrl15">
              <div class="row">
                <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                  <div class="form-group">
                    <label
                      >{{ "lang_branch" | language }} :<span class="red"
                        >*</span
                      ></label
                    >
                    <select
                      class="form-control almnabrformcontrol"
                      formControlName="branch_id"
                      #branch_id
                      (change)="
                        load_payment_modes(branch_id.value);
                        load_taxes();
                        load_tax_settings()
                      "
                    >
                      <option value="">
                        {{ "lang_choose_options" | language }}
                      </option>
                      <option
                        *ngFor="let branch of branches"
                        value="{{ branch.id }}"
                      >
                        {{ branch.title }}
                      </option>
                    </select>
                    <mat-error
                      *ngIf="
                        form.controls['branch_id'].touched &&
                        form.controls['branch_id'].errors?.required
                      "
                      >{{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0'
                  "
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_finance" | language }} :<span class="red"
                        >*</span
                      >
                    </label>
                    <ng-select
                      [items]="financeList"
                      [multiple]="false"
                      bindLabel="label"
                      bindValue="value"
                      clearAllText="Clear"
                      [searchFn]="ds.customSearchFn"
                      appendTo="body"
                      (change)="selectFinance()"
                      formControlName="finance_id"
                      placeholder="{{ 'lang_finance' | language }}"
                    >
                    </ng-select>
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0'
                  "
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_invoice_date" | language }} :<span class="red"
                        >*</span
                      ></label
                    >
                    <div class="input-group input-group-sm">
                      <input
                        [matDatepicker]="invoice_date"
                        formControlName="invoice_date"
                        class="form-control almnabrformcontrol"
                        placeholder="YYYY/MM/DD"
                        readonly
                      />
                      <span class="input-group-btn">
                        <mat-datepicker-toggle
                          class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                          matSuffix
                          [for]="invoice_date"
                        ></mat-datepicker-toggle>
                        <mat-datepicker
                          #invoice_date
                          (closed)="getsinvoice_calculations()"
                        ></mat-datepicker>
                      </span>
                      <span class="input-group-btn">
                        <button
                          (click)="form.get('invoice_date').setValue('')"
                          matTooltip="{{ 'lang_empty' | language }}"
                          type="button"
                          class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
                        ></button>
                      </span>
                    </div>
                    <mat-error
                      *ngIf="
                        form.controls['invoice_date'].touched &&
                        form.controls['invoice_date'].errors?.required
                      "
                      >{{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_payment_mode" | language }} :<span class="red"
                        >*</span
                      ></label
                    >
                    <div class="row">
                      <div class="col-6">
                        <ng-select
                          [items]="modes"
                          [multiple]="false"
                          clearAllText="Clear"
                          appendTo="body"
                          formControlName="invoice_payment_mode"
                          placeholder="{{ 'lang_payment_mode' | language }}"
                          (change)="
                            payment_mode_options($event);
                            calculate_total_calculation();
                            getsinvoice_calculations()
                          "
                        >
                        </ng-select>
                        <mat-error
                          *ngIf="
                            form.controls['invoice_payment_mode'].touched &&
                            form.controls['invoice_payment_mode'].errors
                              ?.required
                          "
                          >{{ "lang_field_required" | language }}</mat-error
                        >
                      </div>
                      <div class="col-6">
                        <input
                          type="text"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_credit_days' | language }}"
                          formControlName="invoice_credit_days"
                          autocomplete="off"
                          (keyup)="add_days($event.target.value)"
                        />
                      </div>
                    </div>
                    <mat-error
                      *ngIf="
                        form.controls['invoice_credit_days'].touched &&
                        form.controls['invoice_credit_days'].errors?.required
                      "
                    >
                      {{ "lang_field_required" | language }}
                    </mat-error>
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label>{{ "lang_credit_date" | language }} :</label>
                    <input
                      formControlName="invoice_credit_date"
                      value="{{ date | date: 'yyyy/MM/dd' }}"
                      class="form-control almnabrformcontrol"
                      [readonly]="true"
                      placeholder="YYYY/MM/DD"
                    />
                    <mat-error
                      *ngIf="
                        form.controls['invoice_credit_date'].touched &&
                        form.controls['invoice_credit_date'].errors?.required
                      "
                      >{{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_writer" | language }} :
                      <span class="red">*</span></label
                    >
                    <input
                      type="text"
                      value="{{ fullname }}"
                      readonly
                      disabled
                      class="form-control almnabrformcontrol"
                    />
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label>{{ "lang_reference_no" | language }} :</label>
                    <input
                      type="text"
                      formControlName="invoice_ref_number"
                      class="form-control almnabrformcontrol"
                      placeholder="{{ 'lang_reference_no' | language }}"
                    />
                    <mat-error
                      *ngIf="
                        form.controls['invoice_ref_number'].touched &&
                        form.controls['invoice_ref_number'].errors?.required
                      "
                    >
                      {{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_invoice_instruction_remarks" | language }} :
                      <span class="red">*</span></label
                    >
                    <input
                      type="text"
                      formControlName="invoice_instruction_remarks"
                      class="form-control almnabrformcontrol"
                      placeholder="{{
                        'lang_invoice_instruction_remarks' | language
                      }}"
                    />
                    <mat-error
                      *ngIf="
                        form.controls['invoice_instruction_remarks'].touched &&
                        form.controls['invoice_instruction_remarks'].errors
                          ?.required
                      "
                      >{{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div
                  class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label>
                      {{ "lang_select_customer" | language }} :<span class="red"
                        >*</span
                      >
                    </label>
                    <ng-select
                      #select
                      [items]="customers"
                      [multiple]="false"
                      bindLabel="label"
                      bindValue="value"
                      clearAllText="Clear"
                      [searchFn]="ds.customSearchFn"
                      appendTo="body"
                      formControlName="customers"
                      placeholder="{{ 'lang_select_customer' | language }}"
                      (search)="searchcustomer($event)"
                      (change)="Addinfo()"
                      (focus)="customers = []"
                    >
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                      >
                        <div width="100%">
                          <b>{{ item.label }}</b>
                        </div>
                      </ng-template>
                      <ng-template ng-footer-tmp class="sinvioce">
                        <div
                          width="100%"
                          class="addAccount"
                          (click)="openModal(addAccount); closeSelect(select)"
                        >
                          <i class="fa fa-plus-square-o fa-icon ml-1 mr-1"></i>
                          <b>{{ "lang_customers_create" | language }}</b>
                        </div>
                      </ng-template>
                    </ng-select>
                    <mat-error
                      *ngIf="
                        form.controls['invoice_to_account_id'].touched &&
                        form.controls['invoice_to_account_id'].errors?.required
                      "
                      >{{ "lang_field_required" | language }}</mat-error
                    >
                  </div>
                </div>
                <div
                  class="col-sm-4 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label>{{ "lang_document_type" | language }} :</label>
                    <ng-select
                      [items]="documentTypes"
                      bindLabel="label"
                      bindValue="value"
                      [multiple]="false"
                      clearAllText="Clear"
                      appendTo="body"
                      type="text"
                      placeholder="{{ 'lang_document_type' | language }}"
                      formControlName="document_type"
                    >
                    </ng-select>
                  </div>
                </div>
                <div
                  class="col-sm-4 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label>{{ "lang_return_parent_id" | language }} :</label>
                    <input
                      type="text"
                      class="form-control almnabrformcontrol"
                      placeholder="{{ 'lang_return_parent_id' | language }}"
                      formControlName="return_parent_id"
                      autocomplete="off"
                      class="form-control almnabrformcontrol"
                    />
                  </div>
                </div>
                <div
                  class="col-sm-4 mb-10"
                  *ngIf="form.get('document_type')?.value == 'standard'"
                >
                  <div class="form-group">
                    <label
                      >{{ "lang_actual_delivery_date" | language }} :</label
                    >
                    <div class="input-group input-group-sm">
                      <input
                        [matDatepicker]="actual_delivery_date"
                        formControlName="actual_delivery_date"
                        class="form-control almnabrformcontrol"
                        placeholder="YYYY/MM/DD"
                        readonly
                      />
                      <span class="input-group-btn">
                        <mat-datepicker-toggle
                          class="mat-datepicker-toggle mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray mnabricon pad0"
                          matSuffix
                          [for]="actual_delivery_date"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #actual_delivery_date></mat-datepicker>
                      </span>
                      <span class="input-group-btn">
                        <button
                          (click)="
                            form.get('actual_delivery_date').setValue('')
                          "
                          matTooltip="{{ 'lang_empty' | language }}"
                          type="button"
                          class="mat-tooltip-trigger btnSearch h34 btn btn-md btn-gray fa fa-times red mnabricon"
                        ></button>
                      </span>
                    </div>
                  </div>
                </div>
                <!-- <div
                  class="col-sm-4 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label>{{ "lang_invoice_type_zatca" | language }} :</label>
                    <input
                      type="text"
                      class="form-control almnabrformcontrol"
                      placeholder="{{ 'lang_invoice_type_zatca' | language }}"
                      formControlName="invoice_type_zatca"
                      autocomplete="off"
                      class="form-control almnabrformcontrol"
                    />
                  </div>
                </div> -->
                <div
                  class="col-sm-4 mb-10"
                  *ngIf="
                    form.get('branch_id').value &&
                    form.get('branch_id').value != '0' &&
                    form.get('finance_id').value &&
                    form.get('finance_id').value != '0' &&
                    form.get('invoice_date').value &&
                    form.get('invoice_date').value != '0'
                  "
                >
                  <div class="form-group">
                    <label>{{ "lang_transaction_tages" | language }} :</label>
                    <input
                      type="text"
                      class="form-control almnabrformcontrol"
                      placeholder="{{ 'lang_transaction_tages' | language }}"
                      formControlName="transaction_tages"
                      autocomplete="off"
                      class="form-control almnabrformcontrol"
                    />
                  </div>
                </div>
                <div class="col-sm-4 mb-10"></div>
                <div class="col-sm-4 mb-10">
                  <fieldset
                    class="fieldset m-3"
                    *ngIf="
                      form.get('branch_id').value &&
                      form.get('branch_id').value != '0' &&
                      form.get('finance_id').value &&
                      form.get('finance_id').value != '0' &&
                      form.get('invoice_date').value &&
                      form.get('invoice_date').value != '0'
                    "
                  >
                    <legend>{{ "lang_customer_details" | language }} :</legend>
                    <div class="row" style="width: 100%">
                      <div class="col-12 customer_details p-1 m-2">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td scope="row" class="count_label">
                                {{ "lang_vat_tax" | language }}
                              </td>
                              <td>
                                {{ this.data?.customer_tax_vat || "---" }}
                              </td>
                            </tr>
                            <tr>
                              <td scope="row" class="count_label">
                                {{ "lang_ccr_no" | language }}
                              </td>
                              <td>{{ this.data?.customer_ccr_no || "---" }}</td>
                            </tr>
                            <tr>
                              <td scope="row" class="count_label">
                                {{ "lang_invoice_address" | language }}
                              </td>
                              <td>
                                {{ this.data?.customer_address || "---" }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- <div
                      class="col-md-4 col-sm-4 col-xs-12 mb-10"
                      *ngIf="
                        form.get('branch_id').value &&
                        form.get('branch_id').value != '0' &&
                        form.get('finance_id').value &&
                        form.get('finance_id').value != '0'
                      "
                    >
                      <div class="form-group">
                        <label>{{ "lang_vat_tax" | language }} :</label>
                        <input
                          type="text"
                          formControlName="invoice_vat_tax_id"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_vat_tax' | language }}"
                          readonly
                        />
                        <mat-error
                          *ngIf="
                            form.controls['invoice_vat_tax_id'].touched &&
                            form.controls['invoice_vat_tax_id'].errors?.required
                          "
                          >{{ "lang_field_required" | language }}</mat-error
                        >
                      </div>
                    </div>
                    <div
                      class="col-md-4 col-sm-4 col-xs-12 mb-10"
                      *ngIf="
                        form.get('branch_id').value &&
                        form.get('branch_id').value != '0' &&
                        form.get('finance_id').value &&
                        form.get('finance_id').value != '0'
                      "
                    >
                      <div class="form-group">
                        <label>{{ "lang_ccr_no" | language }} :</label>
                        <input
                          type="text"
                          formControlName="ccr_number"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_ccr_no' | language }}"
                          readonly
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-4 col-sm-4 col-xs-12 mb-10"
                      *ngIf="
                        form.get('branch_id').value &&
                        form.get('branch_id').value != '0' &&
                        form.get('finance_id').value &&
                        form.get('finance_id').value != '0'
                      "
                    >
                      <div class="form-group">
                        <label>{{ "lang_invoice_address" | language }} :</label>
                        <input
                          type="text"
                          formControlName="invoice_address_to"
                          class="form-control almnabrformcontrol"
                          placeholder="{{ 'lang_invoice_address' | language }}"
                          readonly
                        />
                      </div>
                    </div> -->
                    </div>
                  </fieldset>
                </div>
              </div>
              <div
                class="showform"
                *ngIf="
                  form.get('branch_id').value &&
                  form.get('branch_id').value != '0' &&
                  form.get('finance_id').value &&
                  form.get('finance_id').value != '0' &&
                  form.get('invoice_date').value &&
                  form.get('invoice_date').value != '0'
                "
              >
                <div [ngClass]="{ TableParts: item_tax || global_tax }">
                  <div
                    class="table-responsive customResponsive mb-10 tablefields"
                  >
                    <table border="1" class="table CustomTable text-center">
                      <thead>
                        <tr>
                          <td>#</td>
                          <td>
                            <i
                              class="fa fa-plus-square fa-icon"
                              matTooltip="{{ 'lang_add' | language }}"
                              (click)="add_items()"
                            ></i>
                          </td>
                          <td>
                            {{ "lang_invoice_item_name" | language }}
                            <i class="red">*</i>
                          </td>
                          <td>{{ "lang_invoice_item_unit" | language }}</td>
                          <td>
                            {{ "lang_invoice_item_quantity" | language }}
                            <i class="red">*</i>
                          </td>
                          <td>
                            {{ "lang_invoice_item_price" | language }}
                            <i class="red">*</i>
                          </td>
                          <td
                            *ngIf="item_discount && !form.get('isGlobal').value"
                          >
                            {{ "lang_invoice_discount_percent" | language }} %
                          </td>
                          <td
                            width="10%"
                            *ngIf="item_discount && !form.get('isGlobal').value"
                          >
                            {{ "lang_invoice_discount_amount" | language }}
                          </td>
                          <td
                            width="10%"
                            *ngIf="item_discount && !form.get('isGlobal').value"
                          >
                            {{ "lang_amout_after_discount" | language }}
                          </td>
                          <td
                            width="10%"
                            *ngIf="item_tax && !form.get('isGlobal').value"
                          >
                            {{ "lang_tax" | language }}
                          </td>
                          <td *ngIf="item_tax && !form.get('isGlobal').value">
                            {{ "lang_tax_amount" | language }}
                          </td>
                          <td>
                            {{ "lang_invoice_item_amount" | language
                            }}<i class="red">*</i>
                          </td>
                          <td>
                            {{ "lang_invoice_total_amount" | language
                            }}<i class="red">*</i>
                          </td>
                          <td>
                            {{ "lang_description" | language
                            }}<i class="red">*</i>
                          </td>
                        </tr>
                      </thead>
                      <tbody formArrayName="items" class="AppendList">
                        <tr
                          *ngFor="
                            let item of items_group().controls;
                            let itemIndex = index;
                            let odd = odd;
                            let even = even
                          "
                          [formGroupName]="itemIndex"
                        >
                          <td class="verticalalignmid pad0">
                            {{ itemIndex + 1 }}
                          </td>
                          <td class="verticalalignmid pad0">
                            <i
                              (click)="
                                removeItems(itemIndex);
                                calculate_total_calculation();
                                getsinvoice_calculations()
                              "
                              class="fa fa-minus-square fa-icon red mnabricon"
                              matTooltip="{{ 'lang_delete' | language }}"
                            ></i>
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_name"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_name' | language
                              }}"
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_unit"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_unit' | language
                              }}"
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_quantity"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_quantity' | language
                              }}"
                              (change)="
                                ds.CheckDecimal(
                                  $event.target.value,
                                  itemvalid.controls[itemIndex].get(
                                    'invoice_item_quantity'
                                  )
                                )
                              "
                              (keyup)="
                                calculate_invoice_tax(
                                  itemvalid.controls[itemIndex],
                                  itemIndex
                                );
                                getsinvoice_calculations()
                              "
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_price"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_price' | language
                              }}"
                              (keyup)="
                                calculate_invoice_tax(
                                  itemvalid.controls[itemIndex],
                                  itemIndex
                                );
                                getsinvoice_calculations()
                              "
                            />
                          </td>
                          <td
                            class="pad0 input-width"
                            *ngIf="item_discount && !form.get('isGlobal').value"
                          >
                            <input
                              type="text"
                              formControlName="invoice_discount_percent"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_discount_percent' | language
                              }}"
                              (change)="
                                ds.CheckDecimalNofixed(
                                  $event.target.value,
                                  itemvalid.controls[itemIndex].get(
                                    'invoice_discount_percent'
                                  )
                                )
                              "
                              (keyup)="
                                getsinvoice_calculations();
                                calculate_invoice_tax(
                                  itemvalid.controls[itemIndex],
                                  itemIndex,
                                  'invoice_discount_percent'
                                )
                              "
                            />
                          </td>
                          <td
                            class="pad0"
                            *ngIf="item_discount && !form.get('isGlobal').value"
                          >
                            <input
                              type="text"
                              formControlName="invoice_discount_percent_amount"
                              class="form-control almnabrformcontrol"
                              placeholder="0.00"
                              (change)="
                                ds.CheckDecimal(
                                  $event.target.value,
                                  itemvalid.controls[itemIndex].get(
                                    'invoice_discount_percent_amount'
                                  )
                                );
                                calculate_invoice_tax(
                                  itemvalid.controls[itemIndex],
                                  itemIndex,
                                  'invoice_discount_percent_amount'
                                );
                                getsinvoice_calculations()
                              "
                            />
                          </td>
                          <td
                            class="pad0"
                            *ngIf="item_discount && !form.get('isGlobal').value"
                          >
                            <input
                              type="text"
                              formControlName="total_amount_discount"
                              class="form-control almnabrformcontrol"
                              placeholder="0.00"
                            />
                          </td>
                          <td
                            class="pad0"
                            *ngIf="item_tax && !form.get('isGlobal').value"
                          >
                            <ng-select
                              [items]="taxs"
                              [multiple]="false"
                              clearAllText="Clear"
                              appendTo="body"
                              formControlName="invoice_item_tax"
                              placeholder="{{ 'lang_invoice_to' | language }}"
                              (change)="
                                calculate_invoice_tax(
                                  itemvalid.controls[itemIndex],
                                  itemIndex
                                );
                                getsinvoice_calculations()
                              "
                            >
                            </ng-select>
                          </td>
                          <td
                            class="pad0"
                            *ngIf="item_tax && !form.get('isGlobal').value"
                          >
                            <input
                              type="text"
                              formControlName="invoice_item_tax_amount"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_item_amount' | language
                              }}"
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_total_amount"
                              class="form-control almnabrformcontrol"
                              placeholder="{{
                                'lang_invoice_total_amount' | language
                              }}"
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_amount"
                              class="form-control almnabrformcontrol"
                              placeholder="0.00"
                            />
                          </td>
                          <td class="pad0">
                            <input
                              type="text"
                              formControlName="invoice_item_description"
                              class="form-control almnabrformcontrol"
                              placeholder="{{ 'lang_description' | language }}"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <br />
                <hr
                  style="width: 50%; margin-left: auto; margin-right: auto"
                  *ngIf="form.get('finance_id').value"
                />
                <div class="row pt-4" *ngIf="form.get('finance_id').value">
                  <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                    <div class="form-group">
                      <label
                        >{{ "lang_invoice_to" | language }} :<span class="red"
                          >* ({{ "lang_debit" | language }})</span
                        ></label
                      >
                      <ng-select
                        #select
                        [items]="accounts"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                        [searchFn]="ds.customSearchFn"
                        appendTo="body"
                        formControlName="invoice_to_account_id"
                        placeholder="{{ 'lang_invoice_to' | language }}"
                        (search)="
                          searchAccounts(
                            $event,
                            form.get('invoice_payment_mode').value,
                            'customer_accounts'
                          )
                        "
                        (focus)="accounts = []"
                      >
                      </ng-select>
                      <mat-error
                        *ngIf="
                          form.controls['invoice_to_account_id'].touched &&
                          form.controls['invoice_to_account_id'].errors
                            ?.required
                        "
                        >{{ "lang_field_required" | language }}</mat-error
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                    <div class="form-group">
                      <label
                        >{{ "lang_net_amount" | language }} :
                        <span class="red">*</span>
                      </label>
                      <input
                        formControlName="invoice_net_total"
                        class="form-control almnabrformcontrol"
                        placeholder="0.00"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-4 col-xs-12 mb-10">
                    <div class="form-group">
                      <label
                        >{{ "lang_income_accounts" | language }} :
                        <span class="red"
                          >* ({{ "lang_credit" | language }})</span
                        ></label
                      >
                      <ng-select
                        [items]="accounts"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                        [searchFn]="ds.customSearchFn"
                        appendTo="body"
                        formControlName="invoice_income_expanse_account_id"
                        placeholder="{{ 'lang_income_accounts' | language }}"
                        (search)="
                          searchAccounts(
                            $event,
                            form.get('invoice_payment_mode').value,
                            'income_accounts'
                          )
                        "
                        (focus)="accounts = []"
                      >
                      </ng-select>
                      <mat-error
                        *ngIf="
                          form.controls['invoice_income_expanse_account_id']
                            .touched &&
                          form.controls['invoice_income_expanse_account_id']
                            .errors?.required
                        "
                        >{{ "lang_field_required" | language }}</mat-error
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                    <div class="form-group">
                      <label
                        >{{ "lang_grand_total" | language }} :
                        <span class="red">*</span></label
                      >
                      <input
                        formControlName="invoice_grand_total"
                        class="form-control almnabrformcontrol"
                        placeholder="0.00"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="
                      form.get('invoice_net_total').value !=
                      form.get('invoice_roundoff_amount').value
                    "
                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  >
                    <div class="form-group">
                      <label
                        >{{ "lang_invoice_roundoff" | language }} :
                        <span class="red">*</span></label
                      >
                      <ng-select
                        [items]="accounts"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                        [searchFn]="ds.customSearchFn"
                        appendTo="body"
                        formControlName="invoice_roundoff_account_id"
                        placeholder="{{ 'lang_invoice_roundoff' | language }}"
                        (search)="
                          searchAccounts(
                            $event,
                            form.get('invoice_payment_mode').value,
                            ''
                          )
                        "
                        (focus)="accounts = []"
                      >
                      </ng-select>
                      <mat-error
                        *ngIf="
                          form.controls['invoice_roundoff_account_id']
                            .touched &&
                          form.controls['invoice_roundoff_account_id'].errors
                            ?.required
                        "
                        >{{ "lang_field_required" | language }}</mat-error
                      >
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                    <div class="form-group">
                      <label>{{ "lang_invoice_roundoff" | language }} :</label>
                      <div class="input-group">
                        <input
                          formControlName="invoice_roundoff_amount"
                          class="form-control almnabrformcontrol input-group-btn"
                          placeholder="0.00"
                        />
                        <span class="input-group-btn">
                          <button
                            id="btnSearch"
                            type="button"
                            class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-minus mnabricon roundofficon"
                            (click)="calcRoundoffAmount('down')"
                            [disabled]="
                              form.get('invoice_roundoff_amount').value ==
                              '0.00'
                            "
                          ></button>
                        </span>
                        <span class="input-group-btn">
                          <button
                            id="btnSearch"
                            type="button"
                            class="btnSearch enablecode h34 btn btn-md btn-gray fa fa-plus mnabricon roundofficon"
                            (click)="calcRoundoffAmount('up')"
                            [disabled]="
                              form.get('invoice_roundoff_amount').value ==
                              '0.00'
                            "
                          ></button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="
                      (form.get('invoice_global_discount_amount').value &&
                        form.get('invoice_global_discount_amount').value !=
                          '0.00') ||
                      (form.get('total_item_discount').value &&
                        form.get('total_item_discount').value != '0.00')
                    "
                    class="col-md-4 col-sm-4 col-xs-12 mb-10"
                  >
                    <div class="form-group">
                      <label
                        >{{ "lang_discount_accounts" | language }} :
                      </label>
                      <ng-select
                        [items]="accounts"
                        [multiple]="false"
                        bindLabel="label"
                        bindValue="value"
                        clearAllText="Clear"
                        [searchFn]="ds.customSearchFn"
                        appendTo="body"
                        formControlName="invoice_discount_account_id"
                        placeholder="{{ 'lang_discount_accounts' | language }}"
                        (search)="
                          searchAccounts(
                            $event,
                            form.get('invoice_payment_mode').value,
                            'income_accounts'
                          )
                        "
                        (focus)="accounts = []"
                      >
                      </ng-select>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                    <div class="form-group">
                      <label
                        >{{ "lang_total_item_discount" | language }} :
                      </label>
                      <div class="form-control totalDisc">
                        {{ totalDiscount }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table
                        border="1"
                        class="table CustomTable"
                        width="100%"
                        formArrayName="invoiceToCostCenter"
                      >
                        <thead>
                          <tr>
                            <td>#</td>
                            <td>
                              <mat-icon
                                class="mnabricon"
                                matTooltip="{{ 'lang_add' | language }}"
                                (click)="addInvoiceToCostCenter()"
                                >add_box</mat-icon
                              >
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_cost" | language }}
                              <span
                                class="red"
                                *ngIf="invoiceToCostsArray.length > 0"
                                >*</span
                              >
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_debit_amount" | language }}
                              <span
                                class="red"
                                *ngIf="invoiceToCostsArray.length > 0"
                                >*</span
                              >
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_credit_amount" | language }}
                              <span
                                class="red"
                                *ngIf="invoiceToCostsArray.length > 0"
                                >*</span
                              >
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of invoiceToCostCenterGroup().controls;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                            [formGroupName]="i"
                          >
                            <td class="verticalalignmid">
                              {{ i + 1 }}
                            </td>
                            <td class="verticalalignmid">
                              <i
                                (click)="removeInvoiceToCostCenter(i)"
                                class="fa fa-minus-square fa-icon red mnabricon"
                                matTooltip="{{ 'lang_delete' | language }}"
                              ></i>
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <ng-select
                                [items]="
                                  invoiceToCostsArray?.controls[i]?.get(
                                    'costList'
                                  )?.value
                                "
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                clearAllText="Clear"
                                [searchFn]="ds.customSearchFn"
                                appendTo="body"
                                formControlName="cid"
                                placeholder="{{ 'lang_cost' | language }}"
                                (search)="
                                  searchCosts(
                                    $event,
                                    i,
                                    form.get('invoice_to_account_id').value,
                                    'invoiceTo'
                                  )
                                "
                              >
                                <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-search="searchTerm"
                                  let-index="index"
                                >
                                  <span
                                    >{{ item.label }} ({{ item.value }})</span
                                  >
                                </ng-template>
                              </ng-select>
                              <mat-error
                                *ngIf="
                                  invoiceToCostsArray.controls[i].get('cid')
                                    .touched &&
                                  invoiceToCostsArray.controls[i].get('cid')
                                    .errors?.required
                                "
                              >
                                {{
                                  "lang_field_required" | language
                                }}</mat-error
                              >
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <input
                                type="text"
                                formControlName="debit_amount"
                                placeholder="{{
                                  'lang_debit_amount' | language
                                }}"
                                autocomplete="off"
                                class="form-control almnabrformcontrol"
                              />
                              <mat-error
                                *ngIf="
                                  invoiceToCostsArray.controls[i].get(
                                    'debit_amount'
                                  ).touched &&
                                  invoiceToCostsArray.controls[i].get(
                                    'debit_amount'
                                  ).errors?.required
                                "
                              >
                                {{
                                  "lang_field_required" | language
                                }}</mat-error
                              >
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <input
                                type="text"
                                formControlName="credit_amount"
                                placeholder="{{
                                  'lang_credit_amount' | language
                                }}"
                                autocomplete="off"
                                class="form-control almnabrformcontrol"
                              />
                              <mat-error
                                *ngIf="
                                  invoiceToCostsArray.controls[i].get(
                                    'credit_amount'
                                  ).touched &&
                                  invoiceToCostsArray.controls[i].get(
                                    'credit_amount'
                                  ).errors?.required
                                "
                              >
                                {{
                                  "lang_field_required" | language
                                }}</mat-error
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <!-- <div class="col-sm-6 col-12">
                    <div
                      class="table-responsive dropdownyes customResponsive tablefields"
                    >
                      <table
                        border="1"
                        class="table CustomTable"
                        width="100%"
                        formArrayName="incomeAccountsCostCenter"
                      >
                        <thead>
                          <tr>
                            <td
                              width="2%"
                              style="padding: 5px; word-break: break-all"
                            >
                              #
                            </td>
                            <td
                              width="2%"
                              style="padding: 5px; word-break: break-all"
                            >
                              <mat-icon
                                class="mnabricon"
                                matTooltip="{{ 'lang_add' | language }}"
                                (click)="addIncomeAccountsCostCenter()"
                                >add_box</mat-icon
                              >
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_cost" | language }}
                              <span class="red">*</span>
                            </td>
                            <td
                              width="32%"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ "lang_amount" | language }}
                              <span class="red">*</span>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="
                              let item of incomeAccountsCostCenterGroup()
                                .controls;
                              let i = index;
                              let odd = odd;
                              let even = even
                            "
                            [formGroupName]="i"
                          >
                            <td
                              class="verticalalignmid"
                              style="padding: 5px; word-break: break-all"
                            >
                              {{ i + 1 }}
                            </td>
                            <td class="verticalalignmid">
                              <mat-icon
                                (click)="removeIncomeAccountsCostCenter(i)"
                                class="mnabricon red"
                                matTooltip="{{ 'lang_delete' | language }}"
                                >delete_forever</mat-icon
                              >
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <ng-select
                                [items]="
                                  incomeAccountsCostsArray?.controls[i]?.get(
                                    'costList'
                                  )?.value
                                "
                                [multiple]="false"
                                bindLabel="label"
                                bindValue="value"
                                clearAllText="Clear"
                                [searchFn]="ds.customSearchFn"
                                appendTo="body"
                                formControlName="cid"
                                placeholder="{{ 'lang_cost' | language }}"
                                (search)="
                                  searchCosts(
                                    $event,
                                    i,
                                    form.get(
                                      'invoice_income_expanse_account_id'
                                    ).value,
                                    'incomeAccounts'
                                  )
                                "
                              >
                                <ng-template
                                  ng-option-tmp
                                  let-item="item"
                                  let-search="searchTerm"
                                  let-index="index"
                                >
                                  <span
                                    >{{ item.label }} ({{ item.value }})</span
                                  >
                                </ng-template>
                              </ng-select>
                            </td>
                            <td
                              style="
                                padding: 5px;
                                word-break: break-all;
                                position: relative;
                              "
                            >
                              <input
                                type="text"
                                formControlName="amount"
                                placeholder="{{ 'lang_amount' | language }}"
                                autocomplete="off"
                                class="form-control almnabrformcontrol"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> -->
                </div>
                <br />
                <hr style="width: 50%; margin-left: auto; margin-right: auto" />
                <div class="row pt-4">
                  <div class="col-sm-6 col-12">
                    <div class="row">
                      <div
                        class="col-sm-8 col-xs-12 mb-10"
                        *ngIf="
                          form.get('invoice_payment_mode')?.value?.key !=
                            'cash' &&
                          form.get('invoice_payment_mode').value != null
                        "
                      >
                        <div class="form-group">
                          <label
                            >{{ advance_acode_text }} :
                            <span
                              class="red"
                              *ngIf="
                                form.get('invoice_cash_advance_amount')
                                  .touched &&
                                form.get('invoice_cash_advance_amount').value
                              "
                              >* ({{ "lang_debit" | language }})</span
                            ></label
                          >
                          <ng-select
                            [items]="accounts"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            clearAllText="Clear"
                            [searchFn]="ds.customSearchFn"
                            appendTo="body"
                            formControlName="invoice_cash_advance_account_id"
                            placeholder="{{
                              'lang_income_accounts' | language
                            }}"
                            (search)="
                              searchAccounts(
                                $event,
                                form.get('invoice_payment_mode').value,
                                'advance_accounts'
                              )
                            "
                            (focus)="accounts = []"
                          >
                          </ng-select>
                        </div>
                      </div>
                      <div
                        class="col-sm-4 col-xs-12 mb-10"
                        *ngIf="
                          form.get('invoice_payment_mode')?.value?.key !=
                            'cash' &&
                          form.get('invoice_payment_mode').value != null
                        "
                      >
                        <div class="form-group">
                          <label>{{ "lang_cash_advance" | language }} : </label>
                          <input
                            formControlName="invoice_cash_advance_amount"
                            class="form-control almnabrformcontrol"
                            placeholder="0.00"
                            (keyup)="
                              calculate_total_calculation();
                              getsinvoice_calculations()
                            "
                            (change)="
                              ds.CheckDecimal(
                                $event.target.value,
                                form.get('invoice_cash_advance_amount')
                              )
                            "
                          />
                        </div>
                      </div>
                      <div
                        class="col-12"
                        *ngIf="
                          form.get('invoice_payment_mode')?.value?.key !=
                            'cash' &&
                          form.get('invoice_payment_mode').value != null
                        "
                      >
                        <div
                          class="table-responsive dropdownyes customResponsive tablefields"
                        >
                          <table
                            border="1"
                            class="table CustomTable"
                            width="100%"
                            formArrayName="cashAdvanceCostCenter"
                          >
                            <thead>
                              <tr>
                                <td
                                  width="2%"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  #
                                </td>
                                <td
                                  width="2%"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  <mat-icon
                                    class="mnabricon"
                                    matTooltip="{{ 'lang_add' | language }}"
                                    (click)="addCashAdvanceCostCenter()"
                                    >add_box</mat-icon
                                  >
                                </td>
                                <td
                                  width="32%"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  {{ "lang_cost" | language }}
                                  <span
                                    class="red"
                                    *ngIf="
                                      form.get('invoice_cash_advance_amount')
                                        .touched &&
                                      form.get('invoice_cash_advance_amount')
                                        .value
                                    "
                                    >*</span
                                  >
                                </td>
                                <td
                                  width="32%"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  {{ "lang_amount_b_vat" | language }}
                                  <span
                                    class="red"
                                    *ngIf="
                                      form.get('invoice_cash_advance_amount')
                                        .touched &&
                                      form.get('invoice_cash_advance_amount')
                                        .value
                                    "
                                    >*</span
                                  >
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let item of cashAdvanceCostCenterGroup()
                                    .controls;
                                  let i = index;
                                  let odd = odd;
                                  let even = even
                                "
                                [formGroupName]="i"
                              >
                                <td
                                  class="verticalalignmid"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  {{ i + 1 }}
                                </td>
                                <td class="verticalalignmid">
                                  <mat-icon
                                    (click)="removeCashAdvanceCostCenter(i)"
                                    class="mnabricon red"
                                    matTooltip="{{ 'lang_delete' | language }}"
                                    >delete_forever</mat-icon
                                  >
                                </td>
                                <td
                                  style="
                                    padding: 5px;
                                    word-break: break-all;
                                    position: relative;
                                  "
                                >
                                  <ng-select
                                    [items]="
                                      cashAdvanceCostsArray?.controls[i]?.get(
                                        'costList'
                                      )?.value
                                    "
                                    [multiple]="false"
                                    bindLabel="label"
                                    bindValue="value"
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body"
                                    formControlName="cid"
                                    placeholder="{{ 'lang_cost' | language }}"
                                    (search)="
                                      searchCosts(
                                        $event,
                                        i,
                                        form.get('invoice_to_account_id').value,
                                        'cashAdvance'
                                      )
                                    "
                                  >
                                    <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-search="searchTerm"
                                      let-index="index"
                                    >
                                      <span
                                        >{{ item.label }} ({{
                                          item.value
                                        }})</span
                                      >
                                    </ng-template>
                                  </ng-select>
                                </td>
                                <td
                                  style="
                                    padding: 5px;
                                    word-break: break-all;
                                    position: relative;
                                  "
                                >
                                  <input
                                    type="text"
                                    formControlName="amount"
                                    placeholder="{{
                                      'lang_amount_b_vat' | language
                                    }}"
                                    autocomplete="off"
                                    class="form-control almnabrformcontrol"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div class="row">
                      <div class="col-sm-8 col-xs-12 mb-10">
                        <div
                          class="form-group"
                          *ngIf="
                            (form.get('total_item_tax').value &&
                              form.get('total_item_tax').value != '0.00') ||
                            (form.get('invoice_global_tax_amount').value &&
                              form.get('invoice_global_tax_amount').value !=
                                '0.00')
                          "
                        >
                          <label
                            >{{ "lang_tax_accounts" | language }} :
                            <span class="red"
                              >* ({{ "lang_credit" | language }})</span
                            ></label
                          >
                          <ng-select
                            [items]="accounts"
                            [multiple]="false"
                            bindLabel="label"
                            bindValue="value"
                            clearAllText="Clear"
                            [searchFn]="ds.customSearchFn"
                            appendTo="body"
                            formControlName="invoice_tax_account_id"
                            placeholder="{{ 'lang_tax_accounts' | language }}"
                            (search)="
                              searchAccounts(
                                $event,
                                form.get('invoice_payment_mode').value,
                                'tax_accounts'
                              )
                            "
                            (focus)="accounts = []"
                          >
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-sm-4 col-xs-12 mb-10">
                        <div
                          class="form-group"
                          *ngIf="
                            (form.get('total_item_tax').value &&
                              form.get('total_item_tax').value != '0.00') ||
                            (form.get('invoice_global_tax_amount').value &&
                              form.get('invoice_global_tax_amount').value !=
                                '0.00')
                          "
                        >
                          <label
                            >{{ "lang_total_item_tax" | language }} :
                          </label>
                          <input
                            formControlName="total_item_tax"
                            class="form-control almnabrformcontrol"
                            placeholder="0.00"
                          />
                        </div>
                      </div>
                      <div
                        class="col-12"
                        *ngIf="
                          (form.get('total_item_tax').value &&
                            form.get('total_item_tax').value != '0.00') ||
                          (form.get('invoice_global_tax_amount').value &&
                            form.get('invoice_global_tax_amount').value !=
                              '0.00')
                        "
                      >
                        <div
                          class="table-responsive dropdownyes customResponsive tablefields"
                        >
                          <table
                            border="1"
                            class="table CustomTable"
                            width="100%"
                            formArrayName="taxAccountsCostCenter"
                          >
                            <thead>
                              <tr>
                                <td
                                  width="2%"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  #
                                </td>
                                <td
                                  width="2%"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  <mat-icon
                                    class="mnabricon"
                                    matTooltip="{{ 'lang_add' | language }}"
                                    (click)="addTaxAccountsCostCenter()"
                                    >add_box</mat-icon
                                  >
                                </td>
                                <td
                                  width="32%"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  {{ "lang_cost" | language }}
                                  <span class="red">*</span>
                                </td>
                                <td
                                  width="32%"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  {{ "lang_amount_b_vat" | language }}
                                  <span class="red">*</span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let item of taxAccountsCostCenterGroup.controls;
                                  let i = index;
                                  let odd = odd;
                                  let even = even
                                "
                                [formGroupName]="i"
                              >
                                <td
                                  class="verticalalignmid"
                                  style="padding: 5px; word-break: break-all"
                                >
                                  {{ i + 1 }}
                                </td>
                                <td class="verticalalignmid">
                                  <mat-icon
                                    (click)="removeTaxAccountsCostCenter(i)"
                                    class="mnabricon red"
                                    matTooltip="{{ 'lang_delete' | language }}"
                                    >delete_forever</mat-icon
                                  >
                                </td>
                                <td
                                  style="
                                    padding: 5px;
                                    word-break: break-all;
                                    position: relative;
                                  "
                                >
                                  <ng-select
                                    [items]="
                                      taxAccountsCostsArray?.controls[i]?.get(
                                        'costList'
                                      )?.value
                                    "
                                    [multiple]="false"
                                    bindLabel="label"
                                    bindValue="value"
                                    clearAllText="Clear"
                                    [searchFn]="ds.customSearchFn"
                                    appendTo="body"
                                    formControlName="cid"
                                    placeholder="{{ 'lang_cost' | language }}"
                                    (search)="
                                      searchCosts(
                                        $event,
                                        i,
                                        form.get('invoice_to_account_id').value,
                                        'taxAccounts'
                                      )
                                    "
                                  >
                                    <ng-template
                                      ng-option-tmp
                                      let-item="item"
                                      let-search="searchTerm"
                                      let-index="index"
                                    >
                                      <span
                                        >{{ item.label }} ({{
                                          item.value
                                        }})</span
                                      >
                                    </ng-template>
                                  </ng-select>
                                </td>
                                <td
                                  style="
                                    padding: 5px;
                                    word-break: break-all;
                                    position: relative;
                                  "
                                >
                                  <input
                                    type="text"
                                    formControlName="amount"
                                    placeholder="{{
                                      'lang_amount_b_vat' | language
                                    }}"
                                    autocomplete="off"
                                    class="form-control almnabrformcontrol"
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-0 col-sm-9"></div>
                  <div class="col-12 col-sm-3 d-flex justify-content-end">
                    <mat-slide-toggle
                      class="ml-3 mr-3"
                      formControlName="isGlobal"
                      (change)="
                        onToggleGlobal($event); getsinvoice_calculations()
                      "
                      >{{ "lang_global" | language }}</mat-slide-toggle
                    >
                  </div>
                </div>
                <div class="row pt-5" *ngIf="form.get('isGlobal').value">
                  <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                    <div class="form-group" *ngIf="global_discount">
                      <label
                        >{{ "lang_total_item_discount" | language }} :
                      </label>
                      <input
                        formControlName="total_item_discount"
                        class="form-control almnabrformcontrol"
                        placeholder="0.00"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                    <div class="form-group" *ngIf="global_discount">
                      <label>{{ "lang_global_discount" | language }} : </label>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                          <input
                            formControlName="invoice_global_discount_percent"
                            class="form-control almnabrformcontrol"
                            placeholder="0.00"
                            (keyup)="
                              calculate_total_calculation();
                              getsinvoice_calculations()
                            "
                            (change)="
                              ds.CheckDecimal(
                                $event.target.value,
                                form.get('invoice_global_discount_percent')
                              )
                            "
                          />
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                          <input
                            type="text"
                            id="global_tax1"
                            formControlName="invoice_global_discount_amount"
                            class="form-control almnabrformcontrol"
                            placeholder="0"
                            (keyup)="
                              getsinvoice_calculations('fromGlobalAmount')
                            "
                            (change)="
                              ds.CheckDecimal(
                                $event.target.value,
                                form.get('invoice_global_discount_amount')
                              )
                            "
                            autocomplete="off"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                    <div class="form-group" *ngIf="global_tax">
                      <label
                        >{{ "lang_accounts_global_item_tax" | language }} :
                      </label>
                      <input
                        formControlName="invoice_global_tax_amount"
                        class="form-control almnabrformcontrol"
                        placeholder="0.00"
                      />
                    </div>
                  </div>
                  <div class="col-md-3 col-sm-3 col-xs-12 mb-10">
                    <div class="form-group" *ngIf="global_tax">
                      <label>{{ "lang_tax_group" | language }} : </label>
                      <div class="row">
                        <div class="col-md-6 col-sm-6 col-xs-6">
                          <ng-select
                            [items]="taxs"
                            [multiple]="false"
                            clearAllText="Clear"
                            appendTo="body"
                            formControlName="invoice_global_tax_id"
                            placeholder="{{ 'lang_tax_group' | language }}"
                            (change)="
                              global_tax_age(); getsinvoice_calculations()
                            "
                          >
                          </ng-select>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-6">
                          <div class="input-group input-group-sm">
                            <input
                              type="text"
                              id="global_tax1ss"
                              formControlName="invoice_global_tax_age"
                              class="form-control almnabrformcontrol"
                              placeholder="0"
                              autocomplete="off"
                              readonly
                            />
                            <span class="input-group-addon">%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer padb0">
              <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button
                    type="button"
                    class="albutton"
                    routerLink="/accounts/sinvoices"
                  >
                    {{ "lang_goto_list" | language }}
                  </button>
                </div>
                <div class="col-md-8 col-sm-8 col-xs-12 mb-10">
                  <app-alert id="default-alert"></app-alert>
                </div>
                <div class="col-md-2 col-sm-2 col-xs-12 mb-10">
                  <button type="submit" class="albutton">
                    {{ "lang_submit" | language }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</div>
<ng-template #addAccount>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_customers_create" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRef.hide()"
      >
        &times;
      </button>
    </div>
    <form
      [formGroup]="registrationForm"
      (ngSubmit)="createCustomer()"
      class="create"
    >
      <div class="modal-body">
        <div class="row">
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_branch" | language }} :<span class="red"
                  >*</span
                ></label
              >
              <select
                id="focusaddAccount"
                class="form-control almnabrformcontrol"
                formControlName="branch_id"
              >
                <option value="">
                  {{ "lang_choose_options" | language }}
                </option>
                <option *ngFor="let branch of branches" value="{{ branch.id }}">
                  {{ branch.title }}
                </option>
              </select>
              <mat-error
                *ngIf="
                  registrationForm.controls['branch_id'].touched &&
                  registrationForm.controls['branch_id'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}</mat-error
              >
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_human_resources_nameen" | language }} :<span
                  class="red"
                  >*</span
                ></label
              >
              <input
                type="text"
                class="form-control almnabrformcontrol customer_title_en"
                placeholder="{{ 'lang_human_resources_nameen' | language }}"
                formControlName="customer_title_en"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_title_en'].touched &&
                  registrationForm.controls['customer_title_en'].errors
                    ?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_human_resources_namear" | language }} :<span
                  class="red"
                  >*</span
                ></label
              >
              <input
                type="text"
                class="form-control almnabrformcontrol customer_title_ar"
                placeholder="{{ 'lang_human_resources_namear' | language }}"
                formControlName="customer_title_ar"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_title_ar'].touched &&
                  registrationForm.controls['customer_title_ar'].errors
                    ?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_city" | language }} :<span class="red">*</span></label
              >
              <input
                type="text"
                class="form-control almnabrformcontrol city"
                placeholder="{{ 'lang_city' | language }}"
                formControlName="city"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['city'].touched &&
                  registrationForm.controls['city'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_street_name" | language }} :<span class="red"
                  >*</span
                ></label
              >
              <input
                type="text"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_street_name' | language }}"
                formControlName="street_name"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['street_name'].touched &&
                  registrationForm.controls['street_name'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}</mat-error
              >
            </div>
          </div>
          <div class="col-md-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_building_number" | language }} :<span class="red"
                  >*</span
                ></label
              >
              <input
                minlength="4"
                type="text"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_building_number' | language }}"
                formControlName="building_number"
                (keypress)="ds.numberOnly($event)"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['building_number'].touched &&
                  registrationForm.controls['building_number'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
              <mat-error
                *ngIf="
                  registrationForm.controls['building_number'].errors?.minlength
                "
              >
                {{ "lang_minimum" | language }} 4
              </mat-error>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_plot_identification" | language }} :<span class="red"
                  >*</span
                ></label
              >
              <input
                type="text"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_plot_identification' | language }}"
                formControlName="plot_identification"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['plot_identification'].touched &&
                  registrationForm.controls['plot_identification'].errors
                    ?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_city_sub_division" | language }} :<span class="red"
                  >*</span
                ></label
              >
              <input
                minlength="4"
                type="text"
                class="form-control almnabrformcontrol city_sub_division"
                placeholder="{{ 'lang_city_sub_division' | language }}"
                formControlName="city_sub_division"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['city_sub_division'].touched &&
                  registrationForm.controls['city_sub_division'].errors
                    ?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
              <mat-error
                *ngIf="
                  registrationForm.controls['city_sub_division'].errors
                    ?.minlength
                "
              >
                {{ "lang_minimum" | language }} 4
              </mat-error>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_postal_number" | language }} :</label>
              <input
                minlength="5"
                type="text"
                class="form-control almnabrformcontrol postal_number"
                placeholder="{{ 'lang_postal_number' | language }}"
                formControlName="postal_number"
                (keypress)="ds.numberOnly($event)"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['postal_number'].touched &&
                  registrationForm.controls['postal_number'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
              <mat-error
                *ngIf="
                  registrationForm.controls['postal_number'].errors?.minlength
                "
              >
                {{ "lang_minimum" | language }} 5
              </mat-error>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_registration_name" | language }} : </label>
              <input
                type="text"
                class="form-control almnabrformcontrol registration_name"
                placeholder="{{ 'lang_registration_name' | language }}"
                formControlName="registration_name"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['registration_name'].touched &&
                  registrationForm.controls['registration_name'].errors
                    ?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_email_address" | language }} :</label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_email_address"
                placeholder="{{ 'lang_email_address' | language }}"
                formControlName="customer_email_address"
                (input)="checkEmailExists()"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_email_address'].touched &&
                  registrationForm.controls['customer_email_address'].errors
                    ?.email
                "
              >
                {{ "lang_field_valid_email" | language }}
              </mat-error>
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_email_address'].touched &&
                  registrationForm.controls['customer_email_address'].valid &&
                  isEmailExists
                "
              >
                {{ "lang_email_exists" | language }}
                <span *ngIf="emailErrMsg"> ({{ emailErrMsg }}) </span>
              </mat-error>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_mobile_number" | language }} :</label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_mobile"
                placeholder="{{ 'lang_mobile_number' | language }}"
                minlength="12"
                maxlength="12"
                (keypress)="ds.numberOnly($event)"
                formControlName="customer_mobile"
                (input)="checkMobExists()"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_mobile'].errors?.minlength
                "
              >
                {{ "lang_minimum" | language }} 12
              </mat-error>
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_mobile'].errors?.maxlength
                "
              >
                {{ "lang_maximum" | language }} 12
              </mat-error>
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_mobile'].touched &&
                  registrationForm.controls['customer_mobile'].valid &&
                  isMobExists
                "
              >
                {{ "lang_mobile_exists" | language }}
                <span *ngIf="mobErrMsg"> ({{ mobErrMsg }}) </span>
              </mat-error>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_vat_tax" | language }} :</label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_tax_vat"
                placeholder="{{ 'lang_vat_tax' | language }}"
                formControlName="customer_tax_vat"
              />
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_tax_vat'].touched &&
                  registrationForm.controls['customer_tax_vat'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_ccr_no" | language }} : </label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_ccr_no"
                placeholder="{{ 'lang_ccr_no' | language }}"
                formControlName="customer_ccr_no"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_manager" | language }} :</label>
              <ng-select
                [items]="users"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                [searchFn]="ds.customSearchFn"
                appendTo="body"
                formControlName="customer_managers"
                placeholder="{{ 'lang_manager' | language }}"
                (search)="search_users($event)"
                (focus)="users = []"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_users" | language }} :</label>
              <ng-select
                [items]="users"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                [searchFn]="ds.customSearchFn"
                appendTo="body"
                formControlName="customer_teams"
                placeholder="{{ 'lang_users' | language }}"
                (search)="search_users($event)"
                (focus)="users = []"
              >
              </ng-select>
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_teams'].touched &&
                  registrationForm.controls['customer_teams'].errors?.required
                "
              >
                {{ "lang_field_required" | language }}
              </mat-error>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_link_with_accounts" | language }} :<span class="red"
                  >*</span
                ></label
              >
              <select
                type="text"
                class="form-control almnabrformcontrol customer_link_with_accounts"
                matTooltip="{{ 'lang_link_with_accounts' | language }}"
                formControlName="customer_link_with_accounts"
                (change)="emptyAccountFileds()"
              >
                <option value="">
                  {{ "lang_choose_options" | language }}
                </option>
                <option value="1">{{ "lang_yes" | language }}</option>
                <option value="0">{{ "lang_no" | language }}</option>
              </select>
              <mat-error
                *ngIf="
                  registrationForm.controls['customer_link_with_accounts']
                    .touched &&
                  registrationForm.controls['customer_link_with_accounts']
                    .errors?.required
                "
              >
                {{ "lang_field_required" | language }}</mat-error
              >
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label
                >{{ "lang_logo" | language }} :
                <mat-icon
                  *ngIf="isFile"
                  matTooltip="{{ 'lang_delete' | language }}"
                  (click)="removeFile()"
                  class="red mnabricon"
                  >close</mat-icon
                ></label
              >
              <div class="IDDIV">
                <input
                  name="logo"
                  type="file"
                  onclick="this.value = null"
                  (change)="uploadFile($event)"
                  class="nghide albutton width100"
                />
                <label id="uploadButton" class="albutton"
                  >{{ filename }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          *ngIf="
            registrationForm.get('customer_link_with_accounts').value == '1'
          "
        >
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_address_text" | language }} :</label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_address"
                placeholder="{{ 'lang_address_text' | language }}"
                formControlName="customer_address"
              />
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_credit_days" | language }} : </label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_credit_days"
                placeholder="{{ 'lang_credit_days' | language }}"
                (keypress)="ds.numberOnly($event)"
                formControlName="customer_credit_days"
              />
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_security_amount" | language }} : </label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_security_amount"
                placeholder="{{ 'lang_security_amount' | language }}"
                (keypress)="ds.numberOnly($event)"
                formControlName="customer_security_amount"
              />
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_credit_limit" | language }} : </label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_credit_limit"
                placeholder="{{ 'lang_credit_limit' | language }}"
                (keypress)="ds.numberOnly($event)"
                formControlName="customer_credit_limit"
              />
            </div>
          </div>
        </div>
        <div
          class="row"
          *ngIf="
            registrationForm.get('customer_link_with_accounts').value == '1'
          "
        >
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_discount_limit" | language }} : </label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_discount_limit"
                placeholder="{{ 'lang_discount_limit' | language }}"
                formControlName="customer_discount_limit"
                (keypress)="ds.numberOnly($event)"
              />
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_discount_on_payment" | language }} : </label>
              <input
                type="text"
                class="form-control almnabrformcontrol customer_discount_onpay"
                placeholder="{{ 'lang_discount_on_payment' | language }}"
                formControlName="customer_discount_onpay"
                (keypress)="ds.numberOnly($event)"
              />
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_salesman" | language }} : </label>
              <select
                type="text"
                class="form-control almnabrformcontrol customer_salesmancode"
                matTooltip="{{ 'lang_salesman' | language }}"
                formControlName="customer_salesmancode"
              >
                <option value="">
                  {{ "lang_choose_options" | language }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_salesman_area" | language }} : </label>
              <select
                type="text"
                class="form-control almnabrformcontrol customer_salesmanarea"
                matTooltip="{{ 'lang_salesman_area' | language }}"
                formControlName="customer_salesmanarea"
              >
                <option value="">
                  {{ "lang_choose_options" | language }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          class="row"
          *ngIf="
            registrationForm.get('customer_link_with_accounts').value == '1'
          "
        >
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_stop_transaction" | language }} : </label>
              <select
                type="text"
                class="form-control almnabrformcontrol customer_stop_transaction"
                matTooltip="{{ 'lang_link_with_accounts' | language }}"
                formControlName="customer_stop_transaction"
              >
                <option value="">
                  {{ "lang_choose_options" | language }}
                </option>
                <option value="1">{{ "lang_yes" | language }}</option>
                <option value="0">{{ "lang_no" | language }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-xs-12 mb-10">
            <div class="form-group">
              <label>{{ "lang_account" | language }} : </label>
              <ng-select
                [items]="accounts"
                [multiple]="false"
                bindLabel="label"
                bindValue="value"
                clearAllText="Clear"
                [searchFn]="ds.customSearchFn"
                appendTo="body"
                formControlName="customer_account_id"
                placeholder="{{ 'lang_account' | language }}"
                (search)="accountSearch($event)"
                (focus)="accounts = []"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer pad15">
        <div class="row">
          <div class="col-12 mb-10">
            <app-alert id="default-alert"></app-alert>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xs-12 mb-10">
            <button type="submit" class="albutton">
              {{ "lang_submit" | language }}
            </button>
          </div>
          <div class="col-md-6 col-xs-12 mb-10">
            <button
              type="button"
              class="btn btn-secondary secondarybutton"
              data-dismiss="modal"
              (click)="modalRef.hide()"
            >
              {{ "lang_close" | language }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>
