import {
	trigger,
	state,
	style,
	transition,
	animate,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import { HttpHeaders } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
	PreloaderService,
	SettingsService,
	StartupService,
	TokenService,
} from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { MessagingService } from "@core/bootstrap/messaging.service";
import { MnabrthemeService } from "@core/bootstrap/mnabrtheme.service";
import { PermissionService } from "@core/bootstrap/permission.service";
import { SocketServiceService } from "@core/bootstrap/socket-service.service";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@shared/components/alert/alert.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { SweetAlertService } from "@shared/services/sweet-alert.service";
import { ApiRoutes } from "app/routes/apiroutes";
import moment from "moment";
import { Subscription } from "rxjs";
import 'moment-timezone';
@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
	animations: [
		trigger("fade", [
			state("in", style({ opacity: 1 })),
			transition(":enter", [style({ opacity: 0 }), animate(2000)]),
			transition(":leave", animate(0, style({ opacity: 0 }))),
		]),
	],
})
export class LoginComponent implements OnInit {
	form: FormGroup;
	profileimage: string = "";
	error_status: boolean = false;
	error_message: string = "";
	languages: any = this.ds.languages;
	options = this.settings.getOptions();
	lang_key = localStorage.getItem("lang_key") || "en";
	qrcode_img = "assets/images/logo.svg";
	api_key = environment.X_API_KEY;
	private subscriptions = new Subscription();
	showBarcode = false;
	//send code
	currentIndex = 0;
	need_otp = false;
	email = "";
	mobile = "";
	whatsapp = "";
	constructor(
		public lang: LanguagePipe,
		private fb: FormBuilder,
		private router: Router,
		private token: TokenService,
		private startup: StartupService,
		private theme: MnabrthemeService,
		private settings: SettingsService,
		private ds: DataService,
		private spinner: PreloaderService,
		private ls: LocalStorage,
		private alert: AlertService,
		private permissionService: PermissionService,
		private messagingService: MessagingService,
		private socketService: SocketServiceService,
		private translate: TranslateService,
		private sweetAlert: SweetAlertService,
		@Inject(DOCUMENT) private document: Document
	) {
		this.form = this.fb.group({
			username: ["", [Validators.required]],
			password: ["", [Validators.required]],
			sendcodetype: ["", [Validators.required]],
			verification_code: ["", [Validators.required, Validators.min(4)]],
		});
	}
	ngOnInit() {
		this.socketService.connectToServerQRcode();
		this.socketService.socketQrCode.on("connect", () => {
			if (this.socketService.socketQrCode.connected) {
				this.showBarcode = true;
				this.getAutoQr();
				this.listenToLogin();
			} else {
				this.showBarcode = false;
			}
		});
	}
	listenToLogin() {
		this.socketService.socketQrCode.on("loginStatus", (res) => {
			localStorage.setItem("loggedin", "1");
			const { token, uid, username } = {
				token: this.ls.getsetdata("token", res.user_data.token),
				uid: this.ls.getsetdata("user_id", res.user_data.user_id),
				username: this.ls.getsetdata(
					"user_username",
					res.user_data.user_username
				),
			};
			localStorage.setItem('user_company_email', res?.user_data?.user_company_email || '');
			this.settings.setUser({
				id: this.ls.getItem("user_id"),
				name: this.ls.getsetdata("user_username", res.user_data.user_username),
				email: this.ls.getsetdata("user_email", res.user_data.user_email),
				mobile: this.ls.getsetdata("user_phone", res.user_data.user_phone),
				admin: this.ls.getsetdata("is_admin", res.user_data.is_admin),
				usertype: this.ls.getsetdata(
					"user_type_id",
					res.user_data.user_type_id
				),
				username: this.ls.getItemString("user_username"),
				user_active_status: this.ls.getsetdata(
					"user_active_status",
					res.user_data.user_active_status
				),
			});
			if (res?.user_data?.lang_key == "ar") {
				localStorage.setItem("lang_key", "ar");
				localStorage.setItem("direction", "rtl");
			} else {
				localStorage.setItem("lang_key", "en");
				localStorage.setItem("direction", "ltr");
			}
			const defaultLang = localStorage.getItem("lang_key") || "en";
			this.translate.setDefaultLang(defaultLang);
			this.document.body.dir = localStorage.getItem("direction") || "ltr";
			this.permissionService.load();
			this.token.set({ token, uid, username });
			this.theme.load();
			this.startup.load().then(() => {});
			this.router.navigateByUrl("/");
			this.socketService.socketQrCode.close();
			if (this.ls.getItem("token")) {
				this.socketService.connectToServer();
				this.ds.checkEmployeeStatus(this.ls.getItem("user_id"));
			}
			if (
				localStorage.getItem("loggedin") == "1" &&
				navigator.userAgent.indexOf("Chrome") != -1
			) {
				this.messagingService.requestPermission();
				this.messagingService.receiveMessage();

				let formData = new FormData();
				formData.append("noti_registrationId", token);
				this.ds
					.post("user/update/noti_registrationId", formData)
					.subscribe((res) => {});
			}
		});
	}
	getAutoQr() {
		this.showBarcode = true;
		let header = {
			"X-API-KEY": this.api_key,
		};
		this.ds
			.getWithXapikey(
				`auto_qr?qrcode=${this.socketService.socketQrCode.id}`,
				header
			)
			.subscribe(
				(res) => {
					if (res?.status) {
						this.qrcode_img = `data:image/png;base64,${res?.imgpngbase64}`;
					} else {
						this.qrcode_img = "assets/images/logo.svg";
						this.showBarcode = false;
					}
				},
				(err) => {
					this.qrcode_img = "assets/images/logo.svg";
					this.showBarcode = false;
				}
			);
	}

	switchlanguage(lang): void {
		localStorage.setItem("lang_key", lang.lang_key);
		if (localStorage.getItem("direction") != lang.lang_dir) {
			localStorage.setItem("direction", lang.lang_dir);
			window.location.reload();
		}
	}
	login() {
		let fireToken = this.messagingService.fireToken;
		this.spinner.show();
		const formData = new FormData();
		formData.append("username", this.form.get("username").value);
		formData.append("password", this.form.get("password").value);
		formData.append("noti_registrationId", fireToken);
		formData.append("platform", "web");
		formData.append(
			"verification_code",
			this.form.get("verification_code")?.value || ""
		);
		formData.append(
			"language",
			localStorage.getItem("lang_key") == null
				? "en"
				: localStorage.getItem("lang_key")
		);
		this.subscriptions.add(
			this.ds.post(ApiRoutes.login, formData).subscribe(
				(res) => {
					this.spinner.hide();
					if (res.status && res.user_data) {
						localStorage.setItem("loggedin", "1");
						const { token, uid, username } = {
							token: this.ls.getsetdata("token", res.user_data.token),
							uid: this.ls.getsetdata("user_id", res.user_data.user_id),
							username: this.ls.getsetdata(
								"user_username",
								res.user_data.user_username
							),
						};
						localStorage.setItem('user_company_email', res?.user_data?.user_company_email || '');
						this.settings.setUser({
							id: this.ls.getItem("user_id"),
							name: this.ls.getsetdata(
								"user_username",
								res.user_data.user_username
							),
							email: this.ls.getsetdata("user_email", res.user_data.user_email),
							mobile: this.ls.getsetdata(
								"user_phone",
								res.user_data.user_phone
							),
							admin: this.ls.getsetdata("is_admin", res.user_data.is_admin),
							usertype: this.ls.getsetdata(
								"user_type_id",
								res.user_data.user_type_id
							),
							username: this.ls.getItemString("user_username"),
							user_active_status: this.ls.getsetdata(
								"user_active_status",
								res.user_data.user_active_status
							),
						});
						this.saveTokenExpiryTime(res);
						this.permissionService.load();
						this.token.set({ token, uid, username });
						this.theme.load();
						this.startup.load().then(() => {});
						this.router.navigateByUrl("/");
						if (this.ls.getItem("token")) {
							this.socketService.connectToServer();
							this.messagingService.requestPermission();
							this.messagingService.receiveMessage();
							this.ds.checkEmployeeStatus(this.ls.getItem("user_id"));
						}
					} else {
						this.alert.error(res?.error);
					}
				},
				(error) => {
					this.spinner.hide();
					this.alert.error(
						error && error.error && error.error.error
							? error.error.error
							: this.lang.transform("lang_internal_server_error")
					);
				}
			)
		);
	}

	getProfileImg(e) {
		let params = {
			user_id: "",
			user_username: e?.target?.value,
		};
		let headers = new HttpHeaders();
		headers.append("X-API-KEY", environment.X_API_KEY);
		this.ds
			.getActionByNoTokenFullUrl(params, "user/avatar", headers)
			.subscribe((res) => {
				if (res?.avatar) {
					this.profileimage = res?.avatar;
					this.ls.setItem("user_avatar", res?.avatar);
				} else {
					this.profileimage = "";
					this.ls.setItem("user_avatar", "");
				}
			});
	}

	moveToNext() {
		if (this.currentIndex < 2) {
			this.currentIndex++;
		}
	}

	moveToPrev() {
		if (this.currentIndex <= 2 && this.currentIndex > 0) {
			this.currentIndex--;
		}
	}
	checkSendCode() {
		this.spinner.show();
		this.ds
			.get(
				`user/get_code_options?username=${
					this.form.get("username").value
				}&password=${this.form.get("password").value}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.need_otp = true;
						this.form.get("sendcodetype").setValidators(Validators.required);
						this.form.get("sendcodetype").updateValueAndValidity();
						this.form
							.get("verification_code")
							.setValidators([Validators.required, Validators.min(4)]);
						this.form.get("verification_code").updateValueAndValidity();
						this.email = `Email (${res?.records?.email})`;
						this.mobile = `Mobile (${res?.records?.mobile})`;
						this.whatsapp = `Whatsapp (${res?.records?.whatsapp})`;
						this.moveToNext();
					} else if (res?.status == false && res?.otp_need == 0) {
						this.need_otp = false;
						this.form.get("sendcodetype").clearValidators();
						this.form.get("sendcodetype").clearAsyncValidators();
						this.form.get("sendcodetype").updateValueAndValidity();
						this.form.get("verification_code").clearValidators();
						this.form.get("verification_code").clearAsyncValidators();
						this.form.get("verification_code").updateValueAndValidity();
						this.login();
					} else if (res?.status == false) {
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
				}
			);
	}

	sendCode() {
		this.spinner.show();
		this.ds
			.get(
				`user/send_otp_code?username=${
					this.form.get("username").value
				}&password=${this.form.get("password").value}&sender_type=${
					this.form.get("sendcodetype").value
				}`
			)
			.subscribe(
				(res) => {
					this.spinner.hide();
					if (res?.status) {
						this.sweetAlert.successToast(res?.error, 3000);
						this.moveToNext();
					} else {
						this.sweetAlert.errorToast(res?.error, 3000);
					}
				},
				(err) => {
					this.spinner.hide();
					this.sweetAlert.errorToast(
						this.lang.transform("lang_internal_server_error"),
						3000
					);
				}
			);
	}

	saveTokenExpiryTime(data:any) {
		const dateEnd = moment.unix(+data?.user_data?.user_token_timestamp_created + data?.user_data?.token_set_time).toString();
		this.ls.setItem('logoutTokenExpiry', dateEnd);
		this.ls.setItem('token_set_time', JSON.stringify(data?.user_data?.token_set_time));
		console.log(dateEnd);
		
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
