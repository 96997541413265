<div class="row">
  <div class="col-12">
    <div class="material-panel-body tlist">
      <div class="mbrl15 fromtransactions">
        <div class="row col-md-12 attendence mb-4">
          <div class="col-12 col-sm-3">
            <mat-form-field
              style="width: 100%"
              appearance="outline"
              class="device-date"
            >
              <mat-label>{{ "lang_person_id" | language }}</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="person_id"
                (keyup)="get_logs_lists()"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-3">
            <mat-form-field
              style="width: 100%"
              appearance="outline"
              class="device-date"
            >
              <mat-label>{{ "lang_device_key" | language }}</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="device_key"
                (keyup)="get_logs_lists()"
              />
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-4">
            <mat-form-field
              appearance="outline"
              class="device-date"
              style="width: 100%"
            >
              <mat-label>{{ "lang_date" | language }}</mat-label>
              <mat-date-range-input
                [formGroup]="reasonFromToDate"
                [rangePicker]="picker"
              >
                <input
                  matStartDate
                  formControlName="start"
                  readonly
                  placeholder="{{ 'lang_start_date' | language }}"
                />
                <input
                  matEndDate
                  formControlName="end"
                  readonly
                  placeholder="{{ 'lang_end_date' | language }}"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker (closed)="get_logs_lists()">
              </mat-date-range-picker>
              <mat-icon
                matDatepickerToggleIcon
                (click)="reasonFromToDate.reset(); get_logs_lists()"
                >clear</mat-icon
              >
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-2">
            <button
              type="button"
              (click)="export_exl()"
              class="mt-2 albutton"
              [disabled]="listData?.length === 0 && !loading"
            >
              {{ "lang_export_excel" | language }}
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive customResponsive">
              <table border="1" class="table CustomTable">
                <thead>
                  <tr>
                    <td>#</td>
                    <td>{{ "lang_image" | language }}</td>
                    <td>{{ "lang_person_name" | language }}</td>
                    <td>{{ "lang_person_id" | language }}</td>
                    <td>{{ "lang_device_ip" | language }}</td>
                    <td>{{ "lang_device_key" | language }}</td>
                    <td>{{ "lang_liveness_score" | language }}</td>
                    <td>{{ "lang_search_score" | language }}</td>
                    <td>{{ "lang_date" | language }}</td>
                  </tr>
                </thead>
                <tbody
                  *ngIf="listData?.records?.length != '0'"
                  class="AppendList"
                >
                  <tr
                    *ngFor="
                      let list of listData?.records;
                      let i = index;
                      let odd = odd;
                      let even = even
                    "
                    [ngClass]="{ odd: odd, even: even }"
                  >
                    <td>{{ i + 1 }}</td>
                    <td>
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <img [src]="list.path" style="width: 50px" />
                        <span
                          *ngIf="list.system_path"
                          (click)="
                            downloadFile(list.system_path, list.person_name)
                          "
                        >
                          <i class="fa fa-download mnabricon"></i>
                        </span>
                      </div>
                    </td>
                    <td>{{ list.person_name }}</td>
                    <td>{{ list.person_id }}</td>
                    <td>{{ list.ip }}</td>
                    <td>{{ list.device_key }}</td>
                    <td>{{ list.liveness_score }}</td>
                    <td>{{ list.search_score }}</td>
                    <td>{{ list.action_datatime }}</td>
                  </tr>
                </tbody>
                <tbody *ngIf="loading">
                  <tr>
                    <td colspan="9" class="nodata" align="center">
                      <ngx-skeleton-loader
                        count="5"
                        [theme]="{ 'border-radius': '3px', height: '50px' }"
                      ></ngx-skeleton-loader>
                    </td>
                  </tr>
                </tbody>
                <tbody
                  *ngIf="listData?.length === 0 && !loading"
                  class="AppendList"
                >
                  <tr class="odd">
                    <td colspan="9" class="nodata" align="center">
                      {{ nodata }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="listData?.page?.total_records > 0" class="card-footer padb0">
        <div class="row responsive_pagination">
          <div
            class="col-lg-3 col-sm-3 mb-10"
            style="height: fit-content; margin: auto 0"
          >
            {{ "lang_total" | language }} :
            {{ listData?.page?.total_records }}
          </div>
          <div
            class="col-lg-5 col-sm-5 mb-10"
            style="display: flex; justify-content: center"
            *ngIf="listData?.page?.total_records > 0"
          >
            <ngb-pagination
              class="m-auto"
              [collectionSize]="listData?.page?.total_records"
              [rotate]="true"
              [ellipses]="false"
              [maxSize]="3"
              [boundaryLinks]="true"
              [(page)]="page_no"
              [pageSize]="pagesize"
              (pageChange)="loadPageFrm($event)"
            ></ngb-pagination>
          </div>
          <div
            class="col-lg-3 col-sm-3"
            style="display: flex; justify-content: end; margin: auto"
          >
            <mat-select
              style="max-width: 150px"
              [(ngModel)]="pagesize"
              (selectionChange)="loadFrmPagesize($event.value)"
            >
              <mat-option class="text-center" value="10"
                >10 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="20"
                >20 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="50"
                >50 {{ "lang_per_page" | language }}
              </mat-option>
              <mat-option class="text-center" value="100"
                >100 {{ "lang_per_page" | language }}</mat-option
              >
              <mat-option class="text-center" value="500"
                >500 {{ "lang_per_page" | language }}</mat-option
              >
            </mat-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
