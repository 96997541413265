  export const environment = {
	SERVER_ORIGIN: "https://erp.manbaa.sa/backend/",
	SOCKET_ORIGIN: "https://node.manbaa.sa",
	frontUrl: "https://erp.manbaa.sa/",
	X_API_KEY: "PCGYdyK45y6474x6baRrraRpSFc",
	encryptionPassword: "5465465464654sd65f4g6sd54gs6d5g4s65dg4",
	GOOGLE_MAPS_API_KEY: "AIzaSyAcCkMwqbGOz2CoPgagwkv-Hl4mwJDaTk0",
	firebaseConfig: {
		apiKey: "AIzaSyD3SjIIoDGV-VdkXtEakQC4iXLjBMCRAso",
		messagingSenderId: "419511277631",
		appId: "1:419511277631:web:a4aed26061343516c1008a",
		measurementId: "G-FW3WTMYCNN",
		authDomain: "almnabr-1535481109604.firebaseapp.com",
		projectId: "almnabr-153548110960",
		storageBucket: "almnabr-1535481109604.appspot.com",
	},
	production: true,
	useHash: true,
	hmr: false,
	singledropdown: {
		singleSelection: true,
		text: "Select",
		searchPlaceholderText: "Search",
		enableSearchFilter: true,
		labelKey: "label",
		primaryKey: "value",
	},
	multipledropdown: {
		text: "Select",
		selectAllText: "Select All",
		unSelectAllText: "UnSelect All",
		searchPlaceholderText: "Search",
		enableSearchFilter: true,
		labelKey: "label",
		primaryKey: "value",
		badgeShowLimit: 2,
	},
	theme: {
		acolor: "#1992bc",
		bcolor: "#000000",
		ccolor: "#555555",
		dcolor: "#ffffff",
	},
	modelconfig: {
		animated: true,
		keyboard: true,
		backdrop: true,
		ignoreBackdropClick: true,
	},
	templateType: 1,
};

