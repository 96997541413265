import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreloaderService } from '@core';
import { DataService } from '@core/bootstrap/data.service';
import { LanguagePipe } from '@shared/pipes/language.pipe';
import { TrackingReportsAddComponent } from './tracking-reports-add/tracking-reports-add.component';
import { SweetAlertService } from '@shared/services/sweet-alert.service';
import { HttpParams } from '@angular/common/http';
import { LocalStorage } from '@core/bootstrap/localstorage.service';
declare var $:any;

@Component({
  selector: 'app-tracking-reports-list',
  templateUrl: './tracking-reports-list.component.html',
  styleUrls: ['./tracking-reports-list.component.scss']
})
export class TrackingReportsListComponent implements OnInit {
  lang_key = localStorage?.getItem('lang_key') || 'en';
  filterForm!: FormGroup;
  emailsList = [];
  typeList = [
    {
      label: this.lang.transform('lang_manual'),
      value: 'manual'
    },
    {
      label: this.lang.transform('lang_application'),
      value: 'application'
    }
  ];
  trackingReports = [];
  errormessagef = '';
  page_no = 1;
  page_size = '10';
  total_pages = 0;
  total_records = 0;
  constructor(private fb: FormBuilder, private lang: LanguagePipe, public ds: DataService, public ls:LocalStorage,
    private spinner: PreloaderService, private dialog: MatDialog, private sweetAlert: SweetAlertService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getTrackingReports();
    this.getTrackingReportsTotal();
  }

  buildForm() {
    this.filterForm = this.fb.group({
      start_date: [new Date()],
      end_date: [new Date()],
      project_id: [''],
      project_group_by_file_name: [true],
      user_emails: [null],
      user_emails_group_by_file_name: [true],
      recordstype: [null]
    });
  }

  searchUserEmail(searchKey) {
    this.ds.get(`human_resources/search_users_tracking?search_key=${searchKey?.term || ''}`).subscribe(res => {
      if(res?.status) {
        this.emailsList = res?.records;
      } else {
        this.emailsList = [];
      }
    }, err => {
      this.emailsList = [];
    })
  }

  getTrackingReports() {
    const formData = new FormData();
    formData.append('start_date', this.filterForm?.get('start_date')?.value || '');
    formData.append('end_date', this.filterForm?.get('end_date')?.value || '');
    formData.append('project_id', this.filterForm?.get('project_id')?.value || '');
    formData.append('project_group_by_file_name', this.filterForm?.get('project_group_by_file_name')?.value ? '1' : '0');
    formData.append('user_emails', this.filterForm?.get('user_emails')?.value?.toString() || '');
    formData.append('user_emails_group_by_file_name', this.filterForm?.get('user_emails_group_by_file_name')?.value ? '1' : '0');
    formData.append('recordstype', this.filterForm?.get('recordstype')?.value || '');
    this.spinner.show();
    this.ds.getActionByUrl(
      this.ds.formData2string(formData), 
      `np/design/tracking/tracking_reports_details_hr/${this.page_no}/${this.page_size}`).subscribe(res => {
        this.spinner.hide();
        if(res?.status) {
          this.trackingReports = res?.records;
          this.errormessagef = '';
        } else {
          this.trackingReports = [];
          this.errormessagef = res?.error;
        }
      }, err => {
        this.trackingReports = [];
        this.spinner.hide();
      })
  }

  getTrackingReportsTotal() {
    const formData = new FormData();
    formData.append('start_date', this.filterForm?.get('start_date')?.value || '');
    formData.append('end_date', this.filterForm?.get('end_date')?.value || '');
    formData.append('project_id', this.filterForm?.get('project_id')?.value || '');
    formData.append('project_group_by_file_name', this.filterForm?.get('project_group_by_file_name')?.value ? '1' : '0');
    formData.append('user_emails', this.filterForm?.get('user_emails')?.value?.toString() || '');
    formData.append('user_emails_group_by_file_name', this.filterForm?.get('user_emails_group_by_file_name')?.value ? '1' : '0');
    formData.append('recordstype', this.filterForm?.get('recordstype')?.value || '');
    this.spinner.show();
    this.ds.getActionByUrl(
      this.ds.formData2string(formData), 
      `np/design/tracking/tracking_reports_details_hr_counts`).subscribe(res => {
        this.spinner.hide();
        if(res?.status) {
          this.total_records = res?.total;
        } else {
          this.total_records = 0;
        }
      }, err => {
        this.total_records = 0;
        this.spinner.hide();
      })
  }

  public load_page_size(page_size) {
    this.page_size = page_size;
    this.getTrackingReports();
  }

  public page_length(page) {
    this.page_no = page;
    this.getTrackingReports();
  }

  onAddTrackingReport() {
    this.dialog.open(TrackingReportsAddComponent, {
      autoFocus: false,
      width: '70vw'
    })
  }

  checkAllItems(event) {
    if (event.target.checked) {
      $(".tracking-reports .eachItem").prop("checked", true);
      $(".tracking-reports .makeback").addClass("bground");
      $(".tracking-reports .deleteIconDiv").show();
    } else {
      $(".tracking-reports .eachItem").prop("checked", false);
      $(".tracking-reports .makeback").removeClass("bground");
      $(".tracking-reports .deleteIconDiv").hide();
    }
  }
  checkEachItem(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".tracking-reports .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".tracking-reports .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".tracking-reports .eachItem:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".tracking-reports .eachItem").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".tracking-reports .deleteIconDiv").show();
    } else {
      $(".tracking-reports .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".tracking-reports #allItems").prop("checked", true);
    } else {
      $(".tracking-reports #allItems").prop("checked", false);
    }
  }
  deleteBulkData() {
    const checkedtotal = [];
    $(".tracking-reports .eachItem:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      let httpParams = new HttpParams();
      httpParams = httpParams.append("ids", checkedtotal?.toString());
      const deleteheaders = {
        headers: null,
        body: httpParams.toString(),
      };
      this.sweetAlert.confirmMessage(this.lang.transform('lang_are_you_sure'), this.lang.transform('lang_yes'), this.lang.transform('lang_cancel')).then(res => {
        if(res?.isConfirmed) {
          this.spinner.show()
          this.ds.deleteActionByHeaders(deleteheaders, 'np/design/tracking/delete_manual_tracking_records').subscribe(
            (data:any) => {
              this.spinner.hide()
              if (data.status) {
                this.getTrackingReports();
                this.getTrackingReportsTotal();
                $(".tracking-reports #allItems").prop("checked", false);
                $(".deleteIconDiv").hide();
                this.sweetAlert.successToast(data.msg, 3000);
              } else {
                this.sweetAlert.errorToast(data.error, 3000);
              }
            },
            (error) => {
              this.sweetAlert.errorToast((error && error.error && error.error.error) ? error.error.error : this.lang.transform('lang_internal_server_error'), 3000);
              this.spinner.hide()
            }
          );
        }
      })
    } else {
      this.ds.dialogf();
    }
  }

}
