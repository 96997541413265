import { Component, OnInit, TemplateRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { environment } from "@env/environment";
import { FilterEmployeeCardMobComponent } from "../filter-card-mob/filter-card-mob.component";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConfirmationDialog } from "@shared/components/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from "@shared/components/alert/alert.service";

declare var $: any;

import moment from "moment";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

@Component({
  selector: "app-employees-page",
  templateUrl: "./employees-page.component.html",
  styleUrls: ["./employees-page.component.scss"],
})
export class EmployeesPageComponent implements OnInit {
  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  modalRefC: BsModalRef;
  signform: FormGroup;
  liststatus: boolean = false;
  errormessagef = this.lang.transform("lang_loading");
  myemployees: any = [];
  signchecked: any = false;
  branches: any = [];
  lltotal: any = "";
  lltotalpage: any = "";
  wholddata: any = [];
  signfilename = this.lang.transform("lang_select_files");
  markfilename = this.lang.transform("lang_select_files");
  signform_status: any = false;
  signerror: string = "";
  is_admin = JSON.parse(this.ls.getItem("is_admin"));

  page: any = localStorage.getItem("M_H_R_N") || 1;
  size: any = localStorage.getItem("M_H_R_S") || 10;

  searchKey = "";

  lang_key = localStorage.getItem("lang_key")
    ? localStorage.getItem("lang_key")
    : "en";

  showFilter = false;
  showSort = false;
  isMob = false;
  EmployeeData = [];
  imageUrl = environment.SERVER_ORIGIN;

  searchByBranch = localStorage.getItem("M_H_R_B") || "";

  pages: any = "pages";
  page_no = 1;
  page_size = 10;
  collection_size = 0;
  paginationMaxSize = 3;
  showExel = false;
  filters_params = {
    nationality_type: null,
    project_id: null,
    branch_id: null,
    national_id: null,
    employee_id: null,
    emp_user_id: null,
    employee_number: null,
    employee_name: null,
    nationality: null,
    position: null,
  };
  sort_params = {
    form_request_id: null,
    template_id: null,
    platform_code_system: null,
  };

  ////////////////////////  pending transactions variables  //////////////////////////////////

  is_empoyee_detailes: boolean = true;
  is_pending_transactions: boolean = false;
  pending_count: boolean = false;
  pending_page_size: number = 10;
  pending_page_no: number = 1;
  totalPenddingTransactions: number;
  pending_transactions: [];
  changeSignatureForm: FormGroup;
  nodata: string = this.lang.transform("lang_loading");
  ////////////////////////  pending transactions variables  //////////////////////////////////
  onlyNumbers(str) {
    return /^[0-9]+$/.test(str);
  }

  search(event) {
    if (!event) {
      this.filters_params = {
        ...this.filters_params,
        employee_id: null,
        employee_name: null,
      };
      this.getEmployeeData(this.filters_params, this.sort_params, false);
    }
    event = event.trim();

    if (event.length > 2) {
      if (this.onlyNumbers(event)) {
        this.filters_params.employee_id = event;
        this.getEmployeeData(this.filters_params, this.sort_params, false);
      } else {
        this.filters_params.employee_name = event;
        /* let obj= {
            employee_name:event
          } */
        this.getEmployeeData(this.filters_params, this.sort_params, false);
      }
    }
  }
  showFiller = false;
  is_tracking_reports = false;
  constructor(
    private ds: DataService,
    public alert: AlertService,
    private spinner: PreloaderService,
    public lang: LanguagePipe,
    public ls: LocalStorage,
    public dialog: MatDialog,
    public modalService: BsModalService,
    public sweetAlert: SweetAlertService
  ) {}

  ngOnInit() {
    this.get_pending_hr_transactions();
    moment.locale("en");
    if (window.innerWidth < 600) {
      this.isMob = true;
      this.paginationMaxSize = 1;
    } else {
      this.isMob = false;
    }
    document.addEventListener("keypress", (e) => {
      if (e.keyCode === 13 || e.which === 13) {
        this.search(this.searchKey);
      }
    });

    /*  localStorage.setItem('employee_filterParams', JSON.stringify(this.filters_params)); */

    /* if(this.filterParams['group_type_id']) {
      this.showExel = true;
    } else {
      this.showExel = false;
    } */
    this.spinner.show();
    this.getEmployeeData(this.filters_params, this.sort_params, false);
    this.getAllProjects();
    this.signatureform();
    this.loadbranches();
    this.get_advanced_search_meta_data();
  }

  ngAfterViewInit(): void {}

  public loadbranches() {
    this.branches = [];
    this.ds
      .getActionByUrl([], "human_resources/branches/human_resources_view")
      .subscribe((data) => {
        if (data.status) {
          this.branches = data.branches;
        }
      });
  }

  metaData = [];

  public get_advanced_search_meta_data() {
    this.metaData = [];
    this.ds
      .getActionByUrl([], "human_resources/get_advanced_search_meta_data")
      .subscribe((data) => {
        if (data.status) {
          this.metaData = data.records;
        }
      });
  }
  exportExcel() {
    this.getExcel(this.filters_params);
  }

  exportContracts() {
    this.spinner.show();
    this.ds.get("human_resources/get_employees_contract_filling").subscribe(
      (res) => {
        if (res?.status) {
          let url = "data:" + res["content-type"] + ";base64," + res.base64;
          this.spinner.hide();
          let downloadExcel = document.createElement("a");
          downloadExcel.style.display = "none";
          downloadExcel.href = url;
          downloadExcel.download = "Almnabr Contracts";
          document.body.appendChild(downloadExcel);
          downloadExcel.click();
          document.body.removeChild(downloadExcel);
        } else {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  uploadExcelContract(e) {
    if (e && e?.length) {
      let formData = new FormData();
      formData.append("contract_data_file", e[0] || "");
      this.spinner.show();
      this.ds
        .post("human_resources/post_employees_contract_filling", formData)
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res.status) {
              this.sweetAlert.successMessage(res?.msg);
            } else {
              this.sweetAlert.errorMessage(res?.error);
            }
          },
          (err) => {
            this.sweetAlert.errorMessage(err?.error?.error);
            this.spinner.hide();
          }
        );
    }
    document.getElementById("uploadExcelInput")["value"] = "";
  }

  toggleFilter() {
    this.showFilter = !this.showFilter;
    this.showSort = false;

    if (this.isMob) {
      const dialogRef = this.dialog.open(FilterEmployeeCardMobComponent, {
        width: "700px",
        data: {
          isMob: this.isMob,
          metaData: this.metaData,
          projects: this.projects,
          branches: this.branches,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.filters_params = result;
          this.getEmployeeData(this.filters_params, this.sort_params, false);
        }
      });
    }
  }
  view_profile_image;
  public get_profile_image(url) {
    this.view_profile_image = "";
    this.ds.getActionByUrl([], url).subscribe((data) => {
      if (data.status) {
        return (this.view_profile_image =
          "data:" + data["content-type"] + ";base64," + data.base64);
      }
    });
  }

  previewPDF(link: string) {
    this.ds.preview_pdf_file(link, "employee");
  }

  load_by_page_size(e) {
    if (e && e.value) {
      this.page_no = 1;
      this.getEmployeeData(this.getEmployeeData, this.sortParams, false);
    }
  }

  filterParams(e) {
    this.filters_params = e;
    this.getEmployeeData(this.filters_params, this.sort_params, false);
  }
  sortParams(e) {
    this.sort_params = e;
    this.getEmployeeData(this.filters_params, this.sort_params, false);
  }

  public onPageChange(pageNum: number): void {
    this.getEmployeeData(this.filters_params, this.sort_params, false);
  }
  public openModelC(template: TemplateRef<any>) {
    this.modalRefC = this.modalService.show(template, environment.modelconfig);
  }
  allEmpData = [];
  getEmployeeData(filters: any, sort: any, isExcel: boolean) {
    this.spinner.show();
    let formData = new FormData();
    if (filters?.project_id && filters?.project_id.length > 0) {
      filters?.project_id.forEach((element) => {
        formData.append("project_id[]", element || "");
      });
    }
    if (filters?.nationality && filters?.nationality.length > 0) {
      filters?.nationality.forEach((element) => {
        formData.append("nationality[]", element || "");
      });
    }
    if (filters?.branch_id && filters?.branch_id.length > 0) {
      filters?.branch_id.forEach((element) => {
        formData.append("branch_id[]", element || "");
      });
    }
    if (filters?.position && filters?.position.length > 0) {
      filters?.position.forEach((element) => {
        formData.append("position[]", element || "");
      });
    }
    if (filters?.employee_status && filters?.employee_status.length > 0) {
      filters?.employee_status.forEach((element) => {
        /* if (element == 'in_active'){
          element = 'inactive'
        } */
        formData.append("employee_status[]", element || "");
      });
    }

    moment.locale("en");
    if (filters?.iqama_expiry_date_from) {
      formData.append(
        "iqama_expiry_date_from",
        moment(filters?.iqama_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.iqama_expiry_date_to) {
      formData.append(
        "iqama_expiry_date_to",
        moment(filters?.iqama_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.passport_expiry_date_from) {
      formData.append(
        "passport_expiry_date_from",
        moment(filters?.passport_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.passport_expiry_date_to) {
      formData.append(
        "passport_expiry_date_to",
        moment(filters?.passport_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.membership_expiry_date_to) {
      formData.append(
        "membership_expiry_date_to",
        moment(filters?.membership_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.contract_expiry_date_from) {
      formData.append(
        "contract_expiry_date_from",
        moment(filters?.contract_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.membership_expiry_date_from) {
      formData.append(
        "membership_expiry_date_from",
        moment(filters?.membership_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.contract_expiry_date_to) {
      formData.append(
        "contract_expiry_date_to",
        moment(filters?.contract_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }

    if (filters?.nationality_type) {
      formData.append("nationality_type[]", filters?.nationality_type || "");
    }
    if (filters?.national_id) {
      formData.append("national_id[]", filters?.national_id || "");
    }
    if (filters?.employee_id) {
      formData.append("employee_id[]", filters?.employee_id || "");
    }
    if (filters?.employee_name) {
      formData.append("employee_name", filters?.employee_name || "");
    }
    if (filters?.emp_user_id) {
      formData.append("emp_user_id[]", filters?.emp_user_id || "");
    }
    if (filters?.employee_number) {
      formData.append("employee_number[]", filters?.employee_number || "");
    }

    this.ds
      .postActionByUrl(
        formData,
        `human_resources/employees_report/${this.page_no}/${this.page_size}`
      )
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.allEmpData = res;
            this.EmployeeData = res?.records == "" ? [] : res?.records;
            this.collection_size = res?.page?.total_records;
          } else {
            this.spinner.hide();
            this.EmployeeData = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.EmployeeData = [];
        }
      );
  }

  getExcel(filters: any) {
    this.spinner.show();
    let formData = new FormData();
    if (filters?.project_id && filters?.project_id.length > 0) {
      filters?.project_id.forEach((element) => {
        formData.append("project_id[]", element || "");
      });
    }
    if (filters?.nationality && filters?.nationality.length > 0) {
      filters?.nationality.forEach((element) => {
        formData.append("nationality[]", element || "");
      });
    }
    if (filters?.branch_id && filters?.branch_id.length > 0) {
      filters?.branch_id.forEach((element) => {
        formData.append("branch_id[]", element || "");
      });
    }
    if (filters?.position && filters?.position.length > 0) {
      filters?.position.forEach((element) => {
        formData.append("position[]", element || "");
      });
    }
    if (filters?.employee_status && filters?.employee_status.length > 0) {
      filters?.employee_status.forEach((element) => {
        if (element == "in_active") {
          element = "inactive";
        }
        formData.append("employee_status[]", element || "");
      });
    }
    moment.locale("en");
    if (filters?.iqama_expiry_date_from) {
      formData.append(
        "iqama_expiry_date_from",
        moment(filters?.iqama_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.iqama_expiry_date_to) {
      formData.append(
        "iqama_expiry_date_to",
        moment(filters?.iqama_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.passport_expiry_date_from) {
      formData.append(
        "passport_expiry_date_from",
        moment(filters?.passport_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.passport_expiry_date_to) {
      formData.append(
        "passport_expiry_date_to",
        moment(filters?.passport_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.membership_expiry_date_to) {
      formData.append(
        "membership_expiry_date_to",
        moment(filters?.membership_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.contract_expiry_date_from) {
      formData.append(
        "contract_expiry_date_from",
        moment(filters?.contract_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.membership_expiry_date_from) {
      formData.append(
        "membership_expiry_date_from",
        moment(filters?.membership_expiry_date_from).format("YYYY/MM/DD") || ""
      );
    }
    if (filters?.contract_expiry_date_to) {
      formData.append(
        "contract_expiry_date_to",
        moment(filters?.contract_expiry_date_to).format("YYYY/MM/DD") || ""
      );
    }

    if (filters?.nationality_type) {
      formData.append("nationality_type[]", filters?.nationality_type || "");
    }
    if (filters?.national_id) {
      formData.append("national_id[]", filters?.national_id || "");
    }
    if (filters?.employee_id) {
      formData.append("employee_id[]", filters?.employee_id || "");
    }
    if (filters?.employee_name) {
      formData.append("employee_name", filters?.employee_name || "");
    }
    if (filters?.emp_user_id) {
      formData.append("emp_user_id[]", filters?.emp_user_id || "");
    }
    if (filters?.employee_number) {
      formData.append("employee_number[]", filters?.employee_number || "");
    }

    this.ds
      .postActionByUrl(formData, `human_resources/employees_report_excel`)
      .subscribe(
        (res) => {
          if (res?.status) {
            let url = "data:" + res["content-type"] + ";base64," + res.base64;

            this.spinner.hide();
            let downloadExcel = document.createElement("a");
            downloadExcel.style.display = "none";
            downloadExcel.href = url;
            downloadExcel.download = "Almnabr Employees";
            document.body.appendChild(downloadExcel);
            downloadExcel.click();
            document.body.removeChild(downloadExcel);
            this.pages = "pages";
          } else {
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  projects = [];
  getAllProjects() {
    this.spinner.show();
    this.ds
      .getActionByUrl([], `human_resources/get_project_subjects`)
      .subscribe(
        (res) => {
          if (res?.status) {
            this.spinner.hide();
            this.projects = res.records;
          } else {
            this.spinner.hide();
            this.projects = [];
          }
        },
        (err) => {
          this.spinner.hide();
          this.projects = [];
        }
      );
  }

  public showsignature(empid, type, user_id) {
    this.spinner.show();
    this.ds.getActionByUrl([], "viewsmark/" + empid + type + user_id).subscribe(
      (data) => {
        this.spinner.hide();
        if (data.status && data.base64) {
          ($("#showSMPreview") as any).modal({
            backdrop: "static",
            keyboard: false,
          });
          ($("#showSMPreview") as any).modal("show");
          $("#SMnabrPdf").attr(
            "src",
            "data:" + data["content-type"] + ";base64," + data.base64
          );
        } else {
          this.ds.dialogf("", data.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.ds.dialogf(
          "",
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }

  public performStatus(employee_number, id, employee_id_number) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show();
        this.ds
          .getActionByUrl(
            [],
            "users/user_status_action/" + employee_number || employee_id_number
          )
          .subscribe(
            (data) => {
              this.spinner.hide();
              if (data.status) {
                this.getEmployeeData(
                  this.filters_params,
                  this.sort_params,
                  false
                );

                this.ds.dialogf("", data.msg);
              } else {
                this.ds.dialogf("", data.error);
              }
            },
            (error) => {
              this.ds.dialogf(
                "",
                error && error.error && error.error.error
                  ? error.error.error
                  : this.lang.transform("lang_internal_server_error")
              );
              this.spinner.hide();
            }
          );
      }
    });
  }
  public signaturefor(id, branch_id) {
    this.signform.reset();
    this.signfilename = this.lang.transform("lang_select_files");
    this.markfilename = this.lang.transform("lang_select_files");
    this.signform.patchValue({
      id: id,
      branch_id: branch_id,
    });
  }
  public signatureform() {
    this.signform = new FormGroup({
      id: new FormControl("", [Validators.required]),
      branch_id: new FormControl("", [Validators.required]),
      signature: new FormControl("", [Validators.required]),
      mark: new FormControl("", [Validators.required]),
    });
  }
  public uploadfile(event, sform, type) {
    if (
      event.target.files[0] &&
      event.target.files[0] !== undefined &&
      event.target.files[0].name
    ) {
      const fileid = event.target.files[0];
      if (type == "mark") {
        this.markfilename = fileid.name;
      } else {
        this.signfilename = fileid.name;
      }
      sform.setValue(fileid, { emitEvent: true });
    } else {
      sform.setValue(null);
      if (type == "mark") {
        this.markfilename = this.lang.transform("lang_select_files");
      } else {
        this.signfilename = this.lang.transform("lang_select_files");
      }
    }
  }
  public upload_signature() {
    this.spinner.show();
    this.ds
      .postActionByUrl(
        this.ds.json2formdata(this.signform.value),
        "upload_signature"
      )
      .subscribe(
        (res) => {
          this.spinner.hide();
          this.signform_status = res.status;
          if (res.status) {
            this.getEmployeeData(this.filters_params, this.sort_params, false);
            this.alert.success(res.msg);
            this.signform.reset();
            setTimeout(() => {
              this.modalRefC.hide();
            }, 1000);
          } else {
            this.alert.error(res.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.signform_status = false;
          this.alert.error(
            error.error + (error && error.error && error.error.error)
              ? error.error.error
              : this.lang.transform("lang_internal_server_error")
          );
        }
      );
  }
  public remove_signature(user_id) {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: true,
      width: "400px",
      data: {
        title: this.lang.transform("lang_confirmation"),
        message: this.lang.transform("lang_are_you_sure"),
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.spinner.show();
        this.ds.getActionByUrl([], "remove_signature/" + user_id).subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.getEmployeeData(
                this.filters_params,
                this.sort_params,
                false
              );
              this.ds.dialogf("", data.msg);
            } else {
              this.ds.dialogf("", data.error);
            }
          },
          (error) => {
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
            this.spinner.hide();
          }
        );
      }
    });
  }
  public send_signup_link(emp): void {
    this.spinner.show();
    let formdata = new FormData();
    formdata.append("branch_id", emp.branch_id);
    formdata.append("id", emp.employee_number);
    formdata.append("employee_id_number", emp.employee_id_number);
    this.ds.postActionByUrl(formdata, "send_signup_link").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.getEmployeeData(this.filters_params, this.sort_params, false);
          this.ds.dialogf("", res.msg);
        } else {
          this.ds.dialogf("", res.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.ds.dialogf(
          "",
          error.error + (error && error.error && error.error.error)
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  allSelected = false;
  public checkAllHR(checked) {
    this.allSelected = checked;
    if (checked) {
      $(".all_employees .eachItemHR").prop("checked", true);
      $(".all_employees .makeback").addClass("bground");
      $(".all_employees .deleteIconDiv").show();
    } else {
      $(".all_employees .eachItemHR").prop("checked", false);
      $(".all_employees .makeback").removeClass("bground");
      $(".all_employees .deleteIconDiv").hide();
    }
  }

  public checkEachItemHR(event) {
    if (event.target.checked) {
      const val = event.target.value;
      $(".all_employees .removerow" + val).addClass("bground");
    } else {
      const val = event.target.value;
      $(".all_employees .removerow" + val).removeClass("bground");
    }
    const checkedtotal = [];
    $(".all_employees .eachItemHR:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    const totalrow = [];
    $(".all_employees .eachItemHR").each(function () {
      totalrow.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      $(".all_employees .deleteIconDiv").show();
    } else {
      $(".all_employees .deleteIconDiv").hide();
    }
    if (checkedtotal.length === totalrow.length) {
      $(".all_employees #allItemsHR").prop("checked", true);
    } else {
      $(".all_employees #allItemsHR").prop("checked", false);
    }
  }
  public search_employees() {
    this.page_size = 10;
    this.page_no = 1;
    this.getEmployeeData(this.filters_params, this.sort_params, false);
  }
  public deleteBulkDataHR() {
    const checkedtotal = [];
    $(".all_employees .eachItemHR:checked").each(function () {
      checkedtotal.push($(this).val());
    });
    if (checkedtotal.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialog, {
        disableClose: true,
        width: "400px",
        data: {
          title: this.lang.transform("lang_confirmation"),
          message: this.lang.transform("lang_are_you_sure"),
        },
      });
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.spinner.show();
          this.ds
            .deleteActionByUrl(
              checkedtotal,
              "81f5cc8c046c6d1c66fa3424783873db/MAIN"
            )
            .subscribe(
              (data) => {
                this.spinner.hide();
                if (data.status) {
                  this.search_employees();
                  $(".all_employees #allItemsHR").prop("checked", false);
                  $(".all_employees .deleteIconDiv").hide();
                  this.ds.dialogf("", data.msg);
                } else {
                  this.ds.dialogf("", data.error);
                }
              },
              (error) => {
                this.ds.dialogf(
                  "",
                  error && error.error && error.error.error
                    ? error.error.error
                    : this.lang.transform("lang_internal_server_error")
                );
                this.spinner.hide();
              }
            );
        }
      });
    } else {
      this.ds.dialogf();
    }
  }

  //////////////////////////////// pending transactions functions ///////////////////////
  changeContent(type) {
    if (type == "pending") {
      this.is_empoyee_detailes = false;
      this.is_tracking_reports = false;
      this.is_pending_transactions = true;
    } else if(type == 'employee_details') {
      this.is_pending_transactions = false;
      this.is_tracking_reports = false;
      this.is_empoyee_detailes = true;
    } else if(type == 'tracking_reports') {
      this.is_pending_transactions = false;
      this.is_empoyee_detailes = false;
      this.is_tracking_reports = true;
    }
  }
  get_pending_hr_transactions() {
    this.spinner.show();
    this.ds
      .get("/human_resources/get_pending_hr_transactions", {
        count: `${this.pending_count}`,
        page_size: this.pending_page_size,
        page_no: this.pending_page_no,
      })
      .subscribe(
        (res) => {
          if (res?.status) {
            this.pending_transactions = res?.records;
            this.totalPenddingTransactions = res?.total;
            this.changeSignatureForm = new FormGroup({
              transaction_request_id: new FormControl(null, [
                Validators.required,
              ]),
              user_id: new FormControl(null, [Validators.required]),
            });
            this.spinner.hide();
          } else {
            this.sweetAlert.errorToast(res?.error, 2000);
            this.pending_transactions = [];
            this.nodata = res?.error;
            this.spinner.hide();
          }
        },
        (err) => {
          this.sweetAlert.errorToast(
            this.lang.transform("lang_internal_server_error"),
            2000
          );
          this.nodata =
            err && err.error && err.error.error
              ? err.error.error
              : this.lang.transform("lang_internal_server_error");
          this.pending_transactions = [];
        }
      );
  }
  submitForm = false;
  addUser(hide) {
    this.submitForm = true;
    if (this.changeSignatureForm.valid) {
      this.spinner.show();
      let formData = new FormData();
      formData.append("user_id", this.changeSignatureForm.value.user_id);
      formData.append(
        "transaction_request_id",
        this.changeSignatureForm.value.transaction_request_id
      );

      this.ds.post(`/human_resources/add_sign_person`, formData).subscribe(
        (data) => {
          if (data.status) {
            this.spinner.hide();
            this.alert.success(data.msg);
            this.get_pending_hr_transactions();
            this.submitForm = false;
            hide;
          } else {
            this.spinner.hide();
            this.alert.error(data?.error);
          }
        },
        (error) => {
          this.spinner.hide();
          this.alert.error(error);
        }
      );
    } else {
      this.alert.error(this.lang.transform("lang_all_fields_required"));
    }
  }
  users: any = [];
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term || "");
    param.append("lang_key", this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      });
  }
  public loadPageFrm(page) {
    this.pending_page_no = page;
    this.pending_page_size = this.pending_page_size;
    this.get_pending_hr_transactions();
  }
  public loadFrmPagesize(size) {
    this.pending_page_no = 1;
    this.pending_page_size = size;
    this.get_pending_hr_transactions();
  }
  //////////////////////////////// pending transactions functions ///////////////////////
}
