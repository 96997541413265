<div mat-dialog-title>
	<div class="d-flex justify-content-between">
		<h4>{{ "lang_add" | language }}</h4>
		<span mat-dialog-close><i class="fa fa-times"></i></span>
	</div>
</div>
<mat-dialog-content class="mat-typography">
	<form [formGroup]="form">
		<div class="p-2">
			<div class="row">
				<div class="col-12 mb-4" [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
					<ng-select
                            [items]="emailsList"
                            [multiple]="true"
                            bindLabel="label"
                            bindValue="value"
                            appendTo="body"
                            clearAllText="Clear"
                            (search)="searchUserEmail($event)"
                            [searchFn]="ds.customSearchFn"
                            formControlName="emails"
                            placeholder="{{
                            'lang_email' | language
                            }}"
                        ></ng-select>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
							<mat-label>{{ "lang_total_hours" | language }}</mat-label>
							<input
								matInput
								placeholder="{{ 'lang_total_hours' | language }}"
								type="number"
								min="0"
								formControlName="total_hours"
							/>
						</mat-form-field>
					</div>
				</div>
				<div class="col-12 col-sm-6 mb-2">
					<div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
						<mat-form-field appearance="outline" style="width: 100%">
                            <mat-label>{{ "lang_date" | language }}</mat-label>
                            <input matInput [matDatepicker]="dp" readonly formControlName="record_date" />
                        
                            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                            <mat-datepicker #dp></mat-datepicker>
                        </mat-form-field>
					</div>
				</div>
                <div class="col-12">
                    <div class="form-group">
						<label for="notes">{{ "lang_notes" | language }}</label>
						<textarea
							type="text"
							id="notes"
							class="form-control almnabrformcontrol"
							placeholder="{{ 'lang_notes' | language }}"
							formControlName="notes"
						></textarea>
					</div>
                </div>
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-between">
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			mat-dialog-close
		>
			{{ "lang_close" | language }}
		</button>
	</div>
	<div class="col-md-4 col-xs-12">
		<button
			type="button"
			class="albutton mnabrbutton mat-accent"
			mat-button
			(click)="addTrackingReport()"
		>
			{{ "lang_submit" | language }}
		</button>
	</div>
</mat-dialog-actions>
