import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { CalendarEvent, CalendarView } from "angular-calendar";
import { endOfDay, isSameDay, isSameMonth, startOfDay } from "date-fns";
import moment, * as Moment from "moment";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Observable, Subject, Subscription } from "rxjs";
import { DataService } from "@core/bootstrap/data.service";
import { MatDialog } from "@angular/material/dialog";
import { AlertService } from "@shared/components/alert/alert.service";
import { PreloaderService } from "@core";
import { LocalStorage } from "@core/bootstrap/localstorage.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmViolationComponent } from "./confirm-violation/confirm-violation.component";
import { environment } from "@env/environment";
import { SweetAlertService } from "@shared/services/sweet-alert.service";

export interface CalendarEvent2<MetaType = any> {
  id?: string | number;
  start: Date;
  end?: Date;
  title: string;
  color?: any;
  actions?: any[];
  allDay?: boolean;

  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  data?: any;
  draggable?: boolean;
  meta?: MetaType;
  total_hours?: any;
}

@Component({
  selector: "app-calender",
  templateUrl: "./calender.component.html",
  styleUrls: ["./calender.component.scss"],
})
export class CalenderComponent implements OnInit {
  emp_no: any;
  reason_date: string;
  @Input() fromProfile: boolean;
  user_company_email = localStorage.getItem('user_company_email') || '';
  trackingForm: FormGroup;
  @ViewChild('addTrackingReportDialog') addTrackingReportDialog:any;
  addTrackingDialog;
  constructor(
    public ds: DataService,
    public lang: LanguagePipe,
    private snackBar: MatSnackBar,
    public ls: LocalStorage,
    public fb: FormBuilder,
    public spinner: PreloaderService,
    public alert: AlertService,
    public dialog: MatDialog,
    public modalService: BsModalService,
    private sweetAlert: SweetAlertService
  ) {}
  @Output() month = new EventEmitter<string>();
  @Output() year = new EventEmitter<string>();
  startDate: any;
  endDate: any;
  shiftStatus: any;
  shiftData: any;
  shiftData3: any;
  modalRef: BsModalRef;
  modalRefV: BsModalRef;
  modalRefA: BsModalRef;
  pageC = 1;
  sizeC = 10;
  pageNo = 1;
  pageSize = 10;
  updateShiftUser: FormArray;
  shfitUserFG: FormGroup;
  form: FormGroup;
  filename: string = this.lang.transform("lang_select_files");
  time = { hour: 13, minute: 30 };
  @Input() isHRView: boolean = true;
  @Input() refresh2!: Observable<void>;
  @Input() isShowTotalHours: boolean;
  ratioWeek = [];
  ratioMonth;
  topEmps = [];
  trackingReports = [];
  trackingDetails = [];
  selectedDate: any;
  trackingRes: any;
  ngOnInit(): void {
    moment.locale("en");
    Moment.locale("en");
    if (this.singleUser == true) {
      this.searchForm.patchValue({
        employee_number: [this.userInfo.user_id],
      });
    }
    this.LoadBookingsForCalendar(this.from_to);

    if (this.refresh2) {
      this.subscriptions.add(
        this.refresh2.subscribe(() =>
          this.LoadBookingsForCalendar(this.from_to)
        )
      );
    }
    this.build_form();
  }

  @Input() singleUser: boolean = false;
  @Input() userInfo!: any;
  onChangeHour(event) {}
  pcapital(event) {}
  showMsg(msg: string, action: string, className: string) {
    this.snackBar.open(msg, action, {
      duration: 4000,
      panelClass: [className],
    });
  }

  refresh: Subject<any> = new Subject();
  viewDate: Date = new Date();

  events: any[] = [];

  activeDayIsOpen: boolean = false;
  view: CalendarView = CalendarView.Month;

  dayClicked({ date, events }: { date: Date; events: any[] }): void {
    this.selectedDate = date;
    if (isSameMonth(date, this.viewDate)) {
      if (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        let isHasTotalHours = this.trackingReports?.find(
          (el) => el?.date == Moment(date).format("YYYY-MM-DD")
        );
        if (isHasTotalHours) {
          this.getTrackingDetails(date);
        } else {
          this.trackingDetails = [];
        }
      }
      this.viewDate = date;
    }
  }
  showdate() {
    this.year.emit(`${this.viewDate.getFullYear()}`);
    this.month.emit(`${this.viewDate.getMonth() + 1}`);
  }
  from_to = {
    startDate: Moment(this.viewDate).locale("en").startOf("month").format(),
    endDate: Moment(this.viewDate).locale("en").endOf("month").format(),
  };

  closeOpenMonthViewDay(status: string) {
    this.from_to = {
      startDate: Moment(this.viewDate).locale("en").startOf("month").format(),
      endDate: Moment(this.viewDate).locale("en").endOf("month").format(),
    };
    this.LoadBookingsForCalendar(this.from_to);
  }

  searchForm = this.fb.group({
    employee_number: [[]],
  });

  lang_key =
    localStorage.getItem("lang_key") == null
      ? "en"
      : localStorage.getItem("lang_key");

  users: any = [];

  search(e) {
    this.LoadBookingsForCalendar(this.from_to);
  }
  public search_users(key) {
    this.users = [];
    let param = new FormData();
    param.append("search", key.term || "");
    param.append("lang_key", this.lang_key);
    param.append("user_type_id", "1");
    this.ds
      .getActionByUrl(this.ds.formData2string(param), "tc/getformuserslist")
      .subscribe((res) => {
        this.spinner.hide();
        if (res.status) {
          this.users = res.list;
        }
      });
  }

  private subscriptions = new Subscription();

  currentUser: any;
  userType = "";
  serverEvents: Event[] | any = [];
  CalendarView = CalendarView;

  errormessagef = this.lang.transform("lang_loading");
  actions: any[] = [];

  LoadBookingsForCalendar(
    from_to: any,
    usersArr: any = this.searchForm.value.employee_number
  ) {
    this.activeDayIsOpen = false;
    let url = `?from_year=${Moment(from_to.startDate).format(
      "YYYY"
    )}&from_month=${Moment(from_to.startDate).format("MM")}&from_day=${Moment(
      from_to.startDate
    ).format("DD")}&to_year=${Moment(from_to.endDate).format(
      "YYYY"
    )}&to_month=${Moment(from_to.endDate).format("MM")}&to_day=${Moment(
      from_to.endDate
    ).format("DD")}`;
    for (let index = 0; index < usersArr.length; index++) {
      const element = usersArr[index];
      url += `&employee_number[]=${element}`;
    }
    this.ds
      .getActionByUrl([], "at/getnewreport/" + this.pageC + "/" + 1000 + url)
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.status) {
            this.events = [];
            for (const property in data.data.log) {
              let value = data.data.log[property];
              let valueArr = [];
              for (const p in value) {
                if (value[p].length) {
                  valueArr.push({
                    title: p,
                    value: value[p],
                  });
                }
              }
              let calEvent: any = {
                start: startOfDay(
                  new Date(Moment(property, "YYYY/MM/DD").format("MM/DD/YYYY"))
                ),
                end: endOfDay(
                  new Date(Moment(property, "YYYY/MM/DD").format("MM/DD/YYYY"))
                ),

                title: "",
                actions: this.actions,
                data: valueArr,
              };
              this.events.push(calEvent);
            }
            if (this.isShowTotalHours) {
              this.getTrackingReports();
            }
          } else {
            this.events = [];
            this.errormessagef = data.error;
          }
        },
        (error) => {
          this.spinner.hide();
          this.events = [];
          this.errormessagef =
            error && error.error && error.error.error
              ? error.error.error
              : this.lang.transform("lang_internal_server_error");
        }
      );
  }
  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, environment.modelconfig);
  }
  openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  openModalA(template: TemplateRef<any>) {
    this.modalRefA = this.modalService.show(template, environment.modelconfig);
  }
  loadShiftData(setting_id, viewtemplate, event) {
    this.spinner.show();
    let formData = new FormData();
    formData.append("date", moment(event?.start).format("YYYY/MM/DD"));
    formData.append("user_id", setting_id);
    this.ds.postActionByUrl(formData, "at/attendance_detaile").subscribe(
      (response) => {
        this.shiftStatus = response?.status;
        this.spinner.hide();
        if (response?.status) {
          this.openModalV(viewtemplate);
          this.shiftData = {
            ...response.data,
            date: moment(event?.start).format("YYYY/MM/DD"),
          };
        } else {
          this.ds.dialogf("", response.msg);
        }
      },
      (error) => {
        this.spinner.hide();
        this.ds.dialogf(
          "",
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  loadShiftData3(setting_id, viewtemplate, event) {
    this.spinner.show();
    let formData = new FormData();
    formData.append("date", moment(event?.start).format("YYYY/MM/DD"));
    formData.append("employee_number", setting_id);
    this.ds.post("at/get_all_logs", formData).subscribe(
      (response) => {
        this.spinner.hide();
        if (response?.status) {
          this.shiftData3 = response.data;
        } else {
          this.shiftData3 = [];
        }
      },
      (error) => {
        this.spinner.hide();
        this.shiftData3 = [];
      }
    );
  }

  loadRatioWeek(emp_no, viewtemplate, event) {
    this.spinner.show();
    let formData = new FormData();
    formData.append("date", moment(event?.start).format("YYYY/MM/DD") || "");
    formData.append("employee_number", emp_no);
    this.ds.post("at/ratio_week", formData).subscribe(
      (response) => {
        this.spinner.hide();
        if (response?.status) {
          this.ratioWeek = response.data;
          this.openModalV(viewtemplate);
        } else {
          this.ratioWeek = [];
        }
      },
      (error) => {
        this.spinner.hide();
        this.ratioWeek = [];
      }
    );
  }
  loadRatioMonth(emp_no, emp_name, viewtemplate, event) {
    this.spinner.show();
    let formData = new FormData();
    formData.append("date", moment(event?.start).format("YYYY/MM/DD") || "");
    formData.append("employee_number", emp_no);
    this.ds.post("at/ratio_month", formData).subscribe(
      (response) => {
        this.spinner.hide();
        if (response?.status) {
          this.ratioMonth = {
            ratio: response.ratio_month,
            employee_number: emp_no,
            employee_name: emp_name,
          };
          this.openModalV(viewtemplate);
        } else {
          this.ratioMonth = null;
        }
      },
      (error) => {
        this.spinner.hide();
        this.ratioMonth = null;
      }
    );
  }
  violationForm = this.fb.group({
    violations: this.fb.array([]),
    cancel_note: [],
    direct_manager: [""],
  });
  addViolation(item, event, type) {
    this.spinner.show();
    let from = moment(event.start);
    let to = moment(event.start).add(1, "day");
    let url = `at/getViolations/1/10?from_year=${from.format(
      "YYYY"
    )}&from_month=${from.format("MM")}&from_day=${from.format(
      "DD"
    )}&to_year=${to.format("YYYY")}&to_month=${to.format(
      "MM"
    )}&to_day=${to.format("DD")}&employee_number[]=${item.employee_number}`;
    this.ds.getActionByUrl([], url).subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.violationForm.reset();
          let violations = this.violationForm.controls.violations as FormArray;
          violations.clear();
          for (let index = 0; index < res.records.length; index++) {
            const element = res.records[index];
            let violations = this.violationForm.controls
              .violations as FormArray;
            violations.push(
              this.fb.group({
                violation_log_id: element.id,
              })
            );
          }
          const dialogRef = this.dialog.open(ConfirmViolationComponent, {
            width: "700px",
            data: {
              type: type,
            },
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result.sendRequest) {
              if (type == "confirm") {
                this.violationForm.patchValue({
                  direct_manager: result.direct_manager,
                });
                this.add_violaion(this.violationForm.value);
              } else {
                this.violationForm.patchValue({
                  cancel_note: result.cancel_note,
                });
                this.cancelViolation(this.violationForm.value);
              }
            }
          });
        } else {
          this.showMsg(res?.error, "ok", "error-snackbar");
        }
      },
      (error) => {
        this.spinner.hide();
        this.showMsg(error?.error, "ok", "error-snackbar");
      }
    );
  }

  public add_violaion(formValue) {
    let param = new FormData();
    this.spinner.show();
    for (let index = 0; index < formValue.violations.length; index++) {
      const element = formValue.violations[index];
      if (element.violation_log_id) {
        param.append("violation_log_id[]", element.violation_log_id);
      }
    }

    param.append("direct_manager", formValue.direct_manager);

    this.ds.postActionByUrl(param, "form/FORM_VOL1/amv/").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.showMsg(res?.message, "ok", "success-snackbar");
        } else {
          this.showMsg(res?.error, "ok", "error-snackbar");
        }
      },
      (error) => {
        this.spinner.hide();
        this.showMsg(error?.error, "ok", "error-snackbar");
      }
    );
  }

  public cancelViolation(formValue) {
    let param = new FormData();
    this.spinner.show();
    for (let index = 0; index < formValue.violations.length; index++) {
      const element = formValue.violations[index];
      if (element.violation_log_id) {
        param.append("violation_log_id[]", element.violation_log_id);
      }
    }
    if (formValue.cancel_note) {
      param.append("cancel_note", formValue.cancel_note);
    }

    this.ds.postActionByUrl(param, "form/FORM_VOL1/cancelViolation/").subscribe(
      (res) => {
        this.spinner.hide();
        if (res.status) {
          this.showMsg(res?.message, "ok", "success-snackbar");
        } else {
          this.showMsg(res?.error, "ok", "error-snackbar");
        }
      },
      (error) => {
        this.spinner.hide();
        this.showMsg(error?.error, "ok", "error-snackbar");
      }
    );
  }

  //#region FormArray Update Shift
  shiftUser(value?): FormGroup {
    return this.fb.group({
      user: new FormControl(value?.user || null),
      date: new FormControl(value?.date || null),
    });
  }
  addShiftUser(value?) {
    if (this.updateShiftUser.length < 10) {
      this.updateShiftUser.push(this.shiftUser(value));
      this.updateShiftUser.setValidators(Validators.required);
      this.updateShiftUser.updateValueAndValidity();
    }
  }
  removeShiftUser(index) {
    if (this.updateShiftUser.length == 1) {
      this.updateShiftUser.removeAt(index);
      this.updateShiftUser.clearValidators();
      this.updateShiftUser.updateValueAndValidity();
    } else {
      this.updateShiftUser.removeAt(index);
    }
  }
  get shiftUserFormGroup() {
    return this.shfitUserFG.get("shiftUser") as FormArray;
  }
  //#endregion
  onShowTop(temp) {
    this.spinner.show();
    let formData = new FormData();
    formData.append("date", moment(this.viewDate).format("YYYY/MM/DD") || "");
    formData.append("type", "0");
    this.ds.post("at/get_top", formData).subscribe(
      (response) => {
        this.spinner.hide();
        if (response?.status) {
          this.topEmps = response.data;
          this.openModalV(temp);
        } else {
          this.topEmps = [];
          this.alert.error(response?.error);
        }
      },
      (error) => {
        this.spinner.hide();
        this.topEmps = [];
      }
    );
  }

  onChangeInOut(item) {
    let formData = new FormData();
    formData.append("employee_number", this.shiftData?.employee_number || "");
    formData.append("attendance_logs_id", item?.attendance_logs_id || "");
    formData.append("date", this.shiftData?.date || "");
    this.spinner.show();
    this.ds.post("at/option_in_out", formData).subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.sweetAlert.successToast(
            this.lang.transform("lang_success"),
            3000
          );
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (err) => {
        this.spinner.hide();
        this.sweetAlert.errorToast(
          this.lang.transform("lang_internal_server_error"),
          3000
        );
      }
    );
  }
  public build_form() {
    this.form = new FormGroup({
      text: new FormControl("", [Validators.required]),
    });
    this.form.addControl(
      "attachment[file]",
      new FormControl("", Validators.required)
    );
    this.form.addControl(
      "attachment[title]",
      new FormControl("", Validators.required)
    );
    this.trackingForm = this.fb.group({
			emails: [this.user_company_email, Validators.required],
			total_hours: [null, [Validators.required]],
			record_date: [null, [Validators.required]],
			notes: [''],
		});
  }
  add_reason_data(emp_no, event) {
    this.emp_no = emp_no;
    this.reason_date = moment(event?.start).format("YYYY/MM/DD");
  }
  public addReason() {
    this.spinner.show();
    let formData = new FormData();
    formData.append("employee_number", this.emp_no || "");
    formData.append("text", this.form.get("text").value);
    formData.append("date[0]", this.reason_date || "");
    formData.append(
      "attachment[file]",
      this.form.get("attachment[file]").value
    );
    formData.append(
      "attachment[title]",
      this.form.get("attachment[title]").value
    );
    this.ds.postActionByUrl(formData, "at/add_reason").subscribe(
      (res) => {
        this.spinner.hide();
        if (res?.status) {
          this.sweetAlert.successToast(
            this.lang.transform("lang_success"),
            3000
          );
          this.modalRefA.hide();
        } else {
          this.sweetAlert.errorToast(res?.error, 3000);
        }
      },
      (error) => {
        this.spinner.hide();
        this.ds.dialogf(
          "",
          error && error.error && error.error.error
            ? error.error.error
            : this.lang.transform("lang_internal_server_error")
        );
      }
    );
  }
  public getTrackingReports() {
    let param = new FormData();
    param.append("user_id", this.userInfo.user_id || "");
    param.append("year", Moment(this.from_to.startDate).format("YYYY") || "");
    param.append("month", Moment(this.from_to.startDate).format("MM") || "");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "np/design/tracking/tracking_reports"
        )
        .subscribe(
          (data) => {
            if (data.status) {
              this.trackingReports = data.records;
              for (let item of this.trackingReports) {
                for (let i = 0; i < this.events?.length; i++) {
                  if (
                    Moment(item?.date).format("YYYY/MM/DD") ==
                    Moment(this.events[i]?.start)?.format("YYYY/MM/DD")
                  ) {
                    this.events[i] = {
                      ...this.events[i],
                      total_hours: item?.total_hours,
                    };
                  }
                }
              }
              this.refresh.next();
            }
          },
          (error) => {
            this.spinner.hide();
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }
  public getTrackingDetails(date: any) {
    this.spinner.show();
    let param = new FormData();
    param.append("user_id", this.userInfo.user_id || "");
    param.append("date", Moment(date).format("YYYY-MM-DD") || "");
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          "np/design/tracking/tracking_reports_details/" +
            this.pageNo +
            "/" +
            this.pageSize
        )
        .subscribe(
          (data) => {
            this.spinner.hide();
            if (data.status) {
              this.trackingDetails = data.records;
              this.trackingRes = data;
              this.refresh.next();
            } else {
              this.trackingDetails = [];
            }
          },
          (error) => {
            this.spinner.hide();
            this.trackingDetails = [];
            this.ds.dialogf(
              "",
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error")
            );
          }
        )
    );
  }

  public loadpagerec(page) {
    this.spinner.show();
    this.pageNo = page;
    this.pageSize = this.pageSize;
    this.getTrackingDetails(this.selectedDate);
  }
  public loadrecpagesize(size) {
    this.spinner.show();
    this.pageNo = 1;
    this.pageSize = size;
    this.getTrackingDetails(this.selectedDate);
  }

  onAddTrackingReport() {
    this.addTrackingDialog = this.dialog.open(this.addTrackingReportDialog, {
      autoFocus: false
    })

    this.addTrackingDialog.afterClosed().subscribe(res => {
      this.trackingForm.reset();
    })
  }

  addTrackingReport() {
		this.spinner.show();
    this.trackingForm.get('record_date')?.setValue(this.viewDate);
    this.trackingForm.get('emails')?.setValue(this.user_company_email);
		let param = new FormData();
		param.append(
			"emails",
			this.trackingForm.value.emails
		);
		param.append(
			"total_hours",
			this.trackingForm.value.total_hours
		);
		param.append(
			"record_date",
			this.trackingForm.value.record_date
		);
		param.append(
			"notes",
			this.trackingForm.value.notes
		);
		this.ds.post('np/design/tracking/create_manual_tracking_records', param).subscribe(
			(res) => {
				this.spinner.hide();
				if (res.status) {
					this.sweetAlert.successToast(
						this.lang.transform("lang_success"),
						2000
					);
					this.addTrackingDialog.close(true);
          this.getTrackingReports();
          this.getTrackingDetails(this.viewDate);
          this.trackingForm.reset();
				} else {
					this.sweetAlert.errorToast(res.error, 2000);
				}
			},
			(err) => {
				this.spinner.hide();
				this.sweetAlert.errorToast(
					this.lang.transform("lang_internal_server_error"),
					2000
				);
			}
		);
	}
}
