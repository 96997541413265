import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HumanresourcesRoutingModule } from "./humanresources-routing.module";

import { ApplicationsComponent } from "./applications/applications.component";
import { SharedModule } from "@shared/shared.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { SettingsComponent } from "./settings/settings.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { CreateComponent } from "./create/create.component";
import { Step1Component } from "./steps/step1/step1.component";
import { Step2Component } from "./steps/step2/step2.component";
import { Step3Component } from "./steps/step3/step3.component";
import { Step4Component } from "./steps/step4/step4.component";
import { Step5Component } from "./steps/step5/step5.component";
import { Step6Component } from "./steps/step6/step6.component";
import { Step7Component } from "./steps/step7/step7.component";
import { EeditComponent } from "./eedit/eedit.component";
import { EviewComponent } from "./eview/eview.component";
import { PositionsComponent } from "./panels/positions/positions.component";
import { AttachmentsComponent } from "./panels/attachments/attachments.component";
import { ContactsComponent } from "./panels/contacts/contacts.component";
import { ContractsComponent } from "./panels/contracts/contracts.component";
import { EducationComponent } from "./panels/education/education.component";
import { InsuranceComponent } from "./panels/insurance/insurance.component";
import { NotesComponent } from "./panels/notes/notes.component";
import { ApplicationviewComponent } from "./applicationview/applicationview.component";

import { ModulesComponent } from "./panels/modules/modules.component";
import { JoiningComponent } from "./panels/joining/joining.component";
import { VactionComponent } from "./panels/vaction/vaction.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ViewVactionComponent } from "./panels/vaction/view-vaction/view-vaction.component";
import { FinanialDetialsComponent } from "./panels/finanial-details/finanial-details.component";
import { AllEmployeesComponent } from "./employees/all-employees/AllEmployees.component";
import { FilterEmployeeCardComponent } from "./employees/all-employees/filter-card/filter-card.component";
import { FilterEmployeeCardMobComponent } from "./employees/all-employees/filter-card-mob/filter-card-mob.component";
import { CommunicationComponent } from "./panels/communication/communication.component";

import { AttendanceComponent } from "./employees/all-employees/attendance/attendance.component";
import { AddGroupComponent } from "./employees/all-employees/attendance/add-group/add-group.component";
import { AddShifComponent } from "./employees/all-employees/attendance/shifts/add-shift/add-shift.component";
import { AllShiftsComponent } from "./employees/all-employees/attendance/shifts/all-shifts/all-shifts.component";
import { AddSingleShiftComponent } from "./employees/all-employees/attendance/shifts/add-shift/add-single-shift/add-single-shift.component";
import { AddShiftNameComponent } from "./employees/all-employees/attendance/shifts/add-shift/add-shift-name/add-shift-name.component";
import { AttendanceUploadComponent } from "./employees/all-employees/attendance/attendance-upload/attendance-upload.component";
import { NgxEchartsModule } from "ngx-echarts";
import { ChartDataInformationComponent } from "./employees/all-employees/chart-data-information/chart-data-information.component";

import { TimelineCalenderComponent } from "./employees/all-employees/timeline-calender/timeline-calender.component";
import { EmployeesPageComponent } from "./employees/all-employees/employees-page/employees-page.component"; // a plugin!
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MbscModule } from "@mobiscroll/angular";
import { ConfirmViolationComponent } from "./employees/calender/confirm-violation/confirm-violation.component";
import { JopOfferCreateComponent } from "./applications/jop-offer-create/jop-offer-create.component";
import { MatStepperModule } from "@angular/material/stepper";
import { CreateEPPComponent } from "../transactions/FORM_EPP/create/create_epp.component";
import { NgxMultipleDatesModule } from "ngx-multiple-dates";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { ExtendPeriodListComponent } from "./panels/extend-period-list/extend-period-list.component";
import { UserWorkareaModalComponent } from "./employees/all-employees/user-workarea-modal/user-workarea-modal.component";
import { CalendarReportComponent } from "./employees/all-employees/attendance/calendar-report/calendar-report.component";
import { TableModule } from "primeng/table";
import { ButtonModule } from "primeng/button";
import { FinanceReportComponent } from "./panels/finance-report/finance-report.component";
import { HrProjectsComponent } from "./panels/hr-projects/hr-projects.component";
import { HrWhatsappComponent } from "./panels/hr-whatsapp/hr-whatsapp.component";
import { ViolationDeductionComponent } from './employees/all-employees/attendance/violation-deduction/violation-deduction.component';
import { SelectEmployeeComponent } from './employees/all-employees/attendance/violation-deduction/select-employee/select-employee.component';
import { AddHollidaysComponent } from './employees/all-employees/attendance/shifts/add-shift/add-hollidays/add-hollidays.component';
import { DevicesComponent } from './employees/all-employees/attendance/devices/devices/devices.component';
import { AddDeviceComponent } from './employees/all-employees/attendance/devices/add-device/add-device.component';
import { DeviceAttendanceLogsComponent } from './employees/all-employees/attendance/devices/device-attendance-logs/device-attendance-logs.component';
import { TransactionsModule } from "../transactions/transactions.module";
import { TrackingReportsListComponent } from './tracking-reports-list/tracking-reports-list.component';
import { TrackingReportsAddComponent } from './tracking-reports-list/tracking-reports-add/tracking-reports-add.component';
import { DeviceRecordsComponent } from "./employees/all-employees/attendance/device-records/device-records.component";

const COMPONENTS = [
  CreateEPPComponent,
  HrProjectsComponent,
  JopOfferCreateComponent,
  AttendanceUploadComponent,
  ConfirmViolationComponent,
  AddSingleShiftComponent,
  AddShifComponent,
  FilterEmployeeCardMobComponent,
  FilterEmployeeCardComponent,
  AllEmployeesComponent,
  FinanialDetialsComponent,
  ApplicationsComponent,
  SettingsComponent,
  NotificationsComponent,
  CreateComponent,
  Step1Component,
  Step2Component,
  Step3Component,
  Step4Component,
  Step5Component,
  Step6Component,
  Step7Component,
  PositionsComponent,
  EeditComponent,
  EviewComponent,
  AttachmentsComponent,
  ContactsComponent,
  ContractsComponent,
  EducationComponent,
  InsuranceComponent,
  NotesComponent,
  ApplicationviewComponent,
  ModulesComponent,
  JoiningComponent,
  VactionComponent,
  CommunicationComponent,
];
const COMPONENTS_DYNAMIC = [];
@NgModule({
  imports: [
    SharedModule,
    MatFormFieldModule,
    MatStepperModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    MbscModule,
    NgxMaterialTimepickerModule,
    MatInputModule,
    HumanresourcesRoutingModule,

    ModalModule.forRoot(),
    NgxMultipleDatesModule,
    MatNativeDateModule, // any of the supported date adapter should be imported
    MatDatepickerModule,
    TableModule,
    ButtonModule,
    TransactionsModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_DYNAMIC,
    HrWhatsappComponent,
    ViewVactionComponent,
    AttendanceComponent,
    AddGroupComponent,
    AllShiftsComponent,
    AddShiftNameComponent,
    ChartDataInformationComponent,
    TimelineCalenderComponent,
    EmployeesPageComponent,
    ExtendPeriodListComponent,
    UserWorkareaModalComponent,
    CalendarReportComponent,
    FinanceReportComponent,
    HrProjectsComponent,
    ViolationDeductionComponent,
    SelectEmployeeComponent,
    AddHollidaysComponent,
    DevicesComponent,
    AddDeviceComponent,
    DeviceAttendanceLogsComponent,
    TrackingReportsListComponent,
    TrackingReportsAddComponent,
    DeviceRecordsComponent
  ],
  entryComponents: COMPONENTS_DYNAMIC,
  exports: [
    ...COMPONENTS,
    ...COMPONENTS_DYNAMIC,
    MatFormFieldModule,
    MatInputModule,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
})
export class HumanresourcesModule {}
