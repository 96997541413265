import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { PreloaderService } from "@core";
import { DataService } from "@core/bootstrap/data.service";
import { LanguagePipe } from "@shared/pipes/language.pipe";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { environment } from "@env/environment";
import { Subscription } from "rxjs";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import {
  MatRangeDateSelectionModel,
  MatDatepicker,
} from "@angular/material/datepicker";
import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";
import { FormControl } from "@angular/forms";
import { DecimalPipe } from "@angular/common";

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-calendar-report",
  templateUrl: "./calendar-report.component.html",
  styleUrls: ["./calendar-report.component.scss"],
  providers: [
    MatRangeDateSelectionModel,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CalendarReportComponent implements OnInit {
  @Input() year;
  @Input() month;

  calendarReport = [];
  viewdata = [];
  modalRefV: BsModalRef;
  page_no = 1;
  page_size = "10";
  total_records;
  lodingdatas = this.lang.transform("lang_loading");
  lang_key = localStorage.getItem("lang_key") || "en";
  requestfor: string;
  elveiw: any;
  ename: any;
  enumber: any;
  date = new Date();
  constructor(
    public modalService: BsModalService,
    private ds: DataService,
    private spinner: PreloaderService,
    private lang: LanguagePipe,
    private _decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {
    // this.getAttendanceReport();
  }
  private subscriptions = new Subscription();
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  getAttendanceReport() {
    this.spinner.show();
    let param = new FormData();
    param.append(
      "month",
      this._decimalPipe.transform(this.month, "2.0-0") ||
        this._decimalPipe.transform(this.date.getMonth() + 1, "2.0-0")
    );
    param.append("year", this.year || this.date.getFullYear());
    this.subscriptions.add(
      this.ds
        .getActionByUrl(
          this.ds.formData2string(param),
          `at/attendance_report/${this.page_no}/${this.page_size}`
        )
        .subscribe(
          (res) => {
            this.spinner.hide();
            if (res?.status) {
              this.calendarReport = res?.records;
              this.total_records = res?.page?.total_records;
            } else {
              this.calendarReport = [];
              this.total_records = 0;
              this.lodingdatas =
                res.error || this.lang.transform("lang_no_data");
            }
          },
          (error) => {
            this.spinner.hide();
            this.calendarReport = [];
            this.total_records = 0;
            this.lodingdatas =
              error && error.error && error.error.error
                ? error.error.error
                : this.lang.transform("lang_internal_server_error");
          }
        )
    );
  }
  public loadContract(el, enumber, ename, template) {
    this.openModalV(template);
    this.elveiw = el;
    this.ename = ename;
    this.enumber = enumber;
  }
  public openModalV(template: TemplateRef<any>) {
    this.modalRefV = this.modalService.show(template, environment.modelconfig);
  }
  date2 = new FormControl(moment());

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date2.value!;
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.date2.setValue(ctrlValue);
    this.year = ctrlValue.year();
    this.month = ctrlValue.month() + 1;
    this.getAttendanceReport();
    datepicker.close();
  }
}
