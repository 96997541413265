<div class="row">
  <div class="col-md-12">
    <div class="row" *ngIf="!singleUser">
      <div class="col-md-6 col-xs-12 col-sm-12 mb-10">
        <form [formGroup]="searchForm" class="w-100">
          <label class="mb-10">
            {{ "lang_users" | language }}
          </label>

          <ng-select
            [items]="users"
            [multiple]="true"
            bindLabel="label"
            bindValue="value"
            clearAllText="Clear"
            appendTo="body"
            formControlName="employee_number"
            placeholder="{{ 'lang_users' | language }}"
            (search)="search_users($event)"
            (change)="search($event)"
            [searchFn]="ds.customSearchFn"
          >
          </ng-select>
        </form>
      </div>

      <div
        class="col-md-6 col-xs-12 col-sm-12 mb-10 d-flex justify-content-end align-items-center"
      >
        <div (click)="onShowTop(topTemp)" class="top-btn">
          <span>{{ "lang_top" | language }}</span>
          <mat-icon>vertical_align_top</mat-icon>
        </div>
      </div>

      <hr />
    </div>

    <div class="View-title row col-md-12 m-auto p-0">
      <div class="col-12 col-sm-12 mb-2 col-md-4 p-0 ms-auto">
        <button
          mwlCalendarPreviousView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay('previous')"
          class="albutton mnabrbutton"
          (click)="showdate()"
          mat-button
        >
          {{ "lang_previous" | language }}
        </button>
      </div>

      <div class="col-12 col-md-4 col-sm-12 text-center">
        <h3 style="padding: 5px 60px">
          {{ viewDate | calendarDate: view + "ViewTitle":"en" }}
        </h3>
      </div>

      <div class="col-12 col-sm-12 col-md-4 mb-2 p-0 ms-auto">
        <button
          mwlCalendarNextView
          [view]="view"
          [(viewDate)]="viewDate"
          (viewDateChange)="closeOpenMonthViewDay('next')"
          class="albutton mnabrbutton"
          mat-button
          (click)="showdate()"
        >
          {{ "lang_next" | language }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row my-3">
  <div class="col-12 booking-pages">
    <div
      class="Calendar-view"
      [ngClass]="{ 'backgorund-calender': activeDayIsOpen }"
    >
      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [openDayEventsTemplate]="monthOpenDayEventsTemplate"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        [cellTemplate]="monthCellTemplate"
      >
        {{ "Month" | translate }}
      </mwl-calendar-month-view>
    </div>
  </div>
</div>

<ng-template #monthCellTemplate let-day="day" let-locale="locale">
  <span
    class="cal-day-number"
    style="letter-spacing: 0px; color: #5c606b !important"
    >{{ day.date | date: "dd" }}
  </span>

  <ng-container *ngFor="let item of day.events; let i = index">
    <ng-container *ngFor="let violation of item?.data | slice: 0:3">
      <ng-container *ngIf="violation?.value?.length > 0">
        <div
          class="event event-details-month"
          style="width: 98%; text-align: start"
          [ngClass]="[
            violation.title == 'absent'
              ? 'event-details__pending'
              : violation.title == 'not_ok'
              ? 'event-details__cancelled '
              : violation.title == 'daily_emplyee_report'
              ? 'event-details__available '
              : violation.title == 'ok'
              ? 'event-details__available'
              : violation.title == 'record_missing'
              ? 'event-details__vacation'
              : violation.title == 'no_settings'
              ? 'event-details_no_settings'
              : violation.title == 'full_time'
              ? 'event-details_full_time'
              : violation.title == 'released'
              ? 'event-details__released'
              : ''
          ]"
          [contextMenu]="basicMenu"
          [contextMenuSubject]="item.data"
        >
          <span>
            <span
              class="calendar-time-details"
              style="font-weight: bold; font-size: 10px"
            >
              <span
                style="
                  margin-left: 5px;
                  font-weight: bold;
                  font-family: 'Times New Roman', Times, serif;
                  font-size: 10px;
                "
              >
                <span *ngIf="violation?.value?.length > 1">
                  ({{ violation?.value?.length }})
                </span>
                <span>
                  {{ "lang_" + violation.title | language }}
                </span>
              </span>
            </span>
          </span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="item?.total_hours">
      <div
        class="event event-details-month"
        style="width: 98%; text-align: start"
        class="event-details__available"
      >
        <span>
          <span
            class="calendar-time-details"
            style="font-weight: bold; font-size: 10px"
          >
            <span
              style="
                margin-left: 5px;
                font-weight: bold;
                font-family: 'Times New Roman', Times, serif;
                font-size: 10px;
              "
            >
              <span class="bold">
                {{ "lang_total_hours" | language }}
              </span>
              <span> ({{ item?.total_hours }}) </span>
            </span>
          </span>
        </span>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template
  #monthOpenDayEventsTemplate
  let-events="events"
  let-isOpen="isOpen"
>
  <div class="cal-open-day-events" *ngIf="isOpen">
    <h2 class="cal-title d-flex align-items-center">
      <span class="cal-title-icon">
        <mat-icon style="color: #fff">calendar_today</mat-icon>
      </span>
      <span class="cal-title-text">
        {{ viewDate | date: "fullDate" }}
      </span>
      <button type="button" class="add-tracking albutton" *ngIf="fromProfile && user_company_email" (click)="onAddTrackingReport()">
        <i class="fa fa-calendar-check-o"></i>
      </button>
    </h2>

    <ng-container *ngFor="let event of events">
      <div
        class="cals-events-container"
        style="text-align: start"
        [ngClass]="event?.cssClass"
      >
        <ng-container *ngFor="let violation of event?.data">
          <div
            class="cals-event-item w-100 justify-content-spacebetween"
            *ngIf="violation?.value?.length > 0"
            style="padding: 10px 10px"
            [ngClass]="[
              violation.title == 'absent'
                ? 'event-details__pending'
                : violation.title == 'not_ok'
                ? 'event-details__cancelled '
                : violation.title == 'daily_emplyee_report'
                ? 'event-details__available '
                : violation.title == 'ok'
                ? 'event-details__available'
                : violation.title == 'record_missing'
                ? 'event-details__vacation'
                : violation.title == 'no_settings'
                ? 'event-details_no_settings'
                : violation.title == 'full_time'
                ? 'event-details_full_time'
                : violation.title == 'released'
                ? 'event-details__released'
                : ''
            ]"
          >
            <span>
              <span
                class="calendar-time-details"
                style="
                  font-weight: bold;

                  font-size: 10px;
                "
              >
                <!--  <span *ngIf="event?.data?.status != 'vacation'"> 
                            {{event?.start | date: "hh:mm a"}}  
                        </span> -->

                <mat-accordion class="all_employees_vio">
                  <mat-expansion-panel class="mt-2" [expanded]="false">
                    <mat-expansion-panel-header>
                      <span
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          font-family: 'Times New Roman', Times, serif;
                          font-size: 15px;
                        "
                      >
                        <ng-container>
                          <span> ( {{ violation?.value?.length }} ) </span>
                          <span>
                            {{ "lang_" + violation.title | language }}
                          </span>
                        </ng-container>

                        <!--  <ng-container *ngIf="event.data.absent.length>0">
                                            <span>
                                                ({{event.data.absent.length}}) 
                                                  </span>
                                                  <span  >
                                                      {{"lang_absent"|language}}
                                                  </span>
                                        </ng-container> -->
                      </span>
                    </mat-expansion-panel-header>

                    <ng-container>
                      <table class="table calendar-table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">
                              {{ "lang_human_resources_number" | language }}
                            </th>
                            <th scope="col">
                              {{
                                "lang_human_resources_employee_name" | language
                              }}
                            </th>
                            <th
                              *ngIf="
                                violation.title != 'normal_in' &&
                                violation.title != 'normal_out' &&
                                isHRView
                              "
                              scope="col"
                            >
                              {{ "lang_action" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let item of violation?.value; let i = index"
                          >
                            <th scope="row">{{ i + 1 }}</th>
                            <th>{{ item.employee_number }}</th>
                            <th>{{ item.employee_name }}</th>
                            <th
                              *ngIf="
                                violation.title != 'normal_in' &&
                                violation.title != 'normal_out' &&
                                isHRView
                              "
                            >
                              <div
                                class="d-flex align-items-center justify-content-center"
                              >
                                <i
                                  class="fa fa-plus ml-1 mr-1 mnabricon"
                                  *ngIf="
                                    violation?.title == 'absent' ||
                                    violation.title == 'not_ok' ||
                                    violation.title == 'record_missing'
                                  "
                                  (click)="
                                    openModalA(add_reason);
                                    add_reason_data(item.employee_number, event)
                                  "
                                  matTooltip="{{
                                    'lang_add_reason' | language
                                  }}"
                                ></i>
                                <mat-icon
                                  (click)="addViolation(item, event, 'confirm')"
                                  matTooltip="{{
                                    'lang_make_violation' | language
                                  }}"
                                  class="mx-2"
                                  aria-hidden="false"
                                  aria-label="Make Violation"
                                >
                                  gavel
                                </mat-icon>

                                <mat-icon
                                  class="mx-2"
                                  (click)="addViolation(item, event, 'cancel')"
                                  matTooltip="{{
                                    'lang_cancel_violation' | language
                                  }}"
                                  aria-hidden="false"
                                  aria-label="Cancel Violation"
                                >
                                  visibility_off
                                </mat-icon>
                                <mat-icon
                                  class="mx-2"
                                  (click)="
                                    loadShiftData(
                                      item.user_id,
                                      viewShift,
                                      event
                                    );
                                    loadShiftData3(
                                      item.employee_number,
                                      viewShift,
                                      event
                                    )
                                  "
                                  matTooltip="open details"
                                  aria-hidden="false"
                                  aria-label="Cancel Violation"
                                >
                                  visibility
                                </mat-icon>
                                <i
                                  class="fa fa-clock-o ml-1 mr-1 mnabricon"
                                  (click)="
                                    loadRatioWeek(
                                      item.employee_number,
                                      viewRatioWeek,
                                      event
                                    )
                                  "
                                  matTooltip="{{
                                    'lang_week_ratio' | language
                                  }}"
                                ></i>
                                <i
                                  class="fa fa-calendar ml-1 mr-1 mnabricon"
                                  (click)="
                                    loadRatioMonth(
                                      item.employee_number,
                                      item.employee_name,
                                      viewRatioMonth,
                                      event
                                    )
                                  "
                                  matTooltip="{{
                                    'lang_month_ratio' | language
                                  }}"
                                ></i>
                              </div>
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
              </span>
            </span>
          </div>
        </ng-container>

        <ng-container *ngIf="event?.data?.length == 0">
          <div class="row col-md-12 text-center justify-content-center">
            <h3>
              {{ "lang_no_violation_data" | language }}
            </h3>
          </div>
          <br />
        </ng-container>

        <ng-container *ngIf="trackingDetails">
          <div
            class="cals-event-item w-100 justify-content-spacebetween"
            *ngIf="trackingDetails.length > 0"
            style="padding: 10px 10px"
          >
            <span>
              <span
                class="calendar-time-details"
                style="font-weight: bold; font-size: 10px"
              >
                <mat-accordion class="all_employees_vio total-hours-acc">
                  <mat-expansion-panel class="mt-2" [expanded]="false">
                    <mat-expansion-panel-header>
                      <span
                        style="
                          margin-left: 5px;
                          font-weight: bold;
                          font-family: 'Times New Roman', Times, serif;
                          font-size: 15px;
                        "
                      >
                        {{ "lang_total_hours" | language }}
                      </span>
                    </mat-expansion-panel-header>

                    <ng-container>
                      <table class="table calendar-table">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">
                              {{ "lang_app_name" | language }}
                            </th>
                            <th scope="col">
                              {{ "lang_total_hours" | language }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let item of trackingDetails; let i = index"
                          >
                            <th scope="row">{{ i + 1 }}</th>
                            <th>{{ item.app_name }}</th>
                            <th>{{ item.total_hours }}</th>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <div
                        *ngIf="trackingDetails?.length"
                        class="card-footer padb0 customborder"
                      >
                        <div class="row responsive_pagination">
                          <div
                            class="col-lg-3 col-sm-3 mb-10"
                            style="height: fit-content; margin: auto 0"
                          >
                            {{ "lang_total" | language }}
                            {{ trackingRes?.page?.total_records }}
                          </div>
                          <div
                            class="col-lg-6 col-sm-6 mb-10"
                            style="display: flex; justify-content: center"
                          >
                            <ngb-pagination
                              class="m-auto"
                              [collectionSize]="
                                trackingRes?.page?.total_records
                              "
                              [rotate]="true"
                              [ellipses]="false"
                              [maxSize]="3"
                              [boundaryLinks]="true"
                              [(page)]="pageNo"
                              [pageSize]="pageSize"
                              (pageChange)="loadpagerec($event)"
                            ></ngb-pagination>
                          </div>
                          <div
                            class="col-lg-3 col-sm-3 mb-10"
                            style="
                              display: flex;
                              justify-content: end;
                              margin: auto;
                            "
                          >
                            <mat-select
                              [(ngModel)]="pageSize"
                              (selectionChange)="loadrecpagesize($event.value)"
                            >
                              <mat-option class="text-center" value="10"
                                >10 {{ "lang_per_page" | language }}
                              </mat-option>
                              <mat-option class="text-center" value="20"
                                >20 {{ "lang_per_page" | language }}
                              </mat-option>
                              <mat-option class="text-center" value="50"
                                >50 {{ "lang_per_page" | language }}
                              </mat-option>
                              <mat-option class="text-center" value="100"
                                >100
                                {{ "lang_per_page" | language }}</mat-option
                              >
                              <mat-option class="text-center" value="500"
                                >500
                                {{ "lang_per_page" | language }}</mat-option
                              >
                            </mat-select>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </mat-expansion-panel>
                </mat-accordion>
              </span>
            </span>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #viewShift>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_view" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_employee_loggedin" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b
              *ngIf="
                shiftData?.employee_loggedin[0]?.state &&
                shiftData?.employee_loggedin[0]?.time
              "
              >{{ shiftData?.employee_loggedin[0]?.time }} ({{
                shiftData?.employee_loggedin[0]?.state
              }})</b
            >
            <b
              *ngIf="
                !shiftData?.employee_loggedin[0]?.state &&
                !shiftData?.employee_loggedin[0]?.time
              "
              >{{ shiftData?.employee_loggedin }}</b
            >
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_employee_loggedout" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b
              *ngIf="
                shiftData?.employee_loggedout[0]?.state &&
                shiftData?.employee_loggedout[0]?.time
              "
              >{{ shiftData?.employee_loggedout[0]?.time }} ({{
                shiftData?.employee_loggedout[0]?.state
              }})</b
            >
            <b
              *ngIf="
                !shiftData?.employee_loggedout[0]?.state &&
                !shiftData?.employee_loggedout[0]?.time
              "
              >{{ shiftData?.employee_loggedout }}</b
            >
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_employee_name" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b>{{ shiftData.employee_name }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_employee_number" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b>{{ shiftData.employee_number }}</b>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_employee_work_hours" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b>{{ shiftData.employee_work_hours }}</b>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="shiftData.state">
        <div class="row">
          <label class="col-md-6 col-sm-6 col-xs-12"
            >{{ "lang_state" | language }} :</label
          >
          <div class="col-md-6 col-sm-6 col-xs-12">
            <b>{{ shiftData.state }}</b>
          </div>
        </div>
      </div>
      <div *ngIf="shiftData3?.length" class="device-serial-table">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ "lang_device_serial_number" | language }}</th>
              <th>{{ "lang_access_type" | language }}</th>
              <th>{{ "lang_action_datatime" | language }}</th>
              <th>{{ "lang_action" | language }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of shiftData3;
                let i = index;
                let odd = odd;
                let even = even
              "
              [class.greenstatus]="
                item?.action_time == shiftData?.employee_loggedin[0]?.time ||
                item?.action_time == shiftData?.employee_loggedin ||
                item?.action_time == shiftData?.employee_loggedout[0]?.time ||
                item?.action_time == shiftData?.employee_loggedout
              "
            >
              <td>{{ i + 1 }}</td>
              <td>{{ item?.device_serial_number }}</td>
              <td>{{ item?.access_type }}</td>
              <td>{{ item?.action_time }}</td>
              <td>
                <i
                  class="fa fa-exchange mnabricon"
                  matTooltip="{{ 'lang_option_in_out' | language }}"
                  (click)="onChangeInOut(item)"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #viewRatioWeek>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_week_ratio" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <div *ngIf="ratioWeek?.length" class="device-serial-table">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <th width="50%">{{ "lang_week" | language }}</th>
              <th width="50%">{{ "lang_ratio" | language }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of ratioWeek;
                let i = index;
                let odd = odd;
                let even = even
              "
            >
              <td>{{ i + 1 }}</td>
              <td>{{ item?.total_hours }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #viewRatioMonth>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_month_ratio" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <div *ngIf="ratioMonth" class="device-serial-table">
        <div class="month-ratio">
          <div class="row">
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_human_resources_number" | language }}</p>
              <p>{{ ratioMonth?.employee_number }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">
                {{ "lang_human_resources_employee_name" | language }}
              </p>
              <p>{{ ratioMonth?.employee_name }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <p class="bold">{{ "lang_ratio" | language }}</p>
              <p>{{ ratioMonth?.ratio }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #topTemp>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_top" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <div *ngIf="topEmps?.length" class="device-serial-table">
        <table border="1" class="table CustomTable">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ "lang_employee_number" | language }}</th>
              <th>{{ "lang_employee_name" | language }}</th>
              <th>{{ "lang_ratio" | language }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of topEmps;
                let i = index;
                let odd = odd;
                let even = even
              "
            >
              <td>{{ i + 1 }}</td>
              <td>{{ item?.employee_number }}</td>
              <td>{{ item?.employee_name }}</td>
              <td>{{ item?.total_hours }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="modalRefV.hide()"
      >
        {{ "lang_close" | language }}
      </button>
    </div>
  </div>
</ng-template>
<ng-template #add_reason>
  <div class="modal-content">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">{{ "lang_add_reason" | language }}</h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        (click)="modalRefA.hide()"
      >
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body">
      <form class="outgoing" [formGroup]="form" (ngSubmit)="addReason()">
        <div class="mbrl15">
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <div class="form-group">
                <label>
                  {{ "lang_reason" | language }}
                  <span class="red">*</span></label
                >
                <input
                  type="text"
                  formControlName="text"
                  placeholder="{{ 'lang_reason' | language }}"
                  class="form-control almnabrformcontrol"
                />
                <mat-error
                  *ngIf="submitted && form.controls['text'].errors?.required"
                >
                  {{ "lang_field_required" | language }}
                </mat-error>
              </div>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <label>{{ "lang_attach_title" | language }}</label>
              <input
                type="text"
                formControlName="attachment[title]"
                placeholder="{{ 'lang_description' | language }}"
                autocomplete="off"
                class="form-control almnabrformcontrol"
              />
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <label>{{ "lang_file" | language }}</label>
              <input
                name="file"
                type="file"
                required
                onclick="this.value = null"
                (change)="
                  ds.uploadSingleFile(
                    $event,
                    form.get('attachment[file]'),
                    'file',
                    filename
                  )
                "
                class="nghide albutton width100"
              />
              <label id="file" class="albutton">{{ filename }}</label>
            </div>
            <input type="submit" hidden #reasonsubmit />
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer d-block">
      <div class="row">
        <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
          <button
            type="button"
            class="btn btn-secondary"
            data-dismiss="modal"
            (click)="modalRefA.hide()"
          >
            {{ "lang_close" | language }}
          </button>
        </div>
        <div class="col-md-8 col-sm-6 col-xs-12 mb-10">
          <app-alert id="default-alert"></app-alert>
        </div>
        <div class="col-md-2 col-sm-3 col-xs-12 mb-10">
          <button
            class="albutton mnabrbutton mat-accent"
            mat-button
            (click)="reasonsubmit.click()"
          >
            {{ "lang_submit" | language }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addTrackingReportDialog>
  <div mat-dialog-title>
    <div class="d-flex justify-content-between">
      <h4>{{ "lang_add" | language }}</h4>
      <span mat-dialog-close><i class="fa fa-times"></i></span>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <form [formGroup]="trackingForm">
      <div class="p-2">
        <div class="row">
          <div class="col-12 mb-2">
            <div [dir]="lang_key == 'ar' ? 'rtl' : 'ltr'">
              <mat-form-field appearance="outline" style="width: 100%">
                <mat-label>{{ "lang_total_hours" | language }}</mat-label>
                <input
                  matInput
                  placeholder="{{ 'lang_total_hours' | language }}"
                  type="number"
                  min="0"
                  formControlName="total_hours"
                />
              </mat-form-field>
            </div>
          </div>
                  <div class="col-12">
                      <div class="form-group">
              <label for="notes">{{ "lang_notes" | language }}</label>
              <textarea
                type="text"
                id="notes"
                class="form-control almnabrformcontrol"
                placeholder="{{ 'lang_notes' | language }}"
                formControlName="notes"
              ></textarea>
            </div>
                  </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-between">
    <div class="col-md-4 col-xs-12">
      <button
        type="button"
        class="albutton mnabrbutton mat-accent"
        mat-button
        mat-dialog-close
      >
        {{ "lang_close" | language }}
      </button>
    </div>
    <div class="col-md-4 col-xs-12">
      <button
        type="button"
        class="albutton mnabrbutton mat-accent"
        mat-button
        (click)="addTrackingReport()"
      >
        {{ "lang_submit" | language }}
      </button>
    </div>
  </mat-dialog-actions>
  
</ng-template>